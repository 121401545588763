// Import libraries
import { message } from 'antd';
import API from '@aws-amplify/api';
import { useState, useEffect, useMemo } from 'react';

// Import utilities
import { fetchApiErrorMessage } from './api';

export const useAmplifyGetAPI = (apiName, apiPath, initData, params) => {
    const [data, setData] = useState(initData);
    const [loading, setLoading] = useState(true);

    const stringifiedParams = useMemo(
        () => JSON.stringify(params ? { queryStringParameters: params } : {}),
        [params]
    );

    useEffect(() => {
        // Used for aborting state transition if the component is unmounted
        let didCancel = false;

        if (apiName) {
            const fetchData = async () => {
                setLoading(true);
                try {
                    const data = await API.get(
                        apiName,
                        apiPath,
                        JSON.parse(stringifiedParams)
                    );

                    if (!didCancel) setData(data);
                } catch (error) {
                    message.error(fetchApiErrorMessage(error));
                }
                setLoading(false);
            };

            fetchData();
        } else {
            message.error('No API URL Provided');
        }

        return () => { didCancel = true; };
    }, [apiName, apiPath, stringifiedParams]);

    return {
        data,
        loading,
        setData,
    };
};

export const useAmplifyOrgGetAPI = (orgId, apiPath, initData, params, notifyError=true) => {
    const [data, setData] = useState(initData);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const stringifiedParams = useMemo(
        () => JSON.stringify(params ? { queryStringParameters: params } : {}),
        [params]
    );

    useEffect(() => {
        // Used for aborting state transition if the component is unmounted
        let didCancel = false;

        if (orgId) {
            const fetchData = async () => {
                setLoading(true);
                try {
                    const data = await API.get(
                        'organisations',
                        `/${ orgId }` + apiPath,
                        JSON.parse(stringifiedParams)
                    );

                    if (!didCancel) setData(data);
                } catch (error) {
                    setError(error);
                    if (notifyError) message.error(fetchApiErrorMessage(error));
                }
                setLoading(false);
            };

            fetchData();
        }

        return () => { didCancel = true; };
    }, [orgId, apiPath, stringifiedParams]); // eslint-disable-line

    return {
        data,
        loading,
        setData,
        error,
    };
};
