// Import libraries
import { css } from '@emotion/core';

// Import presets
import { content, colors } from '../../styles/presets';

// Prepare stylesheet
const styles = {
    contentWrapper: css({ ...content }),
    adminTagColor: colors.primary,
};

export default styles;
