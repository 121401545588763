export const VISIBILITY = {
    PUBLIC: 'public',
    ADMIN_ONLY: 'admin_only',
    DELETED: 'deleted',
};

export const REQUEST_TYPE = {
    USER_REQUEST: 'USER_REQUEST',
    EVENT_DATA: 'EVENT_DATA',
};

export const REQUEST_STATUS = {
    INCOMPLETE: 'INCOMPLETE',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
};

export const DOWNLOAD_STATUS = {
    PENDING: 'PENDING',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
    RETRYING: 'RETRYING',
};

export const TRANSFER_STATUS = {
    QUEUED: 'QUEUED',
    UPLOADING: 'UPLOADING',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
    RETRYING: 'RETRYING',
};
