/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import API from '@aws-amplify/api';
import { useContext, useState, useEffect } from 'react';

// Import Ant Design components
import { List, Switch } from 'antd';

// Import store
import { OrgsContext } from '../../../../store/OrgsContext';

// Import utilities
import { useAmplifyOrgGetAPI } from '../../../../utilities/hooks';

const Notifications = () => {
    const orgsContext = useContext(OrgsContext);

    // Extract values
    const { selectedOrg } = orgsContext;

    // Loading organisation devices
    const {
        data: settings,
    } = useAmplifyOrgGetAPI(selectedOrg, '/notifications', {});

    const [weeklyEnabled, setWeeklyEnabled] = useState(false);
    const [emergencyEnabled, setEmergencyEnabled] = useState(false);

    useEffect(() => {
        setWeeklyEnabled(settings.receiveWeeklyReport);
        setEmergencyEnabled(settings.receiveEmergencyReport);
    }, [settings]);

    const data = [
        {
            key: 'emergency',
            title: 'Emergency Email',
            description: 'System sends a notification email when a device error is detected.',
            enabled: emergencyEnabled,
        },
        {
            key: 'weekly',
            title: 'Weekly Email',
            description: 'System sends a weekly email summarising device status in past week.',
            enabled: weeklyEnabled,
        },
    ];

    const handleNotificationChange = async (key, checked) => {
        if (key === 'weekly') {
            setWeeklyEnabled(checked);
            await API.put(
                'organisations',
                `/${ selectedOrg }/notifications`,
                { body: { receiveWeeklyReport: checked } }
            );
        } else if (key === 'emergency') {
            setEmergencyEnabled(checked);
            await API.put(
                'organisations',
                `/${ selectedOrg }/notifications`,
                { body: { receiveEmergencyReport: checked } }
            );
        }
    };

    return (
        <div>
            <div className="ant-descriptions-title">Notification Settings</div>
            <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={item => (
                    <List.Item
                        actions={[(
                            <Switch
                                key={item.key}
                                checked={item.enabled}
                                onChange={checked => handleNotificationChange(item.key, checked)}
                            />
                        )]}
                    >
                        <List.Item.Meta
                            title={item.title}
                            description={item.description}
                        />
                    </List.Item>
                )}
            />
        </div>
    );
};

export default Notifications;