/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import moment from 'moment';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useContext, useState, useEffect } from 'react';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

// Import Ant Design component
import { Card, PageHeader, Button, Form, Table, Modal, Input, Space, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

// Import store
import { OrgsContext } from '../../store/OrgsContext';

// Import actions
import { getContacts, deleteContact, createContact, updateContact } from './actions';

// Import components
import DraggableRow from './draggableRow';

// Import stylesheet
import styles from './styles';

const AdminOrgContacts = () => {
    // Initialisation
    const [orgContacts, setOrgContacts] = useState([]);
    const [loadingContacts, setLoadingContacts] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [form] = Form.useForm();

    // Retrieve currently selected organisation
    const orgsContext = useContext(OrgsContext);
    const { selectedOrg } = orgsContext;

    useEffect(() => {
        // Fetch org contacts
        const fetch = async () => {
            setLoadingContacts(true);
            const contacts = await getContacts(selectedOrg);
            setOrgContacts(contacts);
            setLoadingContacts(false);
        };

        if (selectedOrg) fetch();
    }, [selectedOrg]);

    // Configure table columns
    const columns = [
        {
            title: 'Priority Order',
            key: 'sort',
            width: 150,
        },
        {
            title: 'Contact Name',
            dataIndex: 'name',
            render: (name, contact) => (
                <Button
                    type="link"
                    onClick={() => {
                        form.setFieldValue('id', contact.id);
                        form.setFieldValue('name', contact.name);
                        form.setFieldValue('phoneNumber', contact.contactNumber);
                        setModalVisible(true);
                    }}
                    css={{ padding: '0px' }}
                >
                    {name}
                </Button>
            ),
        },
        {
            title: 'Contact Number',
            dataIndex: 'contactNumber',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (value) => moment(value).format('MMM Do YYYY, h:mm:ss a'),
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            width: 150,
            render: (id) => <Button onClick={() => onDelete(id)}>Delete</Button>,
        },
    ];

    // Handle deletion
    const onDelete = async (contactId) => {
        // Delete selected contact
        await deleteContact(selectedOrg, contactId);

        // Remove deleted contact from table
        const newContacts = orgContacts.filter((contact) => contact.id !== contactId);
        setOrgContacts(newContacts);

        // Update priority order
        await Promise.allSettled(
            newContacts.map((contact, idx) =>
                updateContact(
                    selectedOrg,
                    {
                        name: contact.name,
                        phoneNumber: contact.contactNumber,
                        order: idx + 1,
                    },
                    contact.id
                )
            )
        );

        message.success('Contact deleted');
    };

    const onSubmit = async (values) => {
        if (values.id) {
            await updateContact(selectedOrg, values, values.id);
            setOrgContacts(
                orgContacts.map((contact, idx) => {
                    if (contact.id === values.id) {
                        return {
                            ...contact,
                            name: values.name,
                            contactNumber: values.phoneNumber,
                            order: idx + 1,
                        };
                    }
                    return contact;
                })
            );
            message.success('Contact updated');
        } else {
            const contactId = await createContact(selectedOrg, values);
            if (contactId) {
                setOrgContacts([
                    ...orgContacts,
                    {
                        id: contactId,
                        name: values.name,
                        contactNumber: values.phoneNumber,
                        createdAt: new Date().getTime(),
                    },
                ]);
            }
            message.success('Contact created');
        }

        setModalVisible(false);
        form.resetFields();
    };

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setOrgContacts((previous) => {
                const activeIndex = previous.findIndex((i) => i.id === active.id);
                const overIndex = previous.findIndex((i) => i.id === over?.id);
                const newContacts = arrayMove(previous, activeIndex, overIndex);

                Promise.allSettled(
                    newContacts.map((contact, idx) =>
                        updateContact(
                            selectedOrg,
                            {
                                name: contact.name,
                                phoneNumber: contact.contactNumber,
                                order: idx + 1,
                            },
                            contact.id
                        )
                    )
                )
                    .then(() => message.info('Priority order saved'))
                    .catch(() => message.error('Failed to save priority order'));

                return newContacts;
            });
        }
    };

    return (
        <div>
            <PageHeader
                title="SMS Contacts"
                subTitle={`${orgContacts.length} results found`}
                ghost={false}
            />

            <div css={styles.contentWrapper}>
                <Card>
                    {/** Control panel */}
                    <div css={styles.controlPanel}>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                form.resetFields();
                                setModalVisible(true);
                            }}
                            disabled={orgContacts.length >= 2}
                        >
                            Create New
                        </Button>
                    </div>

                    {/** Data display panel */}
                    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                        <SortableContext
                            items={orgContacts.map((i) => i.id)}
                            strategy={verticalListSortingStrategy}
                        >
                            <Table
                                columns={columns}
                                loading={loadingContacts}
                                components={{ body: { row: DraggableRow } }}
                                rowKey="id"
                                dataSource={orgContacts}
                                css={{
                                    '.ant-table-column-title': {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </SortableContext>
                    </DndContext>
                </Card>
            </div>

            {/** SMS Contact Edit Modal */}
            <Modal
                title={`${form.getFieldValue('id') ? 'Update' : 'Create New'} SMS Contact`}
                open={modalVisible}
                destroyOnClose
                footer={[]}
                onCancel={() => setModalVisible(false)}
            >
                <Form form={form} layout="vertical" onFinish={onSubmit}>
                    <Form.Item name="id" hidden />

                    <Form.Item
                        label="Contact Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter a contact name',
                            },
                        ]}
                    >
                        <Input placeholder="Enter contact name" />
                    </Form.Item>

                    <Form.Item
                        label="Contact Number (+61)"
                        name="phoneNumber"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter a contact number',
                            },
                            {
                                pattern: /^\+61\d{9}$/,
                                message:
                                    'Please enter a valid Australian phone number starting with +61',
                            },
                        ]}
                    >
                        <Input placeholder="Enter contact number" />
                    </Form.Item>

                    <div style={{ marginTop: '30px', width: '100%', textAlign: 'right' }}>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                            <Button
                                onClick={() => {
                                    form.resetFields();
                                    setModalVisible(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </Space>
                    </div>
                </Form>
            </Modal>
        </div>
    );
};

export default AdminOrgContacts;
