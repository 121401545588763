/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import moment from 'moment';
import { useContext, useState } from 'react';

// Import Ant Design component
import { Card, Tag, Button, Table, Modal, PageHeader } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

// Import store
import { OrgsContext } from '../../store/OrgsContext';

// Import utilities
import { useAmplifyOrgGetAPI } from '../../utilities/hooks';

// Import actions
import { deleteVehicleGroups, createVehicleGroup, updateVehicleGroup } from './actions';

// Import forms
import EditVehicleGroup from './forms/EditVehicleGroup';

// Import stylesheet
import styles from './styles';

const AdminVehicleGroups = () => {
    const [dataFetchedAt, setDataFetchedAt] = useState(new Date().getTime());
    const [editLoading, setEditLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [selectedVehicleGroup, setSelectedVehicleGroup] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    // Retrieve currently selected organisation
    const orgsContext = useContext(OrgsContext);
    const { selectedOrg } = orgsContext;

    // Fetch vehicle group list data
    const { data: vehicleGroups, loading: loadingVehicleGroups } = useAmplifyOrgGetAPI(
        selectedOrg,
        '/vehicle-groups',
        [],
        { t: dataFetchedAt }
    );

    // Handle table row selection
    const rowSelection = {
        onChange: (_, selectedRows) => setSelectedItems(selectedRows),
        selectedRowKeys: selectedItems.map((item) => item.id),
    };

    // Handle form submission
    const onSubmit = async (values) => {
        // Create or update vehicle group
        setEditLoading(true);
        if (selectedVehicleGroup.id) {
            await updateVehicleGroup(selectedOrg, values, selectedVehicleGroup.id);
        } else {
            await createVehicleGroup(selectedOrg, values);
        }

        // Refetch vehicle group data
        setDataFetchedAt(new Date().getTime());

        // Clean up status
        setSelectedVehicleGroup({});
        setEditLoading(false);
        setModalVisible(false);
    };

    // Handle deletion
    const onDelete = async () => {
        // Delete selected vehicle group(s)
        setDeleteLoading(true);
        await deleteVehicleGroups(selectedOrg, selectedItems);

        // Refetch vehicle group data
        setDataFetchedAt(new Date().getTime());

        // Clean up status
        setDeleteLoading(false);
        setSelectedItems([]);
    };

    // Handle cancellation
    const onCancel = () => {
        setSelectedVehicleGroup({});
        setModalVisible(false);
    };

    // Configure table columns
    const columns = [
        {
            title: 'Group Name',
            dataIndex: 'name',
            render: (value, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        setSelectedVehicleGroup(record);
                        setModalVisible(true);
                    }}
                    css={{ padding: '0px' }}
                >
                    {value}
                </Button>
            ),
        },
        {
            title: 'Group Color Schema',
            dataIndex: 'color',
            render: (value) => <Tag color={value}>{value}</Tag>,
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (value) => moment(value).format('MMM Do YYYY, h:mm:ss a'),
        },
    ];

    return (
        <div>
            <PageHeader
                title="Vehicle Groups"
                subTitle={`${vehicleGroups.length} results found`}
                // breadcrumb={{ routes, itemRender: breadcrumbRender }}
                ghost={false}
            />

            <div css={styles.contentWrapper}>
                <Card>
                    {/** Control panel */}
                    <div css={styles.controlPanel}>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => setModalVisible(true)}
                        >
                            Create New
                        </Button>
                        {selectedItems.length > 0 && (
                            <Button
                                type="danger"
                                css={{ marginLeft: '25px' }}
                                onClick={onDelete}
                                loading={deleteLoading}
                            >
                                Delete
                            </Button>
                        )}
                    </div>

                    {/** Data display panel */}
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        loading={loadingVehicleGroups}
                        rowKey="id"
                        dataSource={vehicleGroups}
                        css={{
                            '.ant-table-column-title': {
                                fontWeight: 'bold',
                            },
                        }}
                    />
                </Card>
            </div>

            {/** Vehicle Group Edit Modal */}
            <Modal
                title={`${selectedVehicleGroup.id ? 'Update' : 'Create New'} Vehicle Group`}
                open={modalVisible}
                destroyOnClose
                footer={[]}
                onCancel={() => setModalVisible(false)}
            >
                <EditVehicleGroup
                    editLoading={editLoading}
                    selectedVehicleGroup={selectedVehicleGroup}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                />
            </Modal>
        </div>
    );
};

export default AdminVehicleGroups;
