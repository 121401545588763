/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { useContext } from 'react';
import { Link } from 'react-router-dom';

// Import Ant Design component
import { Result, Button } from 'antd';

// Import store
import { UserContext } from '../../store/UserContext';

const DealerContainer = props => {
    // Retrieve user context
    const userContext = useContext(UserContext);
    const { isDealer, isSuperAdmin } = userContext;

    if (isDealer || isSuperAdmin) return props.children;
    return (
        <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
                <Link to="/dashboard">
                    <Button type="primary">Back Home</Button>
                </Link>
            }
        />
    );
};

export default DealerContainer;
