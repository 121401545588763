// Import colors
import { colors } from './presets';

// Configure authenticator theme
const authTheme = {
    a: {
        color: colors.primary,
    },
    button: {
        backgroundColor: colors.primary,
    },
    formContainer: {
        margin: 0,
        paddingTop: '10vh',
        paddingBottom: '6vh',
        position: 'relative',
        zIndex: '2',
    },
};

export default authTheme;
