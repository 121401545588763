/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useState, useContext } from 'react';

// Import Ant Design components
import {
    Card, DatePicker, Table, Empty, PageHeader
} from 'antd';

// Import store
import { OrgsContext } from '../../store/OrgsContext';

// Import utilities
import { useAmplifyOrgGetAPI } from '../../utilities/hooks';

// Import stylesheet
import styles from './styles';

// Import RangePicker component
const { RangePicker } = DatePicker;

// Configure table columns
const columns = [
    {
        title: 'Report Name',
        dataIndex: 'name',
        render: (name, report) => (
            <Link to={`/reports/weekly/${ report.id }`}>
                {name}
            </Link>
        )
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        render: value => moment(value).format('MMMM Do YYYY'),
    },
];

const IssueReports = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // Retrieve currently selected organisation
    const orgsContext = useContext(OrgsContext);
    const { selectedOrg } = orgsContext;

    // Fetch weekly report data
    const {
        data: reports,
        loading: loadingReports,
    } = useAmplifyOrgGetAPI(selectedOrg, '/reports/weekly', [], {
        start_date: startDate,
        end_date: endDate,
    });

    const handleCalendarChange = dates => {
        setStartDate(dates.length === 2 ? dates[0].format('YYYY-MM-DD') : null);
        setEndDate(dates.length === 2 ? dates[1].format('YYYY-MM-DD') : null);
    };

    return (
        <div>
            <PageHeader
                title="Weekly Reports"
                breadcrumb={null}
                ghost={false}
            />

            <div css={styles.contentWrapper}>
                <Card>
                    <div css={{ marginBottom: '30px' }}>
                        <span css={styles.queryLabel}>Select Range:</span>
                        <RangePicker
                            css={styles.queryInput}
                            onChange={handleCalendarChange}
                        />
                    </div>
                    <Table
                        dataSource={reports}
                        columns={columns}
                        loading={loadingReports}
                        rowKey="id"
                        locale={{
                            emptyText: (
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="Please update your date range"
                                />
                            )
                        }}
                    />
                </Card>
            </div>
        </div>
    );
};

export default IssueReports;
