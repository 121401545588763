/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import API from '@aws-amplify/api';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { useEffect, useState, Fragment } from 'react';

// Import Ant Design components
import {
    Card, Input, Button, Descriptions, message
} from 'antd';

// Import breadcrumb
import getBreadcrumb from '../breadcrumb';

// Import stylesheet
import styles from '../styles';

const SuperDealerEdit = props => {
    // Retrieve dealer id from path parameter
    const { dealerId } = props.match.params;

    // Initialization
    const [loadingDealer, setLoadingDealer] = useState(false);
    const [updatingDealer, setUpdatingDealer] = useState(false);
    const [dealer, setDealer] = useState({});

    const history = useHistory();

    useEffect(() => {
        const fetchDealerData = async () => {
            setLoadingDealer(true);
            try {
                const data = await API.get('superadmin', `/dealers/${ dealerId }`);
                setDealer(data);
            } catch (error) {
                message.error('Unable to fetch dealer details');
            }
            setLoadingDealer(false);
        };

        if(dealerId) fetchDealerData();
    }, [dealerId]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: dealer ? dealer.name : '',
            contactName: dealer ? dealer.contactName : '',
            contactNumber: dealer ? dealer.contactNumber : '',
            contactAddress: dealer ? dealer.contactAddress : '',
            abn: dealer ? dealer.abn : '',
            userEmail: '',
            userFirstName: '',
            userLastName: '',
        },
        onSubmit: async values => {
            setUpdatingDealer(true);
            const {
                name, contactName, contactNumber, contactAddress,
                abn, userEmail, userFirstName, userLastName,
            } = values || {};

            if (dealerId) {
                try {
                    // Update dealer's details
                    const body = {
                        name, contactName, contactNumber, contactAddress, abn,
                    };
                    await API.put('superadmin', `/dealers/${ dealerId }`, { body });
                    message.success('Dealer updated successfully');
                    history.push(`/management/dealers/${ dealerId }`);
                } catch (error) {
                    setUpdatingDealer(false);
                    message.error('Unable to update dealer');
                }
            } else {
                try {
                    // Create a new dealer and dealer user
                    const body = {
                        name, contactName, contactNumber, contactAddress, abn,
                        user: {
                            email: userEmail,
                            firstName: userFirstName,
                            lastName: userLastName,
                        }
                    };
                    await API.post('superadmin', '/dealers', { body });
                    message.success('Dealer created');
                    history.push('/management/dealers');
                } catch (error) {
                    setUpdatingDealer(false);
                    message.error('Unable to create a new dealer');
                }
            }
        },
    });

    return (
        <Fragment>
            <PageHeaderWrapper
                title={dealerId ? 'Update Dealer' : 'Create Dealer'}
                subTitle={dealerId}
                breadcrumb={getBreadcrumb(dealerId, dealerId ? 'Edit' : 'Create')}
            />

            <div css={styles.contentWrapper}>
                <Card loading={loadingDealer}>
                    <form onSubmit={formik.handleSubmit}>
                        <Descriptions title="Dealer" bordered column={2}>
                            <Descriptions.Item label="Name" span={2}>
                                <Input
                                    id="name"
                                    name="name"
                                    placeholder="Enter dealer name"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    style={{ width: '200px' }}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Contact Name">
                                <Input
                                    id="contactName"
                                    name="contactName"
                                    placeholder="Enter dealer contact name"
                                    onChange={formik.handleChange}
                                    value={formik.values.contactName}
                                    style={{ width: '200px' }}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Contact Number">
                                <Input
                                    id="contactNumber"
                                    name="contactNumber"
                                    placeholder="Enter dealer contact number"
                                    onChange={formik.handleChange}
                                    value={formik.values.contactNumber}
                                    style={{ width: '200px' }}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Contact Address">
                                <Input
                                    id="contactAddress"
                                    name="contactAddress"
                                    placeholder="Enter dealer contact address"
                                    onChange={formik.handleChange}
                                    value={formik.values.contactAddress}
                                    style={{ width: '200px' }}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="ABN">
                                <Input
                                    id="abn"
                                    name="abn"
                                    placeholder="Enter ABN"
                                    onChange={formik.handleChange}
                                    value={formik.values.abn}
                                    style={{ width: '200px' }}
                                />
                            </Descriptions.Item>
                        </Descriptions>

                        {!dealerId && (
                            <Descriptions
                                title="Dealer User"
                                bordered
                                column={2}
                                css={{ marginTop: '30px' }}
                            >
                                <Descriptions.Item label="User Email" span={2}>
                                    <Input
                                        id="userEmail"
                                        name="userEmail"
                                        placeholder="Enter dealer user email"
                                        onChange={formik.handleChange}
                                        value={formik.values.userEmail}
                                        style={{ width: '200px' }}
                                    />
                                </Descriptions.Item>
                                <Descriptions.Item label="User First Name">
                                    <Input
                                        id="userFirstName"
                                        name="userFirstName"
                                        placeholder="Enter dealer user first name"
                                        onChange={formik.handleChange}
                                        value={formik.values.userFirstName}
                                        style={{ width: '200px' }}
                                    />
                                </Descriptions.Item>
                                <Descriptions.Item label="User Last Name">
                                    <Input
                                        id="userLastName"
                                        name="userLastName"
                                        placeholder="Enter dealer user last name"
                                        onChange={formik.handleChange}
                                        value={formik.values.userLastName}
                                        style={{ width: '200px' }}
                                    />
                                </Descriptions.Item>
                            </Descriptions>
                        )}

                        <div css={{ display: 'flex', marginTop: '30px' }}>
                            <Button type="primary" htmlType="submit" loading={updatingDealer}>
                                {dealerId ? 'Update' : 'Create'}
                            </Button>
                            <Button
                                css={{ marginLeft: '15px' }}
                                onClick={() => {
                                    history.push(dealerId
                                        ? `/management/dealers/${ dealerId }`
                                        : '/management/dealers');
                                }}
                            >
                            Cancel
                            </Button>
                        </div>
                    </form>
                </Card>
            </div>
        </Fragment>
    );
};

export default SuperDealerEdit;
