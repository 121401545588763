// Import libraries
import { message } from 'antd';
import API from '@aws-amplify/api';

export const getContacts = async (selectedOrg) => {
    try {
        const contacts = await API.get('organisations', `/${selectedOrg}/sms-contacts`);
        return contacts;
    } catch (error) {
        message.error('Unable to retrieve contacts, please try again later');
        return [];
    }
};

export const deleteContact = async (selectedOrg, contactId) => {
    try {
        await API.del('organisations', `/${selectedOrg}/sms-contacts/${contactId}`);
    } catch (error) {
        message.error('Unable to delete contact, please try again later');
    }
};

export const createContact = async (selectedOrg, values) => {
    try {
        const { contactId } = await API.post('organisations', `/${selectedOrg}/sms-contacts`, {
            body: {
                name: values.name,
                phoneNumber: values.phoneNumber,
            },
        });
        return contactId;
    } catch (error) {
        message.error('Unable to create contact, please try again later');
        return null;
    }
};

export const updateContact = async (selectedOrg, values, contactId) => {
    try {
        await API.put('organisations', `/${selectedOrg}/sms-contacts/${contactId}`, {
            body: {
                name: values.name,
                phoneNumber: values.phoneNumber,
                order: values.order,
            },
        });
    } catch (error) {
        message.error('Unable to update contact, please try again later');
    }
};
