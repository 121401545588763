// Import presets
import { content } from '../../styles/presets';

// Prepare stylesheet
const styles = {
    contentWrapper: { ...content },
    controlPanel: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
    },
    queryPanel: {
        display: 'flex',
        alignItems: 'center',
    },
    queryLabel: { marginRight: '10px' },
    queryInput: {
        width: '150px',
        marginRight: '30px',
    },
};

export default styles;
