/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import moment from 'moment-timezone';
import queryString from 'query-string';
import API from '@aws-amplify/api';
// import { Link } from 'react-router-dom';
import { useContext, useState, useEffect } from 'react';

// Import Ant Design components
import { Card, Input, Switch, Table, PageHeader, Tooltip, Button, Result, message } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

// Import store
import { OrgsContext } from '../../store/OrgsContext';
import { UserContext } from '../../store/UserContext';

// Import utilities
import { channelsRender, statusRender, vlossRender } from './utils';

// Import constants
import { ORG_ROLES } from '../../constants/orgRoles';

// Import stylesheet
import styles from './styles';

const Devices = (props) => {
    const { rego } = queryString.parse(props.location.search) || {};
    const [searchRego, setSearchRego] = useState(rego || '');
    const [searchOnlineOnly, setSearchOnlineOnly] = useState(false);
    const [devices, setDevices] = useState([]);
    const [loadingDevices, setLoadingDevices] = useState(false);
    const [dataFetchedAt, setDataFetchedAt] = useState(new Date().getTime());

    // Retrieve currently selected organisation
    const orgsContext = useContext(OrgsContext);
    const { selectedOrg, userRole } = orgsContext;

    // Retrieve user timezone
    const userContext = useContext(UserContext);
    const { timezone } = userContext;

    const hasPermission = [
        ORG_ROLES.ADMIN.name,
        ORG_ROLES.MANAGER.name,
        ORG_ROLES.OPERATOR.name,
    ].includes(userRole);

    // Loading organisation devices
    useEffect(() => {
        const getDevices = async () => {
            setLoadingDevices(true);
            try {
                const devices = await API.get('organisations', `/${selectedOrg}/devices/status`);
                setDevices(devices || []);
            } catch (error) {
                message.error('Unable to fetch devices');
            }
            setLoadingDevices(false);
        };

        if (selectedOrg && hasPermission) getDevices();
    }, [selectedOrg, dataFetchedAt, hasPermission]);

    if (!hasPermission) {
        return (
            <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
            />
        );
    }

    // Configure table columns
    const columns = [
        {
            title: 'Rego',
            dataIndex: 'rego',
            render: (value, record) => (
                <p>{value}</p>
                // <Link to={`/devices/${ record.deviceId }`}>
                //     {value}
                // </Link>
            ),
            sorter: (a, b) => (a.rego > b.rego ? 1 : -1),
        },
        {
            title: 'Status',
            render: (_, record) => statusRender(record),
            sorter: (a) => (a.online === 'online' ? 1 : -1),
        },
        {
            title: 'Channels',
            dataIndex: 'rec',
            render: (rec, device) => (
                <div css={{ width: '180px', fontSize: '12px' }}>
                    {channelsRender(rec, device.vlossChs, device.service, device.hdd)}
                </div>
            ),
        },
        {
            title: 'VLoss',
            dataIndex: 'vlossChs',
            render: (vloss, _) => vlossRender(vloss),
            sorter: (a, b) => {
                const currentList = a.vlossChs && a.vlossChs !== '' ? a.vlossChs.split('|') : [];
                const currentLength =
                    currentList.length === 1 && currentList[0] === '0' ? 0.5 : currentList.length;
                const nextList = b.vlossChs && b.vlossChs !== '' ? b.vlossChs.split('|') : [];
                const nextLength =
                    nextList.length === 1 && nextList[0] === '0' ? 0.5 : nextList.length;
                return currentLength - nextLength;
            },
        },
        {
            title: 'Model',
            dataIndex: 'model',
            sorter: (a, b) => (a.model > b.model ? 1 : -1),
        },
        {
            title: 'Version',
            dataIndex: 'version',
            sorter: (a, b) => (a.version > b.version ? 1 : -1),
        },
        {
            title: 'Online Hours',
            dataIndex: 'onlineHoursToday',
            sorter: (a, b) => a.onlineHoursToday - b.onlineHoursToday,
        },
        // {
        //     title: 'Connected',
        //     dataIndex: 'aliveUtc',
        //     render: (connectedAt, _) => moment.unix(connectedAt).fromNow(),
        // },
        {
            title: 'Last Updated',
            dataIndex: 'updatedAt',
            render: (updatedAt, _) =>
                moment(updatedAt).tz(timezone).format('DD/MM/YYYY, h:mm:ss a'),
            sorter: (a, b) =>
                moment(a.updatedAt).tz(timezone).isAfter(moment(b.updatedAt)) ? 1 : -1,
        },
    ];

    return (
        <div>
            <PageHeader
                title="Vehicle DVRs"
                subTitle={`${devices.length} results found`}
                breadcrumb={null}
                ghost={false}
            />

            <div css={styles.contentWrapper}>
                <Card>
                    {/** Query panel */}
                    <div css={styles.controlPanel}>
                        <div css={styles.queryPanel}>
                            <span css={styles.queryLabel}>Search Rego:</span>
                            <Input
                                placeholder="Enter device rego"
                                value={searchRego}
                                onChange={(e) => setSearchRego(e.target.value)}
                                css={styles.queryInput}
                                allowClear
                            />
                            <span css={styles.queryLabel}>Show Online Device Only:</span>
                            <Switch
                                checked={searchOnlineOnly}
                                onChange={(checked) => setSearchOnlineOnly(checked)}
                            />
                        </div>

                        <Tooltip title="Refresh" key="refresh" placement="bottom">
                            <Button
                                key="refresh"
                                shape="circle"
                                icon={<ReloadOutlined />}
                                onClick={() => setDataFetchedAt(new Date().getTime())}
                                type="primary"
                            />
                        </Tooltip>
                    </div>

                    {/** Data display panel */}
                    <Table
                        columns={columns}
                        loading={loadingDevices}
                        rowKey="deviceId"
                        dataSource={devices.filter(
                            (device) =>
                                device.rego.toLowerCase().includes(searchRego.toLowerCase()) &&
                                (!searchOnlineOnly || device.online === 'online')
                        )}
                    />
                </Card>
            </div>
        </div>
    );
};

export default Devices;
