// Import presets
import { content } from '../../styles/presets';

// Prepare stylesheet
const styles = {
    contentWrapper: { ...content },
    aliasInput: { width: '40%' },
    eventAliasesForm: { marginTop: '30px' },
};

export default styles;
