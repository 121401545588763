// Import libraries
import { io } from 'socket.io-client';
import Auth from '@aws-amplify/auth';

// Import constants
import { REQUEST_HUB_HOST } from '../constants/requestHub';

// Prepare a method to establish a websocket connection to the Request Hub
export const connectRequestHub = async (orgId) => {
    // Retrieve user token
    const user = await Auth.currentAuthenticatedUser();
    const session = await user.getSignInUserSession();
    const token = session.getAccessToken().getJwtToken();

    return io(REQUEST_HUB_HOST, {
        secure: true,
        extraHeaders: { org_id: orgId, token },
    });
};
