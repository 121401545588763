// Import libraries
import { css } from '@emotion/core';

// Prepare stylesheet
const styles = {
    basicInput: css({
        width: '280px',
    }),
};

export default styles;
