// Import libraries
import { message } from 'antd';
import API from '@aws-amplify/api';

// Import utilities
import { fetchApiErrorMessage } from '../../utilities/api';

export const updateBasic = async values => {
    try {
        const body = {
            firstName: values.firstName,
            lastName: values.lastName,
            timezone: values.timezone,
        };
        await API.put('users', '/me', { body });
        message.success('Basic information updated');
        return body;
    } catch (error) {
        message.error(fetchApiErrorMessage(error));
        return null;
    }
};