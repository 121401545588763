/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import API from '@aws-amplify/api';
import moment from 'moment-timezone';
import { useState, useEffect } from 'react';

// Import Ant Design components
import { Modal, Typography, message } from 'antd';

// Import components
import EventMedia from '../../../../EventReport/EventMedia';

// Import stylesheets
import styles from './styles';

// Import additional Ant Design components
const { Title, Text } = Typography;

const EventPanel = (props) => {
    // Extract values from props
    const {
        visible,
        eventName,
        data = [],
        unixTime,
        timezone = 'Australia/Brisbane',
        onCancel,
    } = props;

    // Initialisation
    const [presignedUrls, setPresignedUrls] = useState([]);

    useEffect(() => {
        const obtainPresignedUrls = async () => {
            try {
                const { presignedUrls = [] } = await API.post('history', '/images/download', {
                    body: { imageUrls: [...data].sort() },
                });
                setPresignedUrls(presignedUrls);
            } catch (error) {
                message.error('Unable to retrieve event images.');
            }
        };

        if (data && Array.isArray(data) && data.length > 0) obtainPresignedUrls();
    }, [data]);

    return (
        <Modal
            title={
                <div style={styles.modalTitleWrapper}>
                    <Title level={4} style={styles.modalEventTitle}>
                        Event Trigger ({eventName})
                    </Title>
                    <Text type="secondary" style={styles.modalEventTime}>
                        {unixTime &&
                            moment.tz(unixTime, timezone).format('MMMM Do YYYY, h:mm:ss a')}
                    </Text>
                </div>
            }
            open={visible}
            onCancel={onCancel}
            footer={null}
            width="650px"
        >
            <EventMedia
                data={presignedUrls}
                imageStyles={{
                    width: '125px',
                    height: '125px',
                }}
            />
        </Modal>
    );
};

export default EventPanel;
