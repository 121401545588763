/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import API from '@aws-amplify/api';
import download from 'downloadjs';
import { findIndex, orderBy, find } from 'lodash';
import { useEffect, useState, useContext } from 'react';
import uuid4 from 'uuid/v4';
import moment from 'moment';

// Import Ant Design component
import {
    Table,
    Button,
    Card,
    PageHeader,
    Input,
    Badge,
    Space,
    Tag,
    Popconfirm,
    Tooltip,
    Modal,
    Descriptions,
    Select,
    Switch,
    Form,
    Divider,
    Typography,
    Row,
    Col,
    message,
} from 'antd';
import {
    EditOutlined,
    PoweroffOutlined,
    PauseOutlined,
    SaveOutlined,
    CloseOutlined,
    PartitionOutlined,
    ClearOutlined,
    CodeOutlined,
    ScissorOutlined,
    LoginOutlined,
    FileSearchOutlined,
} from '@ant-design/icons';

// Import store
import { OrgsContext } from '../../store//OrgsContext';

// Import utilities
import { connectRequestHub } from '../../utilities/requestHub';

// Import constants
import defaultDeviceConfigs from './defaultConfigs';
import { OTMActionCodes, SocketIOChannels } from '../../constants/requestHub';
import { DEFAULT_REGO_SERVER_URL, DEFAULT_LOG_SERVER_URL } from '../../constants/devices';

// Import stylesheet
import styles from './styles';

// Import Option and TextArea components
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;

const SuperDevices = () => {
    // Initialization
    const [loadingDevices, setLoadingDevices] = useState(false);
    const [devices, setDevices] = useState([]);
    const [dealers, setDealers] = useState([]);
    const [searchRid, setSearchRid] = useState('');
    const [searchRego, setSearchRego] = useState('');
    const [searchEnabled, setSearchEnabled] = useState(true);
    const [searchStatus, setSearchStatus] = useState(undefined);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [deviceDealerInEdit, setDeviceDealerInEdit] = useState({});
    const [newDealerId, setNewDealerId] = useState(undefined);
    const [updatingDealer, setUpdatingDealer] = useState(false);
    const [deviceInEdit, setDeviceInEdit] = useState({});
    const [activateModalVisible, setActivateModelVisible] = useState(false);
    const [newRegSvrAddr, setNewRegSvrAddr] = useState(DEFAULT_REGO_SERVER_URL);
    const [newLogSvrAdd, setNewLogSvrAdd] = useState(DEFAULT_LOG_SERVER_URL);
    const [newSN, setNewSN] = useState('0');
    const [newDeviceConfigs, setNewDeviceConfigs] = useState(defaultDeviceConfigs);
    const [updatingStatus, setUpdatingStatus] = useState(false);

    const [commandStatus, setCommandStatus] = useState({});
    const [deviceLogs, setDeviceLogs] = useState({});

    const [configCommandModalVisible, setConfigCommandModalVisible] = useState(false);
    const [sendingSetConfigCommand, setSendingSetConfigCommand] = useState(false);
    const [sendingGetConfigCommand, setSendingGetConfigCommand] = useState(false);
    const [resSetConfig, setResSetConfig] = useState('');
    const [resGetConfig, setResGetConfig] = useState('');

    const [loadingFirmwares, setLoadingFirmwares] = useState(false);
    const [firmwares, setFirmwares] = useState([]);
    const [newFirmwareId, setNewFirmwareId] = useState(undefined);
    const [upgrading, setUpgrading] = useState(false);
    const [upgradeStatus, setUpgradeStatus] = useState({});
    const [clearingHighlights, setClearingHighlights] = useState(false);

    const [reqHubSocket, setReqHubSocket] = useState(null);

    const [setConfigCommandForm] = Form.useForm();
    const [getConfigCommandForm] = Form.useForm();

    // Retrieve currently selected organisation
    const orgContext = useContext(OrgsContext);
    const { selectedOrg } = orgContext;

    useEffect(() => {
        // Establish a websocket connection to request hub
        const startStreaming = async () => {
            const socketIO = await connectRequestHub(selectedOrg);

            // Listen to device operation status events
            socketIO.on(`device-op-status:${selectedOrg}`, (message) => {
                const { actionCode, statusCode, data, deviceId } = message || {};
                const { s: commandStatus, l: deviceLogs } = data || {};

                switch (actionCode) {
                    case OTMActionCodes.UPGRADE_FIRMWARE: {
                        setUpgradeStatus((status) => ({
                            ...status,
                            [deviceId]:
                                statusCode === 2000 && message.s ? message.s : 'Upgrade failed',
                        }));
                        break;
                    }
                    case OTMActionCodes.SEND_COMMAND: {
                        setCommandStatus((status) => ({
                            ...status,
                            [deviceId]:
                                statusCode === 2000 && commandStatus
                                    ? commandStatus
                                    : 'Command failed',
                        }));

                        if (setDeviceLogs) {
                            setDeviceLogs((logs) => ({ ...logs, [deviceId]: deviceLogs }));
                        }

                        break;
                    }
                    default:
                        break;
                }
            });

            setReqHubSocket(socketIO);
        };

        if (!reqHubSocket && selectedOrg) startStreaming();

        return () => {
            if (reqHubSocket) {
                reqHubSocket.disconnect();
                setReqHubSocket(null);
            }
        };
    }, [reqHubSocket, selectedOrg]);

    useEffect(() => {
        // Retrieve all devices
        const fetchDevicesData = async () => {
            setLoadingDevices(true);
            try {
                const devicesData = await API.get('superadmin', '/devices');
                setDevices(devicesData);

                const dealersData = await API.get('superadmin', '/dealers');
                setDealers(dealersData);
            } catch (error) {
                message.error('Unable to fetch all devices');
            }
            setLoadingDevices(false);
        };

        const fetchFirmwares = async () => {
            setLoadingFirmwares(true);
            try {
                const data = await API.get('superadmin', '/firmwares');
                setFirmwares(orderBy(data, ['createdAt'], ['desc']));
            } catch (error) {
                message.error('Unable to fetch firmwares');
            }
            setLoadingFirmwares(false);
        };

        fetchDevicesData();
        fetchFirmwares();
    }, []);

    const assignDeviceToDealer = async () => {
        const { id: deviceId } = deviceDealerInEdit || {};

        // Perform some value checks
        if (!deviceId) message.error('Missing device id');
        else {
            setUpdatingDealer(true);
            try {
                const body = { deviceIds: [deviceId], dealerId: newDealerId };
                await API.put('superadmin', '/devices', { body });
                message.success('Device assigned');
                const idx = findIndex(devices, { id: deviceId });
                const devicesCopy = devices;
                devicesCopy[idx].dealerId = newDealerId;
                setDevices(devicesCopy);
            } catch (error) {
                message.error('Unable to assign device to dealer');
            }
            setUpdatingDealer(false);
            setNewDealerId('');
            setDeviceDealerInEdit({});
        }
    };

    const upgradeDevices = async (upgradeImmediately = true) => {
        setUpgrading(true);

        // Find firmware details
        const matchedFirmware = find(firmwares, { id: newFirmwareId });
        const { name, filePath } = matchedFirmware || {};

        if (name && filePath && reqHubSocket && reqHubSocket.connected) {
            selectedRowKeys.forEach((deviceId) => {
                reqHubSocket.emit(SocketIOChannels.REQUEST, {
                    deviceId: deviceId,
                    actionCode: OTMActionCodes.UPGRADE_FIRMWARE,
                    requestId: uuid4(),
                    d: {
                        p: filePath,
                        v: name,
                        a: upgradeImmediately ? 1 : 2,
                    },
                });
            });

            message.success('Upgrade requests have been submitted');
            setSelectedRowKeys([]);
            setNewFirmwareId(undefined);
            setDevices((currentDevices) =>
                currentDevices.map((device) => ({
                    ...device,
                    ...(selectedRowKeys.includes(device.id) ? { highlighted: true } : {}),
                }))
            );
        } else {
            message.error('Invalid version details or request cannot be submitted');
        }

        setUpgrading(false);
    };

    const assignDevicesToDealer = async () => {
        if (selectedRowKeys.length === 0) {
            message.error('Please select device(s)');
        } else {
            setUpdatingDealer(true);
            try {
                const body = { deviceIds: selectedRowKeys, dealerId: newDealerId };
                await API.put('superadmin', '/devices', { body });
                message.success('Device assigned');
                const devicesCopy = devices.map((device) => {
                    if (selectedRowKeys.indexOf(device.id) < 0) return device;
                    return { ...device, dealerId: newDealerId };
                });
                setDevices(devicesCopy);
                setSelectedRowKeys([]);
                setNewDealerId('');
            } catch (error) {
                message.error('Unable to assign devices to dealer');
            }
            setUpdatingDealer(false);
        }
    };

    const deactivateDevice = async (deviceId) => {
        // Perform some value checks
        if (!deviceId) message.error('Missing device id');
        else {
            setUpdatingStatus(true);
            try {
                const body = {
                    enabled: false,
                    regSvrAddr: '',
                    logSvrAddr: '',
                };
                await API.put('superadmin', `/devices/${deviceId}`, { body });
                message.success('Device deactivated');
                const idx = findIndex(devices, { id: deviceId });
                const devicesCopy = devices;
                devicesCopy[idx].enabled = false;
                devicesCopy[idx].regSvrAddr = '';
                devicesCopy[idx].logSvrAddr = '';
                devicesCopy[idx].sn = '';
                setDevices(devicesCopy);
            } catch (error) {
                message.error('Unable to deactivate device');
            }
            setUpdatingStatus(false);
            setDeviceInEdit({});
        }
    };

    const activateDevice = async () => {
        const { id: deviceId } = deviceInEdit || {};

        // Perform some value checks
        if (!deviceId) message.error('Missing device id');
        else if (!newRegSvrAddr || !newLogSvrAdd || !newSN) {
            message.error('Missing reg/log server addresss or SN');
        } else {
            setUpdatingStatus(true);
            try {
                const body = {
                    enabled: true,
                    regSvrAddr: newRegSvrAddr,
                    logSvrAddr: newLogSvrAdd,
                    sn: newSN,
                    deviceConfigs: JSON.parse(newDeviceConfigs),
                };
                await API.put('superadmin', `/devices/${deviceId}`, { body });
                message.success('Device activated');
                const idx = findIndex(devices, { id: deviceId });
                const devicesCopy = devices;
                devicesCopy[idx].enabled = true;
                devicesCopy[idx].regSvrAddr = newRegSvrAddr;
                devicesCopy[idx].logSvrAddr = newLogSvrAdd;
                devicesCopy[idx].sn = newSN;
                setDevices(devicesCopy);
            } catch (error) {
                message.error('Unable to activate device');
            }
            setUpdatingStatus(false);
            setDeviceInEdit({});
        }
    };

    const sendDeviceCommand = async (deviceId, d) => {
        if (reqHubSocket && reqHubSocket.connected) {
            reqHubSocket.emit(SocketIOChannels.REQUEST, {
                deviceId,
                actionCode: OTMActionCodes.SEND_COMMAND,
                requestId: uuid4(),
                d,
            });

            message.success('Command sent');
        }
    };

    const onSetConfigCommandSent = async (values) => {
        // Extract command
        const { command } = values || {};

        try {
            setResSetConfig('');
            setSendingSetConfigCommand(true);

            if (reqHubSocket) {
                const requestId = uuid4();

                reqHubSocket.emit(SocketIOChannels.REQUEST, {
                    deviceId: deviceInEdit.id,
                    actionCode: OTMActionCodes.SET_DEVICE_CONFIGS,
                    requestId,
                    d: JSON.parse(command),
                });
                message.info('Command sent');

                const resEvent = `${SocketIOChannels.REQUEST}:${requestId}`;
                const timeoutId = setTimeout(() => {
                    reqHubSocket.removeAllListeners(resEvent);
                    setResSetConfig('Request timed out');
                }, 10000);
                reqHubSocket.on(resEvent, ({ statusCode }) => {
                    clearTimeout(timeoutId);
                    setResSetConfig(
                        statusCode === 2000 ? 'Device configured' : 'Failed to configure device'
                    );
                });
            }
        } catch (error) {
            message.error('Unable to set device configurations, please try again later');
        } finally {
            setSendingSetConfigCommand(false);
        }
    };

    const onGetConfigCommandSent = async (values) => {
        // Extract command
        const { command } = values || {};

        try {
            setResGetConfig('');
            setSendingGetConfigCommand(true);

            if (reqHubSocket) {
                const requestId = uuid4();

                reqHubSocket.emit(SocketIOChannels.REQUEST, {
                    deviceId: deviceInEdit.id,
                    actionCode: OTMActionCodes.GET_DEVICE_CONFIGS,
                    requestId,
                    g: command,
                });
                message.info('Command sent');

                const resEvent = `${SocketIOChannels.REQUEST}:${requestId}`;
                const timeoutId = setTimeout(() => {
                    reqHubSocket.removeAllListeners(resEvent);
                    setResGetConfig('Request timed out');
                }, 10000);
                reqHubSocket.on(resEvent, ({ statusCode, data }) => {
                    clearTimeout(timeoutId);
                    setResGetConfig(
                        statusCode === 2000
                            ? JSON.stringify(data)
                            : 'Failed to get device configurations'
                    );
                });
            }
        } catch (error) {
            message.error('Unable to get device configurations, please try again later');
        } finally {
            setSendingGetConfigCommand(false);
        }
    };

    const clearHighlights = async (deviceIds) => {
        setClearingHighlights(true);
        try {
            await API.post('superadmin', `/clear-device-highlights`, {
                body: { deviceIds },
            });

            setDevices((currentDevices) =>
                currentDevices.map((device) => ({
                    ...device,
                    ...(deviceIds.includes(device.id) ? { highlighted: false } : {}),
                }))
            );

            message.success('Cleared highlights');
        } catch (error) {
            message.error('Unable to clear highlights');
        } finally {
            setClearingHighlights(false);
        }
    };

    const cleanUp = () => {
        setActivateModelVisible(false);
        setNewRegSvrAddr(DEFAULT_REGO_SERVER_URL);
        setNewLogSvrAdd(DEFAULT_LOG_SERVER_URL);
        setNewSN('0');
        setNewDeviceConfigs(defaultDeviceConfigs);
        setConfigCommandModalVisible(false);
        setDeviceInEdit({});
        setClearingHighlights(false);
    };

    const getUpgradeStatusColor = (status) => {
        switch (status) {
            case 'Success':
                return 'success';
            case 'Upgrade failed':
            case 'Failed: download':
                return 'error';
            default:
                return 'processing';
        }
    };

    const getCommandStatusColor = (status) => {
        switch (status) {
            case 'COMPLETED':
                return 'success';
            case 'Command failed':
                return 'error';
            default:
                return 'processing';
        }
    };

    // Configure table columns
    const columns = [
        {
            title: 'Rid',
            dataIndex: 'rid',
            render: (value, device) => (
                <Space direction="vertical" size={6}>
                    <Badge
                        status={device.enabled ? 'success' : 'default'}
                        text={<Text mark={device.highlighted}>{value || 'N/A'}</Text>}
                    />

                    {commandStatus[device.id] ? (
                        <Tag color={getCommandStatusColor(commandStatus[device.id])}>
                            {commandStatus[device.id]}
                        </Tag>
                    ) : null}

                    {deviceLogs[device.id] ? (
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                                const buff = Buffer.from(deviceLogs[device.id], 'base64');
                                download(
                                    new Blob([buff]),
                                    `logs-${device.id}.gz`,
                                    'application/octet-stream'
                                );
                            }}
                        >
                            Download logs
                        </Button>
                    ) : null}
                </Space>
            ),
            sorter: (a, b) => (a.rid > b.rid ? 1 : -1),
        },
        {
            title: 'Rego',
            dataIndex: 'rego',
            render: (value) => value || 'N/A',
            sorter: (a, b) => (a.rego > b.rego ? 1 : -1),
        },
        {
            title: 'Model',
            dataIndex: 'model',
            render: (value) => value || 'N/A',
            sorter: (a, b) => (a.model > b.model ? 1 : -1),
        },
        {
            title: 'Version',
            dataIndex: 'version',
            render: (value, device) => (
                <Space direction="vertical">
                    <span>{value || 'N/A'}</span>
                    {upgradeStatus[device.id] ? (
                        <Tag color={getUpgradeStatusColor(upgradeStatus[device.id])}>
                            {upgradeStatus[device.id]}
                        </Tag>
                    ) : null}
                </Space>
            ),
            sorter: (a, b) => (a.version > b.version ? 1 : -1),
        },
        {
            title: 'Status',
            dataIndex: 'online',
            width: '100px',
            render: (value) =>
                value === 'online' ? (
                    <Badge status="success" text={value} />
                ) : (
                    <Badge status="default" text={value} />
                ),
            sorter: (a) => (a.online === 'online' ? 1 : -1),
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (value) => moment(value).format('DD/MM/YYYY'),
            sorter: (a, b) => (a.createdAt > b.createdAt ? 1 : -1),
        },
        {
            title: 'Dealer',
            dataIndex: 'dealerName',
            render: (_, device) => (
                <Select
                    value={device.id === deviceDealerInEdit.id ? newDealerId : device.dealerId}
                    onChange={(value) => setNewDealerId(value)}
                    dropdownMatchSelectWidth={false}
                    style={{ width: '180px' }}
                    disabled={device.id !== deviceDealerInEdit.id}
                >
                    {dealers.map((dealer) => (
                        <Option key={dealer.id} value={dealer.id}>
                            {dealer.name}
                        </Option>
                    ))}
                    <Option key={null} value={null}>
                        Unassigned
                    </Option>
                </Select>
            ),
        },
        {
            render: (_, device) =>
                device.id === deviceDealerInEdit.id ? (
                    <div css={{ display: 'flex' }}>
                        <Button
                            shape="circle"
                            size="small"
                            icon={<SaveOutlined />}
                            onClick={() => assignDeviceToDealer()}
                            css={{ marginRight: '10px' }}
                            loading={updatingDealer}
                        />
                        <Button
                            shape="circle"
                            size="small"
                            icon={<CloseOutlined />}
                            onClick={() => {
                                setNewDealerId('');
                                setDeviceDealerInEdit({});
                            }}
                        />
                    </div>
                ) : (
                    <Space css={{ '.ant-divider.ant-divider-vertical': { margin: 0 } }}>
                        {/* Edit dealer */}
                        <Tooltip placement="leftBottom" title="Edit Dealer">
                            <Button
                                shape="circle"
                                size="small"
                                icon={<EditOutlined />}
                                onClick={() => setDeviceDealerInEdit(device)}
                                disabled={selectedRowKeys.length > 0}
                            />
                        </Tooltip>

                        <Divider type="vertical" />

                        {/* Activate or deactivate device */}
                        <Tooltip
                            placement="rightBottom"
                            title={`${device.enabled ? 'Deactivate' : 'Activate'} device`}
                        >
                            {device.enabled ? (
                                <Popconfirm
                                    title="Are you sure deactivate this device?"
                                    onConfirm={() => {
                                        setDeviceInEdit(device);
                                        deactivateDevice(device.id);
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button
                                        shape="circle"
                                        size="small"
                                        icon={<PauseOutlined />}
                                        disabled={selectedRowKeys.length > 0}
                                        loading={device.id === deviceInEdit.id && updatingStatus}
                                    />
                                </Popconfirm>
                            ) : (
                                <Button
                                    shape="circle"
                                    size="small"
                                    icon={<PoweroffOutlined />}
                                    onClick={() => {
                                        setDeviceInEdit(device);
                                        setActivateModelVisible(true);
                                    }}
                                    disabled={selectedRowKeys.length > 0}
                                    loading={device.id === deviceInEdit.id && updatingStatus}
                                />
                            )}
                        </Tooltip>

                        <Divider type="vertical" />

                        {/* Format SD Card */}
                        <Tooltip placement="rightBottom" title="Format SD card">
                            <Popconfirm
                                title="Are you sure format device SD card?"
                                onConfirm={() => sendDeviceCommand(device.id, 'Storage.sdformat')}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button shape="circle" size="small" icon={<ClearOutlined />} />
                            </Popconfirm>
                        </Tooltip>

                        {/* Reconnect network */}
                        <Tooltip placement="leftBottom" title="Reconnect network">
                            <Popconfirm
                                title="Are you sure reconnect device network?"
                                onConfirm={() => sendDeviceCommand(device.id, 'Network.reconnect')}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button shape="circle" size="small" icon={<PartitionOutlined />} />
                            </Popconfirm>
                        </Tooltip>

                        {/* Reboot system */}
                        <Tooltip placement="rightBottom" title="Reboot system">
                            <Popconfirm
                                title="Are you sure reboot device system?"
                                onConfirm={() => sendDeviceCommand(device.id, 'System.reboot')}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button shape="circle" size="small" icon={<LoginOutlined />} />
                            </Popconfirm>
                        </Tooltip>

                        {/* Upload logs */}
                        <Tooltip placement="rightBottom" title="Request logs">
                            <Popconfirm
                                title="Are you sure upload device logs?"
                                onConfirm={() => sendDeviceCommand(device.id, 'System.logupload')}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button shape="circle" size="small" icon={<FileSearchOutlined />} />
                            </Popconfirm>
                        </Tooltip>

                        {/* Format SSD Card */}
                        <Tooltip placement="rightBottom" title="Format SSD card">
                            <Popconfirm
                                title="Are you sure format device SSD card?"
                                onConfirm={() => sendDeviceCommand(device.id, 'Storage.ssdformat')}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button shape="circle" size="small" icon={<ScissorOutlined />} />
                            </Popconfirm>
                        </Tooltip>

                        <Divider type="vertical" />

                        {/* Set/Get device configuration */}
                        <Tooltip placement="rightBottom" title="Set/Get device configuration">
                            <Button
                                shape="circle"
                                size="small"
                                icon={<CodeOutlined />}
                                onClick={() => {
                                    setDeviceInEdit(device);
                                    setConfigCommandModalVisible(true);
                                }}
                            />
                        </Tooltip>
                    </Space>
                ),
        },
    ];

    return (
        <div>
            <PageHeader
                title="All Devices"
                subTitle={`${devices.length} devices found`}
                ghost={false}
            />

            <div css={styles.contentWrapper}>
                <Card>
                    {/** Query panel */}
                    <div css={styles.queryPanel}>
                        <span css={styles.queryLabel}>Rid:</span>
                        <Input
                            placeholder="Enter device rid"
                            value={searchRid}
                            onChange={(e) => setSearchRid(e.target.value)}
                            css={styles.queryInput}
                        />
                        <span css={styles.queryLabel}>Rego:</span>
                        <Input
                            placeholder="Enter device rego"
                            value={searchRego}
                            onChange={(e) => setSearchRego(e.target.value)}
                            css={styles.queryInput}
                        />
                        <span css={styles.queryLabel}>Status:</span>
                        <Select
                            value={searchStatus}
                            onChange={(value) => setSearchStatus(value)}
                            placeholder="Select a status"
                            dropdownMatchSelectWidth={false}
                            css={styles.queryInput}
                        >
                            <Option key="online" value="online">
                                <Badge status="success" text="Online" />
                            </Option>
                            <Option key="offline" value="offline">
                                <Badge status="default" text="Offline" />
                            </Option>
                            <Option key="timeout" value="timeout">
                                <Badge status="default" text="Timeout" />
                            </Option>
                        </Select>
                        <span css={styles.queryLabel}>Enabled:</span>
                        <Switch
                            checked={searchEnabled}
                            onChange={(value) => setSearchEnabled(value)}
                            css={styles.querySwitch}
                        />
                        <Button
                            onClick={() => {
                                setSearchRid('');
                                setSearchRego('');
                                setSearchEnabled(true);
                                setSearchStatus(undefined);
                            }}
                            css={{ marginRight: '15px' }}
                        >
                            Clear
                        </Button>

                        <Button
                            onClick={async () => {
                                await clearHighlights(devices.map(({ id }) => id));
                            }}
                        >
                            Clear highlights
                        </Button>
                    </div>

                    {selectedRowKeys.length > 0 && (
                        <div css={{ margin: '30px 0px' }}>
                            <div css={styles.queryPanel}>
                                <span css={styles.queryLabel}>
                                    Assign {selectedRowKeys.length}{' '}
                                    {selectedRowKeys.length === 1 ? 'device' : 'devices'} to dealer
                                </span>
                                <Select
                                    value={newDealerId}
                                    onChange={(value) => setNewDealerId(value)}
                                    placeholder="Select a dealer"
                                    dropdownMatchSelectWidth={false}
                                    css={styles.queryInput}
                                >
                                    {dealers.map((dealer) => (
                                        <Option key={dealer.id} value={dealer.id}>
                                            {dealer.name}
                                        </Option>
                                    ))}
                                    <Option key={null} value={null}>
                                        Unassigned
                                    </Option>
                                </Select>
                                <Button
                                    type="primary"
                                    loading={updatingDealer}
                                    onClick={() => assignDevicesToDealer()}
                                    disabled={newDealerId === undefined}
                                    css={{ marginRight: '15px' }}
                                >
                                    Assign
                                </Button>
                                <Button
                                    onClick={() => {
                                        setSelectedRowKeys([]);
                                        setNewDealerId(undefined);
                                    }}
                                >
                                    Deselect all
                                </Button>
                            </div>

                            <div css={styles.upgradePanel}>
                                <span css={styles.queryLabel}>
                                    Upgrade {selectedRowKeys.length}{' '}
                                    {selectedRowKeys.length === 1 ? 'device' : 'devices'} to
                                    firmware
                                </span>
                                <Select
                                    value={newFirmwareId}
                                    onChange={(value) => setNewFirmwareId(value)}
                                    placeholder="Select a firmware"
                                    loading={loadingFirmwares}
                                    dropdownMatchSelectWidth={false}
                                    css={styles.queryInput}
                                >
                                    {firmwares.map((firmware) => (
                                        <Option key={firmware.id} value={firmware.id}>
                                            {firmware.name}
                                        </Option>
                                    ))}
                                </Select>
                                <Button
                                    type="primary"
                                    loading={upgrading}
                                    onClick={() => upgradeDevices()}
                                    disabled={newFirmwareId === undefined}
                                    css={{ marginRight: '15px' }}
                                >
                                    Upgrade immediately
                                </Button>
                                <Button
                                    type="primary"
                                    loading={upgrading}
                                    onClick={() => upgradeDevices(false)}
                                    disabled={newFirmwareId === undefined}
                                    css={{ marginRight: '15px' }}
                                >
                                    Upgrade after restart
                                </Button>
                                <Button
                                    type="primary"
                                    loading={clearingHighlights}
                                    onClick={async () => {
                                        await clearHighlights(selectedRowKeys);
                                        setSelectedRowKeys([]);
                                    }}
                                >
                                    Clear highlights
                                </Button>
                            </div>
                        </div>
                    )}

                    <Table
                        columns={columns}
                        loading={loadingDevices}
                        rowKey="id"
                        dataSource={devices.filter(
                            (value) =>
                                value.rid?.toLowerCase()?.includes(searchRid.toLowerCase()) &&
                                value.rego?.toLowerCase()?.includes(searchRego.toLowerCase()) &&
                                value.enabled === searchEnabled &&
                                (searchStatus === undefined || value.online === searchStatus)
                        )}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: (keys) => setSelectedRowKeys(keys),
                        }}
                    />
                </Card>
            </div>

            <Modal
                title={`Activate ${deviceInEdit.rid}`}
                open={activateModalVisible}
                onOk={() => {
                    cleanUp();
                    activateDevice();
                }}
                onCancel={cleanUp}
                width={700}
            >
                <Descriptions bordered column={1}>
                    <Descriptions.Item label="Reg Server Address">
                        <Input
                            placeholder="Enter reg server address"
                            onChange={(e) => setNewRegSvrAddr(e.target.value)}
                            value={newRegSvrAddr}
                            style={{ width: '350px' }}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="Log Server Address">
                        <Input
                            placeholder="Enter log server address"
                            onChange={(e) => setNewLogSvrAdd(e.target.value)}
                            value={newLogSvrAdd}
                            style={{ width: '350px' }}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="SN">
                        <Input
                            placeholder="Enter SN"
                            onChange={(e) => setNewSN(e.target.value)}
                            value={newSN}
                            style={{ width: '350px' }}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="Device Configurations">
                        <Input.TextArea
                            rows={4}
                            placeholder="Enter device configurations"
                            onChange={(e) => setNewDeviceConfigs(e.target.value)}
                            value={newDeviceConfigs}
                            style={{ width: '350px' }}
                        />
                    </Descriptions.Item>
                </Descriptions>
            </Modal>

            <Modal
                title={`Fleet ${deviceInEdit?.rego ?? 'N/A'} - ${deviceInEdit?.version ?? 'N/A'}`}
                open={configCommandModalVisible}
                onCancel={() => {
                    cleanUp();
                    setConfigCommandForm.resetFields();
                    getConfigCommandForm.resetFields();
                }}
                footer={false}
                width={1000}
            >
                <Row>
                    <Col span={11}>
                        <Form
                            form={getConfigCommandForm}
                            layout="vertical"
                            onFinish={onGetConfigCommandSent}
                        >
                            <Title level={4}>Get configurations</Title>
                            <Form.Item name="command" label="Command" rules={[{ required: true }]}>
                                <TextArea rows={4} placeholder="Enter command (1004)" />
                            </Form.Item>

                            <Text>Response: </Text>
                            <TextArea
                                rows={4}
                                disabled
                                value={resGetConfig}
                                style={{ margin: '10px 0 20px 0' }}
                            />

                            <Form.Item style={{ textAlign: 'right' }}>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    loading={sendingGetConfigCommand}
                                >
                                    Send
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>

                    <Col span={2} style={{ textAlign: 'center' }}>
                        <Divider type="vertical" style={{ height: '100%' }} />
                    </Col>

                    <Col span={11}>
                        <Form
                            form={setConfigCommandForm}
                            layout="vertical"
                            onFinish={onSetConfigCommandSent}
                        >
                            <Title level={4}>Set configurations</Title>
                            <Form.Item name="command" label="Command" rules={[{ required: true }]}>
                                <TextArea rows={4} placeholder="Enter command (1005)" />
                            </Form.Item>

                            <Text>Response: </Text>
                            <TextArea
                                rows={4}
                                disabled
                                value={resSetConfig}
                                style={{ margin: '10px 0 20px 0' }}
                            />

                            <Form.Item style={{ textAlign: 'right' }}>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    loading={sendingSetConfigCommand}
                                >
                                    Send
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
};

export default SuperDevices;
