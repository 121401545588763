/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { Link } from 'react-router-dom';

export const breadcrumbRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={'/' + paths.join('/')}>{route.breadcrumbName}</Link>
    );
};
