// Import libraries
import { css } from '@emotion/core';

// Import presets
import { content } from '../../styles/presets';

// Prepare stylesheet
const styles = {
    contentWrapper: css({ ...content, maxWidth: '1280px' }),
    controlPanel: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '30px',
    },
    queryPanel: css({
        display: 'flex',
        alignItems: 'center',
    }),
    queryLabel: css({ marginRight: '10px' }),
    queryInput: css({
        width: '200px',
        marginRight: '30px',
    }),
};

export default styles;
