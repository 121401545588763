// Import libraries
import { useState, useEffect } from 'react';

const RequestTimer = ({ requestId, notifications }) => {
    // Initialisation
    const [_, setCounter] = useState(0);

    useEffect(() => {
        if (!requestId) return;

        const intervalRef = setInterval(
            () =>
                setCounter((prevCounter) => {
                    // Increment counter
                    const nextSec = prevCounter + 1;

                    // Exeucte notification callback if it exists
                    if (nextSec in notifications) notifications[nextSec]();

                    return nextSec;
                }),
            1000
        );

        return () => {
            clearInterval(intervalRef);
            setCounter(0);
        };
    }, [requestId, setCounter]);

    return null;
};

export default RequestTimer;
