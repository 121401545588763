/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { useFormik } from 'formik';

// Import Ant Design components
import { Button, Descriptions, Input } from 'antd';

const EditVehicle = ({
    editLoading, selectedVehicle, onSubmit, onCancel, isDealer,
}) => {
    const formik = useFormik({
        initialValues: {
            rego: selectedVehicle.rego,
            make: selectedVehicle.make,
            model: selectedVehicle.model,
            plate: selectedVehicle.plate,
        },
        onSubmit: onSubmit,
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Descriptions column={1} css={{ marginBottom: '30px' }} bordered>
                <Descriptions.Item label="Fleet ID">
                    <Input
                        id="rego"
                        name="rego"
                        placeholder="Enter vehicle rego"
                        onChange={formik.handleChange}
                        value={formik && formik.values ? formik.values.rego : ''}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Plate">
                    <Input
                        id="plate"
                        name="plate"
                        placeholder="Enter vehicle plate"
                        onChange={formik.handleChange}
                        value={formik && formik.values ? formik.values.plate : ''}
                        disabled={!isDealer}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Make">
                    <Input
                        id="make"
                        name="make"
                        placeholder="Enter vehicle make"
                        onChange={formik.handleChange}
                        value={formik && formik.values ? formik.values.make : ''}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Body">
                    <Input
                        id="model"
                        name="model"
                        placeholder="Enter vehicle body"
                        onChange={formik.handleChange}
                        value={formik && formik.values ? formik.values.model : ''}
                    />
                </Descriptions.Item>
            </Descriptions>

            <Button
                htmlType="submit"
                type="primary"
                loading={editLoading}
                disabled={!formik.values.rego}
                css={{ width: '100px' }}
            >
                Save
            </Button>
            <Button
                onClick={onCancel}
                css={{ marginLeft: '10px' }}
            >
                Cancel
            </Button>
        </form>
    );
};

export default EditVehicle;
