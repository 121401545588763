// Import libraries
import { message } from 'antd';
import API from '@aws-amplify/api';

// Import utilities
import { fetchApiErrorMessage } from '../../utilities/api';

export const deleteVehicleGroups = async (selectedOrg, vehicleGroups) => {
    try {
        const deletePromises = vehicleGroups.map(vehicleGroup =>
            API.del(
                'organisations',
                `/${ selectedOrg }/vehicle-groups/${ vehicleGroup.id }`
            ));
        await Promise.all(deletePromises);

        message.success('Vehicle group(s) deleted');
    } catch (error) {
        message.error(fetchApiErrorMessage(error));
    }
};

export const createVehicleGroup = async (selectedOrg, values) => {
    try {
        await API.post(
            'organisations',
            `/${ selectedOrg }/vehicle-groups`,
            {
                body: {
                    name: values.name,
                    color: values.color,
                }
            }
        );
        message.success('Vehicle group created');
    } catch (error) {
        message.error(fetchApiErrorMessage(error));
    }
};

export const updateVehicleGroup = async (selectedOrg, values, vehicleGroupId) => {
    try {
        await API.put(
            'organisations',
            `/${ selectedOrg }/vehicle-groups/${ vehicleGroupId }`,
            {
                body: {
                    name: values.name,
                    color: values.color,
                }
            }
        );
        message.success('Vehicle group updated');
    } catch (error) {
        message.error(fetchApiErrorMessage(error));
    }
};
