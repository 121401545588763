/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import Ant Design components
import { Card, Tabs } from 'antd';

// Import components
import Basic from './components/Basic';
import Security from './components/Security';
import Notifications from './components/Notifications';

// Import stylesheet
import styles from './styles';

// Import the TabPane component
const { TabPane } = Tabs;

const Account = () => {
    return (
        <div css={styles.contentWrapper}>
            <Card>
                <Tabs
                    defaultActiveKey="security"
                    tabPosition="left"
                >
                    {/** Basic */}
                    <TabPane tab="Basic Information" key="basic">
                        <Basic />
                    </TabPane>

                    {/** Security */}
                    <TabPane tab="Security Settings" key="security">
                        <Security />
                    </TabPane>

                    {/** Notifications */}
                    <TabPane tab="Notification Settings" key="notifications">
                        <Notifications />
                    </TabPane>
                </Tabs>
            </Card>
        </div>
    );
};

export default Account;
