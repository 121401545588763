const routes = [
    {
        path: '/admin/maintenance',
        breadcrumbName: 'Maintenance',
    },
    {
        breadcrumbName: 'History',
    },
];

export default routes;