// Import presets
import { content, colors } from '../../styles/presets';

// Prepare stylesheet
const styles = {
    contentWrapper: { ...content },
    adminTagColor: colors.primary,
    controlPanel: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    clearButton: {
        marginLeft: '15px',
        cursor: 'pointer',
        color: colors.primary,
    },
    queryPanel: {
        display: 'flex',
        alignItems: 'center',
    },
    queryLabel: { marginRight: '10px' },
    queryInput: {
        width: '200px',
        marginRight: '30px',
    },
    actionPanel: {
        display: 'flex',
        marginTop: '30px',
    },
};

export default styles;
