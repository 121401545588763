/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import API from '@aws-amplify/api';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

// Import Ant Design components
import { Card, PageHeader, Table, message } from 'antd';

// Import stylesheet
import styles from './styles';

const SuperBillings = () => {
    // Initialization
    const [loadingBillings, setLoadingBillings] = useState(false);
    const [billings, setBillings] = useState([]);

    useEffect(() => {
        const fetchBillings = async () => {
            setLoadingBillings(true);
            try {
                const data = await API.get('dealers', '/me/billings');
                setBillings(data);
            } catch (error) {
                message.error('Unable to fetch organisation billings');
            }
            setLoadingBillings(false);
        };

        fetchBillings();
    }, []);

    // Configure table columns
    const columns = [
        {
            title: 'Organisation Name',
            dataIndex: 'orgName',
            render: value => value || 'N/A',
        },
        {
            title: 'Number of active devices',
            dataIndex: 'numOfDevices',
            render: value => value || 'N/A',
        },
        {
            title: 'Billing Year',
            dataIndex: 'createdAt',
            render: value => moment(value).year() || 'N/A',
        },
        {
            title: 'Billing Month',
            dataIndex: 'createdAt',
            render: value => moment(value).month() + 1 || 'N/A',
        },
    ];

    return (
        <div>
            <PageHeader
                title="All Billings"
                subTitle={`${ billings.length } billings found`}
                ghost={false}
            />

            <div css={styles.contentWrapper}>
                <Card title="Billing Reports">
                    <Table
                        columns={columns}
                        loading={loadingBillings}
                        rowKey="id"
                        dataSource={billings}
                        // css={{ marginTop: '30px' }}
                    />
                </Card>
            </div>
        </div>
    );
};

export default SuperBillings;
