/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import API from '@aws-amplify/api';
import uuid4 from 'uuid/v4';
import { findIndex, find } from 'lodash';
import { useContext, useState, useEffect } from 'react';

// Import Ant Design components
import {
    Card, Table, Button, Input, Select,
    Badge, PageHeader, message
} from 'antd';

// Import store
import { OrgsContext } from '../../store/OrgsContext';

// Import stylesheet
import styles from './styles';

// Import Option component
const { Option } = Select;

const AdminVehicleDevices = props => {
    // Initialization
    const [loadingVehicleDevices, setLoadingVehicleDevices] = useState(false);
    const [searchRego, setSearchRego] = useState('');
    const [vehicleDevices, setVehicleDevices] = useState([]);
    const [dealerDevices, setDealerDevices] = useState([]);
    const [assignableDeviceIds, setAssignableDeviceIds] = useState([]);

    const [inEditMode, setInEditMode] = useState(false);
    const [newAssignments, setNewAssignments] = useState({});
    const [savingChanges, setSavingChanges] = useState(false);
    const [dataFetchedAt, setDataFetchedAt] = useState(new Date().getTime());

    // Retrieve currently selected organisation
    const orgsContext = useContext(OrgsContext);
    const { selectedOrg } = orgsContext;

    useEffect(() => {
        const getVehicleDevices = async orgId => {
            // Initialization
            setLoadingVehicleDevices(true);

            // Retrieve vehicle device information within current organisation
            try {
                const vehicleDevicesData = await API.get(
                    'organisations',
                    `/${ orgId }/vehicle-devices`
                );
                setVehicleDevices(vehicleDevicesData);
            } catch (error) {
                message.error('Unable to fetch vehicle devices');
            }

            // Retrieve all dealer's devices
            try {
                const dealerDevicesData = await API.get(
                    'dealers',
                    '/me/devices'
                );
                setDealerDevices(dealerDevicesData.dealerDevices);
                setAssignableDeviceIds(dealerDevicesData.assignableDeviceIds);
            } catch (error) {
                message.error('Unable to fetch organisation devices');
            }
            setLoadingVehicleDevices(false);
        };

        if (selectedOrg) getVehicleDevices(selectedOrg);
    }, [selectedOrg, dataFetchedAt]);

    const assignDevices = async () => {
        setSavingChanges(true);

        // Check assignment duplication
        const newVehicleDevicesCopy = vehicleDevices.map(vehicleDevice => {
            if (vehicleDevice.vehicleId in newAssignments) {
                const deviceId = newAssignments[vehicleDevice.vehicleId];
                if (!deviceId) {
                    return {
                        ...vehicleDevice, deviceId: null, deviceRid: null, deviceEnabled: false,
                    };
                }
                const deviceIdx = findIndex(dealerDevices, { deviceId });
                const { rid: deviceRid, enabled: deviceEnabled } = dealerDevices[deviceIdx];
                return { ...vehicleDevice, deviceId, deviceRid, deviceEnabled };
            }
            return vehicleDevice;
        });

        if (new Set(newVehicleDevicesCopy.map(device => device.deviceId || uuid4())).size !== newVehicleDevicesCopy.length) {
            message.error('Duplicated assignment of a same device found, please update your values');
        } else {
            try {
                const body = { newAssignments, orgId: selectedOrg };
                await API.put('dealers', '/me/devices', { body });
                message.success('Device(s) assigned');

                // setVehicleDevices(newVehicleDevicesCopy);
                setNewAssignments({});
                setInEditMode(false);
                setDataFetchedAt(new Date().getTime());
            } catch (error) {
                message.error('Unable to assign device(s) to vehicle(s)');
            }
        }

        setSavingChanges(false);
    };

    // Configure table columns
    const columns = [
        {
            title: 'Vehicle Rego',
            dataIndex: 'vehicleRego',
            render: value => value || 'N/A',
        },
        {
            title: 'Device',
            dataIndex: 'deviceId',
            render: (deviceId, vehicle) => (
                <Select
                    value={newAssignments[vehicle.vehicleId] !== undefined ? newAssignments[vehicle.vehicleId] : deviceId}
                    onChange={newDeviceId => {
                        const assignmentCopy = { ...newAssignments };

                        if (newDeviceId === vehicle.deviceId) {
                            delete assignmentCopy[vehicle.vehicleId];
                        } else {
                            assignmentCopy[vehicle.vehicleId] = newDeviceId;
                        }

                        setNewAssignments(assignmentCopy);
                    }}
                    style={{ width: '300px' }}
                    disabled={!inEditMode}
                    showSearch
                    filterOption={(input, option) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                >
                    {dealerDevices
                        .map(device => {
                            const deviceIdValue = newAssignments[vehicle.vehicleId] || deviceId;
                            if (assignableDeviceIds.indexOf(device.deviceId) >= 0 || deviceIdValue === device.deviceId) {
                                return (
                                    <Option
                                        key={device.deviceId}
                                        value={device.deviceId}
                                        disabled={deviceIdValue && deviceIdValue !== device.deviceId}
                                    >
                                        {device.rid} ({device.dvrRego})
                                    </Option>
                                );
                            }
                            return null;
                        })}
                    <Option
                        key="unassigned"
                        value={null}
                        disabled={!newAssignments[vehicle.vehicleId] && !deviceId}
                    >
                        Unassigned
                    </Option>
                </Select>
            ),
        },
        {
            title: 'Device Enabled',
            dataIndex: 'deviceEnabled',
            render: (enabled, vehicle) => {
                if (vehicle.vehicleId in newAssignments) {
                    const newDevice = find(dealerDevices, { deviceId: newAssignments[vehicle.vehicleId] });
                    if (newDevice && newDevice.enabled) return (<Badge status="success" text="Enabled" />);
                    return (<Badge status="default" text="Disabled" />);
                }
                return enabled
                    ? (<Badge status="success" text="Enabled" />)
                    : (<Badge status="default" text="Disabled" />);
            }
        },
    ];

    return (
        <div>
            <PageHeader
                title="Vehicle Devices"
                subTitle={`${ vehicleDevices.length } results found`}
                ghost={false}
            />

            <div css={styles.contentWrapper}>
                <Card>
                    <div css={styles.controlPanel}>
                        {/** Query panel */}
                        <div css={styles.queryPanel}>
                            <span css={styles.queryLabel}>Search Rego:</span>
                            <Input
                                placeholder="Enter vehicle rego"
                                value={searchRego}
                                onChange={e => setSearchRego(e.target.value)}
                                css={styles.queryInput}
                            />
                            <Button onClick={() => setSearchRego('')}>Clear</Button>
                        </div>

                        {/* Action panel */}
                        {!inEditMode && (
                            <Button type="primary" onClick={() => setInEditMode(true)}>
                                Edit
                            </Button>
                        )}

                        {inEditMode && (<div css={{ display: 'flex' }}>
                            <Button
                                type="primary"
                                onClick={assignDevices}
                                loading={savingChanges}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => {
                                    setInEditMode(false);
                                    setNewAssignments({});
                                }}
                                css={{ marginLeft: '15px' }}
                            >
                                Cancel
                            </Button>
                        </div>)
                        }
                    </div>

                    <Table
                        columns={columns}
                        loading={loadingVehicleDevices}
                        rowKey="id"
                        dataSource={vehicleDevices.filter(value =>
                            value.vehicleRego.toLowerCase().includes(searchRego.toLowerCase()))}
                        css={{ marginTop: '20px' }}
                    />
                </Card>
            </div>
        </div>
    );
};

export default AdminVehicleDevices;
