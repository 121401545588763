// Import library
import { css } from '@emotion/core';

const styles = {
    footer: css({
        textAlign: 'center',
        color: 'rgba(0,0,0,.45)',
    }),
    footerLinks: css({
        display: 'flex',
        marginBottom: '8px',
        justifyContent: 'center',
        a: {
            margin: '0 20px',
            color: 'rgba(0,0,0,.45)',
        },
    }),
};

export default styles;
