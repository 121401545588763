// Import libraries
import { FlyToInterpolator } from 'deck.gl';

// A list of available Mapbox styles
export const MAPBOX_STYLES = {
    basic: 'mapbox://styles/mapbox/basic-v9',
    streets: 'mapbox://styles/mapbox/streets-v10',
    outdoors: 'mapbox://styles/mapbox/outdoors-v10',
    light: 'mapbox://styles/mapbox/light-v9',
    dark: 'mapbox://styles/mapbox/dark-v9',
    satellite: 'mapbox://styles/mapbox/satellite-v9',
    satelliteStreets: 'mapbox://styles/mapbox/satellite-streets-v10',
    custom: 'mapbox://styles/allenlh0/cju1gpldj0ty21fnaqs2wxwuj',
    newCustom: 'mapbox://styles/beiowolf/cka9ih76q2t3y1jn20hcddnrp',
};

// Initial viewport
export const INITIAL_VIEWPORT = {
    // Default to Brisbane
    latitude: -27.469770,
    longitude: 153.025131,
    // Zoom, bearing and pitch
    zoom: 12,
    bearing: 0,
    pitch: 0,
    // View transition
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};
