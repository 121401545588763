// Import libraries
import { find } from 'lodash';
import { message } from 'antd';
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import { useEffect, useState } from 'react';

// Import utilities
import { fetchApiErrorMessage } from '../../utilities/api';

// Load environment variables
const {
    REACT_APP_SUPER_ADMIN_ROLE: superAdminRole,
    REACT_APP_DEALER_ROLE: dealerRole,
} = process.env;

export const useUserFetch = authState => {
    // Initialization
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [timezone, setTimezone] = useState('');
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [isDealer, setIsDealer] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let didCancel = false;

        const fetchUser = async () => {
            setLoading(true);
            try {
                const user = await Auth.currentAuthenticatedUser();
                if (user) {
                    // Fetch user details
                    const userDetails = await API.get('users', '/me');
                    if (userDetails && !didCancel) {
                        setFirstName(userDetails.firstName);
                        setLastName(userDetails.lastName);
                        setEmail(userDetails.email);
                        setTimezone(userDetails.timezone);
                    }

                    // Check if user is a super admin
                    const session = await Auth.currentSession();
                    const userGroups = session.idToken.payload['cognito:groups'];
                    if (userGroups && userGroups[0] === superAdminRole) {
                        setIsSuperAdmin(true);
                    } else if (userGroups && userGroups[0] === dealerRole) {
                        setIsDealer(true);
                    }
                }
            } catch (error) {
                message.error(fetchApiErrorMessage(error));
                Auth.signOut();
            }
            setLoading(false);
        };

        if (authState === 'signedIn') fetchUser();

        return () => { didCancel = true; };
    }, [authState]);

    return {
        firstName, setFirstName, lastName, setLastName,
        email, setEmail, timezone, setTimezone,
        isSuperAdmin, isDealer, loading,
    };
};

export const useOrgsFetch = authState => {
    // Initialization
    const [orgs, setOrgs] = useState([]);
    const [userRole, setUserRole] = useState('');
    const [selectedOrg, setSelectedOrg] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let didCancel = false;

        const fetchOrgs = async () => {
            setLoading(true);
            try {
                const organisations = await API.get('users', '/me/organisations');
                // if (!organisations || organisations.length === 0) {
                //     throw new Error('Unable to find user organisations');
                // }
                if (organisations && !didCancel) setOrgs(organisations);

                // Set default organisation
                if (organisations.length > 0) {
                    const { id } = find(organisations, { isDefault: true }) || organisations[0];
                    if (!didCancel) setSelectedOrg(id);
                }
            } catch (error) {
                message.error(fetchApiErrorMessage(error));
                Auth.signOut();
            }
            setLoading(false);
        };

        if (authState === 'signedIn') fetchOrgs();

        return () => { didCancel = true; };
    }, [authState]);

    return {
        orgs, setOrgs, userRole, setUserRole,
        selectedOrg, setSelectedOrg, loading,
    };
};

export const setDefaultOrg = async orgId => {
    if (orgId) {
        try {
            const body = { 'organisationId': orgId };
            await API.put('users', '/me/organisations/default', { body });
            message.success('Default organisation updated');
            return orgId;
        } catch (error) {
            message.error(fetchApiErrorMessage(error));
        }
    } else {
        message.error('Missing organisation id');
    }
    return null;
};
