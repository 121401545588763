// Import libraries
import { css } from '@emotion/core';

// Import presets
import { content } from '../../styles/presets';

// Prepare stylesheet
const styles = {
    contentWrapper: css({ ...content }),
    controlPanel: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '30px',
    },
    queryPanel: css({
        display: 'flex',
        alignItems: 'center',
    }),
    queryLabel: css({ marginRight: '10px' }),
    queryInput: css({
        width: '200px',
        marginRight: '30px',
    }),
    channels: css({
        fontSize: '8px',
        td: {
            border: '1px solid #d3d3d3',
            textAlign: 'center',
            color: 'white',
        },
        width: '100%',
    }),
    vloss: css({
        fontSize: '8px',
        width: 'auto!important',
    }),
    chErr: {
        textAlign: 'center',
        backgroundColor: '#cc6666',
        minWidth: '20px',
        padding: '2px!important',
        border: '1px solid #f0f0f0',
        color: 'white',
        width: '11%',
        borderRadius: '5px',
    }
};

export default styles;
