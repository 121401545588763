// Import utilities
import { breadcrumbRender } from '../../utilities/breadcrumb';

const getBreadcrumb = (dealerId, currentAction) => {
    const routes = [
        {
            path: '/management/dealers',
            breadcrumbName: 'Dealers',
        },
    ];

    if (dealerId) {
        routes.push({
            path: `/management/dealers/${ dealerId }`,
            breadcrumbName: 'Dealer Details',
        });
    }

    if (currentAction) {
        routes.push({ breadcrumbName: currentAction });
    }

    return { routes, itemRender: breadcrumbRender };
};

export default getBreadcrumb;
