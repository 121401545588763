/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { useContext } from 'react';
import { Link } from 'react-router-dom';

// Import Ant Design component
import { Result, Button, Spin } from 'antd';

// Import store
import { UserContext } from '../../store/UserContext';
import { OrgsContext } from '../../store/OrgsContext';

// Import constants
import { ORG_ROLES } from '../../constants/orgRoles';

const OperatorContainer = (props) => {
    // Retrieve user and organisation context
    const userContext = useContext(UserContext);
    const orgsContext = useContext(OrgsContext);
    const { userRole, selectedOrg } = orgsContext;
    const { isDealer, isSuperAdmin } = userContext;

    if (
        userRole === ORG_ROLES.COUNCIL.name ||
        userRole === ORG_ROLES.ARCHIVIST.name ||
        userRole === ORG_ROLES.ADMIN.name ||
        userRole === ORG_ROLES.MANAGER.name ||
        userRole === ORG_ROLES.VIEWER.name ||
        isDealer ||
        isSuperAdmin
    ) {
        return props.children;
    } else if (!userRole && !selectedOrg) {
        return <Spin tip="Loading data ..." css={{ marginTop: '100px', width: '100vw' }} />;
    }
    return (
        <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
                <Link to="/fleetmap/real-time">
                    <Button type="primary">Back Home</Button>
                </Link>
            }
        />
    );
};

export default OperatorContainer;
