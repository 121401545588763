/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import Ant Design components
import { Tag } from 'antd';

const OrgPlan = ({ plan }) => {
    if (plan === 'standard') return (<Tag color="purple">Standard Plan</Tag>);
    else if (plan === 'premium') return (<Tag color="geekblue">Premium Plan</Tag>);
    else if (!plan) return 'No plan';
    return 'Unknown plan';
};

export default OrgPlan;
