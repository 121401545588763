/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { find } from 'lodash';
import API from '@aws-amplify/api';
import { useState, useEffect, useContext } from 'react';

// Import Ant Design component
import {
    Card,
    Form,
    Button,
    Input,
    PageHeader,
    Space,
    Spin,
    Switch,
    Typography,
    message,
} from 'antd';

// Import store
import { OrgsContext } from '../../store/OrgsContext';

// Import utilities
import { fetchApiErrorMessage } from '../../utilities/api';

// Import stylesheet
import styles from './styles';

// Import additional Ant Design components
const { Text } = Typography;

const AdminEvents = () => {
    // Initialisation
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [aliasAvailable, setAliasAvailable] = useState(false);
    const [eventAliases, setEventAliases] = useState({});
    const [updatingGSensorEvents, setUpdatingGSensorEvents] = useState(false);
    const [updatingTriggerEvents, setUpdatingTriggerEvents] = useState(false);

    // Retrieve currently selected organisation
    const orgsContext = useContext(OrgsContext);
    const { orgs, setOrgs, selectedOrg } = orgsContext;

    // Find the organisation event settings
    const currentOrg = find(orgs, { id: selectedOrg });
    const { gsensorEventsEnabled, triggerEventsEnabled } = currentOrg || {};

    useEffect(() => {
        const getEventAliases = async () => {
            try {
                setLoading(true);
                const data = await API.get('organisations', `/${selectedOrg}/events/aliases`);

                setAliasAvailable(Object.keys(data).length !== 0);

                const aliases = {};
                for (let idx = 1; idx <= 8; idx += 1) {
                    aliases[`118-${idx}`] =
                        `118-${idx}` in data ? data[`118-${idx}`] : `Trigger ${idx}`;
                }
                setEventAliases(aliases);
                setLoading(false);
            } catch (error) {
                message.error(fetchApiErrorMessage(error));
            }
        };

        if (selectedOrg) getEventAliases();
    }, [selectedOrg]);

    // Handle enabling or disabling the events
    const onSettingUpdated = async (settingName, value) => {
        if (settingName === 'gsensorEventsEnabled') setUpdatingGSensorEvents(true);
        else if (settingName === 'triggerEventsEnabled') setUpdatingTriggerEvents(true);

        try {
            await API.put('organisations', `/${selectedOrg}`, { body: { [settingName]: value } });

            // Update organisation settings locally
            setOrgs((orgs) =>
                orgs.map((org) => ({
                    ...org,
                    [settingName]: org.id === selectedOrg ? value : org[settingName],
                }))
            );
        } catch (error) {
            message.error(
                'Unable to update the event settings at the moment, please try again later'
            );
        }

        if (settingName === 'gsensorEventsEnabled') setUpdatingGSensorEvents(false);
        else if (settingName === 'triggerEventsEnabled') setUpdatingTriggerEvents(false);
    };

    // Handle form data updates
    const onFinish = async (aliases) => {
        setSaving(true);
        if (aliasAvailable) {
            try {
                await API.put('organisations', `/${selectedOrg}/events/aliases`, {
                    body: { aliases },
                });
                message.success('Saved');
            } catch (error) {
                message.error(fetchApiErrorMessage(error));
            }
        } else {
            try {
                await API.post('organisations', `/${selectedOrg}/events/aliases`, {
                    body: { aliases },
                });
                setAliasAvailable(true);
                message.success('Saved');
            } catch (error) {
                message.error(fetchApiErrorMessage(error));
            }
        }
        setSaving(false);
    };

    return (
        <div>
            <PageHeader title="Vehicle Events" ghost={false} />

            <div style={styles.contentWrapper}>
                <Card>
                    {loading ? (
                        <Spin tip="Loading event aliases" />
                    ) : (
                        <div>
                            <Space direction="vertical" size="large">
                                <Space>
                                    <Text>Enable g-sensor events</Text>
                                    <Switch
                                        checked={gsensorEventsEnabled}
                                        onChange={(checked) =>
                                            onSettingUpdated('gsensorEventsEnabled', checked)
                                        }
                                        disabled={updatingTriggerEvents}
                                        loading={updatingGSensorEvents}
                                    />
                                </Space>
                                <Space>
                                    <Text>Enable button trigger events</Text>
                                    <Switch
                                        checked={triggerEventsEnabled}
                                        onChange={(checked) =>
                                            onSettingUpdated('triggerEventsEnabled', checked)
                                        }
                                        disabled={updatingGSensorEvents}
                                        loading={updatingTriggerEvents}
                                    />
                                </Space>
                            </Space>

                            {triggerEventsEnabled && (
                                <Form
                                    form={form}
                                    onFinish={onFinish}
                                    initialValues={eventAliases}
                                    style={styles.eventAliasesForm}
                                >
                                    {Object.keys(eventAliases).map((eventId, idx) => (
                                        <Form.Item
                                            key={eventId}
                                            name={eventId}
                                            label={`Event ID ${idx + 1}`}
                                        >
                                            <Input
                                                placeholder="Enter event name or leave empty to hide the event"
                                                style={styles.aliasInput}
                                            />
                                        </Form.Item>
                                    ))}

                                    <Form.Item>
                                        <Space>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                loading={saving}
                                            >
                                                Save
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Form>
                            )}
                        </div>
                    )}
                </Card>
            </div>
        </div>
    );
};

export default AdminEvents;
