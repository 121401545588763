/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { find, findIndex, flatten, countBy, get } from 'lodash';
import API from '@aws-amplify/api';
import moment from 'moment-timezone';
import { StaticMap } from 'react-map-gl';
import { useState, useContext, useEffect, Fragment, useMemo } from 'react';
import DeckGL, { IconLayer, MapView, PathLayer, ScatterplotLayer } from 'deck.gl';
import randomColor from 'randomcolor';
import hexRgb from 'hex-rgb';

// Import Ant Design component
import { Button, Card, Spin, message } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';

// Import stores
import { UserContext } from '../../../store/UserContext';
import { OrgsContext } from '../../../store/OrgsContext';

// Import MapBox configurations
import { INITIAL_VIEWPORT, MAPBOX_STYLES } from '../configs';

// Import utilities
import { useAmplifyOrgGetAPI } from '../../../utilities/hooks';

// Import constants
import {
    DEFAULT_EVENT_BUTTON_TRIGGER_MAPPINGS,
    DEFAULT_EVENT_G_SENSOR_MAPPINGS,
} from '../../../constants/events';

// Import components
import ControlPanel from './components/ControlPanel';
import VelocityPanel from './components/VelocityPanel';
import MediaPanel from './components/MediaPanel';
import EventPanel from './components/EventPanel';

// Define vehicle colour presets
const VEHICLE_COLOURS = [
    '#2980B9', // Blue
    '#8E44AD', // Purple
    '#F39C12', // Brighter Orange
    '#E91E63', // Pink
    '#F1C40F', // Yellow
    '#795548', // Brown
    '#FF7043', // Lighter Red-Orange
    '#C2185B', // Darker Magenta
];

const HistoryMap = () => {
    // Drawer visibility
    const [drawerVisible, setDrawerVisible] = useState(true);

    // Data layer
    const [vehicles, setVehicles] = useState(null);
    const [selectedVehicleRegos, setSelectedVehicleRegos] = useState([]);
    const [vehicleRoutes, setVehicleRoutes] = useState({});
    const [vehicleEvents, setVehicleEvents] = useState({});
    const [vehicleRouteLines, setVehicleRouteLines] = useState([]);
    const [vehicleRoutePoints, setVehicleRoutePoints] = useState([]);
    const [vehicleEventPoints, setVehicleEventPoints] = useState([]);
    const [focusVehicleRego, setFocusVehicleRego] = useState('');
    const [highlightPt, setHighlightPt] = useState(null);

    // Map state data
    const [viewState, setViewState] = useState(INITIAL_VIEWPORT);

    // Hovered object and positions
    const [hoveredObject, setHoveredObject] = useState({});
    const [pointerX, setPointerX] = useState(0);
    const [pointerY, setPointerY] = useState(0);

    // Center marker and circle for location search
    const [centerMarker, setCenterMarker] = useState(null);
    const [centerCircleRadius, setCenterCircleRadius] = useState(200);

    // History data point
    const [historyModalVisible, setHistoryModalVisible] = useState(false);
    const [historyFocusPoint, setHistoryFocusPoint] = useState(null);

    // Event data point
    // const [eventModalVisible, setEventModalVisible] = useState(false);
    // const [eventFocusPoint, setEventFocusPoint] = useState({});

    // Events to be displayed
    const [selectedDisplayEvents, setSelectedDisplayEvents] = useState([]);

    // Retrieve user timezone
    const userContext = useContext(UserContext);
    const { timezone } = userContext;

    // Retrieve currently selected organisation
    const orgContext = useContext(OrgsContext);
    const { orgs, selectedOrg } = orgContext;

    // Find the organisation event settings
    const currentOrg = find(orgs, { id: selectedOrg });
    const { gsensorEventsEnabled, triggerEventsEnabled } = currentOrg || {};

    // Save search type in this additional place, for controling
    // number of points to be displayed on velocity pancel
    const [searchType, setSearchType] = useState();

    // Fetch organisation settings
    const { data: settings, loading: loadingSettings } = useAmplifyOrgGetAPI(
        selectedOrg,
        '/settings',
        {}
    );

    // Fetch vehicle list
    const { data: orgVehicles } = useAmplifyOrgGetAPI(selectedOrg, '/vehicles', []);

    // Fetch event aliases
    const { data: aliases = {} } = useAmplifyOrgGetAPI(selectedOrg, '/events/aliases', []);

    // Prepare event view options based on the organisation settings
    const eventViewOptions = useMemo(() => {
        // Count events by event id
        const eventIds = flatten(Object.values(vehicleEvents)).map((event) => event.eventId);
        const eventIdCounts = countBy(eventIds);

        return [
            ...(triggerEventsEnabled
                ? Object.entries(DEFAULT_EVENT_BUTTON_TRIGGER_MAPPINGS).map(([key, value]) => ({
                      key,
                      ...value,
                  }))
                : []),
            ...(gsensorEventsEnabled
                ? Object.entries(DEFAULT_EVENT_G_SENSOR_MAPPINGS).map(([key, value]) => ({
                      key,
                      ...value,
                  }))
                : []),
        ]
            .map(({ key }) => ({ label: aliases[key], value: key }))
            .filter(({ label }) => label)
            .map((event) => ({
                ...event,
                label: !eventIdCounts[event.value]
                    ? event.label
                    : `${event.label}: ${eventIdCounts[event.value]}`,
            }));
    }, [triggerEventsEnabled, gsensorEventsEnabled, aliases, vehicleEvents]);

    useEffect(() => {
        if (settings && settings.geolocation) {
            setViewState((v) => ({
                ...v,
                latitude: settings.geolocation.latitude,
                longitude: settings.geolocation.longitude,
            }));
        }

        return () => cleanUp();
    }, [settings]);

    const cleanUp = () => {
        setVehicles(null);
        setVehicleRoutes({});
        setVehicleEvents({});
        setVehicleRouteLines([]);
        setVehicleRoutePoints([]);
        setVehicleEventPoints([]);
        setFocusVehicleRego('');
        setSelectedVehicleRegos([]);
        setHighlightPt(null);
        setHoveredObject({});
        setPointerX(0);
        setPointerY(0);
        setCenterMarker(null);
        setCenterCircleRadius(200);
        setSearchType('');
    };

    const handleHistorySearch = async (
        searchDate,
        searchType,
        searchGeoLoc,
        searchRange,
        searchVehicles
    ) => {
        // Clean up data
        cleanUp();

        // Prepare query parameters
        const queryParams = {
            by: searchType,
            date: searchDate.unix(),
            timezone,
            selectedOrg,
        };
        if (searchType === 'location') {
            queryParams.range = searchRange;
            queryParams.lat = searchGeoLoc[0];
            queryParams.lng = searchGeoLoc[1];
        } else if (searchType === 'rego') {
            queryParams.regos = searchVehicles.join(','); // Convert to string from array
        }

        // Send history data fetch call
        const res = await API.get('history', '', { queryStringParameters: queryParams });
        const { vehicles = [], vehicleRoutes = {}, vehicleEvents: events = {} } = res || {};

        // Update the time information with user's timezone settings
        const vehicleHistoryPositions = Object.keys(vehicleRoutes).reduce((prev, current) => {
            return {
                ...prev,
                [current]: vehicleRoutes[current].map((point) => ({
                    ...point,
                    time: moment.tz(point.unixTime, timezone).format('HH:mm:ss'),
                })),
            };
        }, {});

        // Update data
        setVehicleRoutes(vehicleHistoryPositions);
        setVehicleEvents(events);
        setSearchType(searchType);
        setVehicles(
            vehicles.map((vehicle, idx) => {
                const colorHex = VEHICLE_COLOURS[idx % VEHICLE_COLOURS.length];
                const { red, green, blue } = hexRgb(colorHex);
                return { ...vehicle, colorHex, colorRGB: [red, green, blue] };
            })
        );

        if (vehicles.length === 0) message.info('No data found');
        else if (searchType === 'location') {
            setCenterMarker([searchGeoLoc[1], searchGeoLoc[0]]);
            setCenterCircleRadius(searchRange);
            setViewState({
                ...viewState,
                latitude: searchGeoLoc[0],
                longitude: searchGeoLoc[1],
            });
        } else if (searchType === 'rego') {
            setSelectedVehicleRegos([vehicles[0].rego]);
        }
    };

    const selectedVehicles = useMemo(
        () => selectedVehicleRegos.map((rego) => find(vehicles, { rego })),
        [vehicles, selectedVehicleRegos]
    );

    useEffect(() => {
        // Initialisation
        const routeLines = [];
        const routePoints = [];
        let routeEvents = [];

        if (selectedVehicles.length > 0) {
            // Update center to the latest selected vehicle
            const { lat, lon } = selectedVehicles?.[selectedVehicles.length - 1] ?? {};
            if (lat && lon) {
                setViewState({
                    ...viewState,
                    latitude: lat,
                    longitude: lon,
                });
            }

            selectedVehicles.forEach((vehicle) => {
                // Prepare route points and lines
                const points = vehicleRoutes[vehicle.rego] ?? [];
                let lat = 0;
                let lon = 0;
                const path = [];
                points.forEach((point) => {
                    // Filter out points with same location
                    const newLat = point.position[0];
                    const newLon = point.position[1];
                    if (newLat !== lat || newLon !== lon) {
                        path.push([newLon, newLat]);
                        routePoints.push({
                            ...point,
                            position: [newLon, newLat],
                            color: vehicle.colorRGB,
                            rego: vehicle.rego,
                        });
                        lat = newLat;
                        lon = newLon;
                    }
                });
                routeLines.push(path);

                // Prepare event points
                if (vehicleEvents[vehicle.vehicleId]) {
                    routeEvents = routeEvents.concat(
                        (vehicleEvents[vehicle.vehicleId] ?? []).map((event) => ({
                            ...event,
                            rego: vehicle.rego,
                        }))
                    );
                }
            });
        }

        setVehicleRouteLines(routeLines);
        setVehicleRoutePoints(routePoints);
        setVehicleEventPoints(routeEvents);
    }, [searchType, selectedVehicles, vehicleRoutes]);

    const hexToRgb = (hex) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
            : null;
    };

    const renderTooltip = () => {
        if (!hoveredObject || !hoveredObject.object) return null;

        // Extract values
        const {
            // data: { speed, intensity } = {},
            eventId,
            eventTime,
            rego,
            time,
        } = hoveredObject.object;

        // Prepare event tooltip
        // let eventTooltip = hoveredObject.object.event;
        // if (['119', '120', '121', '122'].indexOf(eventId) >= 0)
        //     eventTooltip += ` (Speed: ${speed} km/h, Intensity: ${intensity})`;
        // eventTooltip += ` @ ${hoveredObject.object.time}`;

        return (
            <Card
                size="small"
                bordered={false}
                css={{
                    position: 'absolute',
                    zIndex: 1,
                    pointerEvents: 'none',
                    backgroundColor: 'black',
                    color: 'white',
                    left: pointerX,
                    top: pointerY,
                }}
            >
                {eventId
                    ? `${rego} - ${moment.tz(eventTime, timezone).format('HH:mm:ss')}`
                    : rego
                    ? `${rego} - ${time}`
                    : time}
                {/* {hoveredObject.object.event
                    ? eventTooltip
                    : hoveredObject.object.rego || hoveredObject.object.time} */}
            </Card>
        );
    };

    const focusLastPoint = (currentTime, targetTime) => {
        const idx = findIndex(vehicleRoutes[focusVehicleRego], {
            unixTime: targetTime ?? currentTime,
        });
        if (idx > 0) {
            setHistoryFocusPoint(vehicleRoutes[focusVehicleRego][targetTime ? idx : idx - 1]);
            return true;
        }
        return false;
    };

    const focusNextPoint = (currentTime, targetTime) => {
        const idx = findIndex(vehicleRoutes[focusVehicleRego], {
            unixTime: targetTime ?? currentTime,
        });
        if (idx > 0 && idx < vehicleRoutes[focusVehicleRego].length - 1) {
            setHistoryFocusPoint(vehicleRoutes[focusVehicleRego][targetTime ? idx : idx + 1]);
            return true;
        }
        return false;
    };

    const layers = [
        // Vehicle Icon Layer
        new IconLayer({
            id: 'trucks',
            data: vehicles,
            pickable: true,
            iconAtlas: '/imgs/red-circle-transparent.png',
            iconMapping: {
                truck: {
                    x: 0,
                    y: 0,
                    width: 980,
                    height: 980,
                },
                truckWithMask: {
                    x: 0,
                    y: 0,
                    width: 980,
                    height: 980,
                    mask: true,
                },
            },
            sizeScale: 20,
            getPosition: (d) => [d.lon, d.lat],
            getIcon: (d) => (d.groupColor ? 'truckWithMask' : 'truck'),
            getColor: (d) => {
                const rgb = d.groupColor ? hexToRgb(d.groupColor) : [0, 0, 0];
                if (focusVehicleRego !== '' && d.rego !== focusVehicleRego) {
                    return [...rgb, 50];
                }
                return [...rgb, 255];
            },
            onHover: (info) => {
                setHoveredObject(info);
                setPointerX(info.x);
                setPointerY(info.y);
            },
        }),
        // Route Line Layer
        new PathLayer({
            id: 'routes',
            data: vehicleRouteLines.map((path) => ({ path })),
            pickable: true,
            getPath: (d) => d.path,
            getColor: () => [233, 136, 176],
            getWidth: () => 3,
        }),
        // Route Point Layer
        new ScatterplotLayer({
            id: 'routePoints',
            data: vehicleRoutePoints,
            pickable: true,
            stroked: true,
            filled: true,
            radiusScale: 1,
            radiusMinPixels: 4, // Minimum size in pixels
            radiusMaxPixels: 7, // Maximum size in pixels
            getPosition: (d) => d.position,
            getRadius: () => 7,
            getFillColor: (d) => d.color,
            getLineColor: [255, 255, 255],
            onHover: (info) => {
                setHoveredObject(info);
                setPointerX(info.x);
                setPointerY(info.y);
            },
            onClick: (info) => {
                const { object: pointInfo } = info || {};
                const { position } = pointInfo || {};
                setHistoryModalVisible(true);
                setHistoryFocusPoint({
                    ...pointInfo,
                    position: [position[1], position[0]],
                });
                setFocusVehicleRego(pointInfo.rego);
            },
            lineWidthMaxPixels: 1,
        }),
        // Event Point Layer
        new IconLayer({
            id: 'eventPoints',
            data: vehicleEventPoints.filter(
                (event) => selectedDisplayEvents.indexOf(event.eventId) >= 0
            ),
            pickable: true,
            iconAtlas: '/imgs/green-circle.png',
            iconMapping: {
                point: {
                    x: 0,
                    y: 0,
                    width: 980,
                    height: 980,
                },
            },
            sizeScale: 10,
            getPosition: (d) => [d.eventLocation.lon, d.eventLocation.lat],
            getIcon: () => 'point',
            onHover: (info) => {
                setHoveredObject(info);
                setPointerX(info.x);
                setPointerY(info.y);
            },
            onClick: (info) => {
                const { object: pointInfo } = info || {};
                const { eventLocation } = pointInfo || {};
                setHistoryModalVisible(true);
                setHistoryFocusPoint({
                    ...pointInfo,
                    speed: pointInfo.speed,
                    unixTime: pointInfo.eventTime,
                    position: [eventLocation.lat, eventLocation.lon],
                });
                setFocusVehicleRego(pointInfo.rego);
                // const { object: pointInfo } = info || {};
                // const eventData = get(pointInfo, 'data.file', []);
                // if (eventData.length === 0) {
                //     message.info('No event images found');
                // } else {
                //     setEventModalVisible(true);
                //     setEventFocusPoint(pointInfo);
                // }
            },
        }),
        // Highlight Point Icon Layer
        new IconLayer({
            id: 'highlight',
            data: [{ coordinates: highlightPt }],
            pickable: true,
            iconAtlas: '/imgs/orange-circle.png',
            iconMapping: {
                highlight: {
                    x: 0,
                    y: 0,
                    width: 980,
                    height: 980,
                },
            },
            sizeScale: 10,
            getPosition: (d) => d.coordinates,
            getIcon: () => 'highlight',
        }),
        // Center Marker Layer
        centerMarker &&
            new IconLayer({
                id: 'icon-layer',
                data: [{ coordinates: centerMarker }],
                iconAtlas: '/imgs/location-square.png',
                iconMapping: {
                    marker: {
                        x: 0,
                        y: 0,
                        width: 256,
                        height: 256,
                        mask: false,
                    },
                },
                pickable: true,
                sizeScale: 10,
                getIcon: () => 'marker',
                getPosition: (d) => d.coordinates,
                getSize: () => 5,
            }),
        // Center Circle Layer
        centerMarker &&
            centerCircleRadius &&
            new ScatterplotLayer({
                id: 'scatterplot-layer',
                data: [{ coordinates: centerMarker }],
                opacity: 0.1,
                filled: true,
                getPosition: (d) => d.coordinates,
                getRadius: () => centerCircleRadius,
                getFillColor: () => [255, 140, 0],
            }),
    ];

    return loadingSettings ? (
        <Spin
            tip="Fetching organisation settings ..."
            css={{ marginTop: '100px', width: '100vw' }}
        />
    ) : (
        <Fragment>
            <DeckGL
                viewState={viewState}
                onViewStateChange={({ viewState }) => setViewState(viewState)}
                controller={focusVehicleRego === ''}
                layers={layers}
                width={`calc(100vw - ${drawerVisible ? '350px' : '0px'})`}
                height="calc(100vh - 64px)"
            >
                <MapView id="map" controller>
                    <StaticMap
                        key="map"
                        mapboxApiAccessToken={process.env.MAPBOX_ACCESS_TOKEN}
                        mapStyle={MAPBOX_STYLES.newCustom}
                    />
                </MapView>

                {!drawerVisible && (
                    <Button
                        type="primary"
                        icon={<FileSearchOutlined />}
                        onClick={() => setDrawerVisible(true)}
                        css={{
                            position: 'absolute',
                            top: '25px',
                            right: '25px',
                        }}
                    >
                        History Panel
                    </Button>
                )}

                {/* Tooltips shown on mouse hover events */}
                {hoveredObject && hoveredObject.picked && renderTooltip()}
            </DeckGL>

            {/* Vehicle velocity panel */}
            {selectedVehicles.length === 1 && (
                <VelocityPanel
                    routePoints={vehicleRoutes[selectedVehicles[0].rego]}
                    setHighlightPt={setHighlightPt}
                    width={drawerVisible ? 'calc(100vw - 350px)' : '100vw'}
                    searchType={searchType}
                />
            )}

            <ControlPanel
                vehicles={vehicles}
                cleanUp={cleanUp}
                selectedVehicleRegos={selectedVehicleRegos}
                setSelectedVehicleRegos={setSelectedVehicleRegos}
                drawerVisible={drawerVisible}
                handleDrawerClose={() => setDrawerVisible(false)}
                handleHistorySearch={handleHistorySearch}
                handleSearchModeChange={cleanUp}
                orgVehicles={orgVehicles.map((vehicle) => ({ value: vehicle.rego }))}
                eventViewOptions={eventViewOptions}
                onViewSettingsChanged={(checkedValues) => setSelectedDisplayEvents(checkedValues)}
            />

            {historyModalVisible && (
                <MediaPanel
                    data={historyFocusPoint}
                    vehicleOnFocus={find(vehicles, { rego: focusVehicleRego })}
                    focusLastPoint={focusLastPoint}
                    focusNextPoint={focusNextPoint}
                    onCancel={() => {
                        setHistoryModalVisible(false);
                        setHistoryFocusPoint(null);
                    }}
                />
            )}

            {/* <EventPanel
                visible={eventModalVisible}
                eventName={get(
                    eventFocusPoint,
                    'event',
                    `Trigger ${
                        eventFocusPoint.eid === 99
                            ? 'Panic'
                            : parseInt(eventFocusPoint.eid, 10) - 20
                    }`
                )}
                data={get(eventFocusPoint, 'data.file', [])}
                unixTime={get(eventFocusPoint, 'unixTime')}
                timezone={timezone}
                onCancel={() => {
                    setEventModalVisible(false);
                    setEventFocusPoint({});
                }}
            /> */}
        </Fragment>
    );
};

export default HistoryMap;
