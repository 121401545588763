/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import API from '@aws-amplify/api';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';

// Import Ant Design component
import { Table, Button, Card, PageHeader, message } from 'antd';

// Import store
import { UserContext } from '../../store/UserContext';

// Import components
import OrgPlan from '../../components/OrgPlan';

// Import stylesheet
import styles from './styles';

const SuperOrgs = () => {
    const [loadingOrgs, setLoadingOrgs] = useState(false);
    const [orgs, setOrgs] = useState([]);

    // Check if current user is an superadmin or dealer
    const userContext = useContext(UserContext);
    const { isSuperAdmin, isDealer } = userContext;

    useEffect(() => {
        // Retrieve all organisations as a super admin user or
        // managed organisation as a dealer
        const fetchOrgsData = async () => {
            setLoadingOrgs(true);
            try {
                const data = await API.get('organisations', '/');
                setOrgs(data);
            } catch (error) {
                message.error('Unable to fetch organisations');
            }
            setLoadingOrgs(false);
        };

        fetchOrgsData();
    }, [isSuperAdmin, isDealer]);

    // Configure table columns
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (name, org) => (
                <Link to={`/management/organisations/${ org.id }`}>
                    {name}
                </Link>
            )
        },
        {
            title: 'Short Name',
            dataIndex: 'shortName',
            render: value => value || 'N/A',
        },
        {
            title: 'Contact Name',
            dataIndex: 'contactName',
            render: value => value || 'N/A',
        },
        {
            title: 'Current Plan',
            dataIndex: 'plan',
            render: value => (<OrgPlan plan={value} />),
        },
        {
            title: 'Plan Started At',
            dataIndex: 'planStartedAt',
            render: value => value ? moment(value).format('MMMM Do YYYY') : 'Not started',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: value => value ? moment(value).format('MMMM Do YYYY') : 'N/A',
        },
    ];

    return (
        <div>
            <PageHeader
                title="All Organisations"
                subTitle={`${ orgs.length } organisations found`}
                ghost={false}
            />

            <div css={styles.contentWrapper}>
                <Card>
                    <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Link to="/management/organisations/create">
                            <Button type="primary" css={{ marginBottom: '20px' }}>
                                Add Organisation
                            </Button>
                        </Link>
                    </div>

                    <Table
                        columns={columns}
                        loading={loadingOrgs}
                        rowKey="id"
                        dataSource={orgs}
                    />
                </Card>
            </div>
        </div>
    );
};

export default SuperOrgs;
