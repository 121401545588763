// Import libraries
import { message } from 'antd';
import API from '@aws-amplify/api';

// Import utilities
import { fetchApiErrorMessage } from '../../utilities/api';

export const deleteUser = async (selectedOrg, userId) => {
    try {
        await API.del('organisations', `/${selectedOrg}/users/${userId}`);
        message.success('User removed');
    } catch (error) {
        message.error(fetchApiErrorMessage(error));
    }
};

export const updateUser = async (selectedOrg, user) => {
    if (!user || !user.id || !user.role) {
        message.error('Missing user role or user id');
        return;
    }

    try {
        await API.put('organisations', `/${selectedOrg}/users/${user.id}`, {
            body: { role: user.role, timezone: user.timezone },
        });
        message.success('User updated');
    } catch (error) {
        message.error(fetchApiErrorMessage(error));
    }
};

export const addUser = async (selectedOrg, user) => {
    if (!user || !user.email || !user.role) {
        message.error('Missing user email or role');
        return;
    }

    try {
        await API.post('organisations', `/${selectedOrg}/users`, {
            body: {
                email: user.email,
                role: user.role,
                firstName: user.firstName,
                lastName: user.lastName,
                timezone: user.timezone,
            },
        });
        message.success('User added');
    } catch (error) {
        message.error(fetchApiErrorMessage(error));
    }
};
