/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import Particles from 'react-particles-js';

// Configure stylesheet
const styles = {
    position: 'absolute',
    top: '0',
    background: 'linear-gradient(135deg, rgb(96, 108, 136) 0%, rgb(63, 76, 107) 100%)',
};

const ReactParticles = props => (
    props.authState !== 'signedIn' && <Particles
        params={{
            particles: {
                number: {
                    value: 50,
                },
                size: {
                    value: 3,
                },
            },
            interactivity: {
                events: {
                    onhover: {
                        enable: true,
                        mode: 'repulse',
                    },
                },
            },
        }}
        style={styles}
    />
);

export default ReactParticles;

