/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { Link } from 'react-router-dom';

// Import Ant Design components
import { Menu } from 'antd';
import {
    AlertOutlined,
    OrderedListOutlined,
    UserOutlined,
    CarOutlined,
    SettingOutlined,
    BuildOutlined,
    BankOutlined,
    DollarOutlined,
    FlagOutlined,
    CommentOutlined,
    ShopOutlined,
    BranchesOutlined,
    CloudDownloadOutlined,
    DeploymentUnitOutlined,
} from '@ant-design/icons';

// Import components
import IconFont from '../../../../components/IconFont';

// Import constants
import { ORG_ROLES } from '../../../../constants/orgRoles';

// Import SubMenu component
const { SubMenu } = Menu;

// Define all routes
const dashboardRoute = (
    <Menu.Item key="dashboard" icon={<IconFont type="iconic_dashboard" />}>
        <Link to="/dashboard">Dashboard</Link>
    </Menu.Item>
);

const nowRoute = (
    <Menu.Item key="fleetmap/real-time" icon={<IconFont type="iconic_now" />}>
        <Link to="/fleetmap/real-time">Now</Link>
    </Menu.Item>
);

const historyRoute = (
    <Menu.Item key="fleetmap/history" icon={<IconFont type="iconic_history" />}>
        <Link to="/fleetmap/history">History</Link>
    </Menu.Item>
);

const deviceRoute = (
    <Menu.Item key="devices" icon={<IconFont type="iconic_devices" />}>
        <Link to="/devices">Devices</Link>
    </Menu.Item>
);

const reportRoute = (
    <SubMenu key="reports" icon={<IconFont type="iconic_reports" />} title="Reports">
        <Menu.Item key="reports/issues" icon={<AlertOutlined />}>
            <Link to="/reports/issues">Issues</Link>
        </Menu.Item>
        <Menu.Item key="reports/events" icon={<FlagOutlined />}>
            <Link to="/reports/events">Events</Link>
        </Menu.Item>
        <Menu.Item key="reports/weekly" icon={<OrderedListOutlined />}>
            <Link to="/reports/weekly">Weekly</Link>
        </Menu.Item>
        <Menu.Item key="downloads" icon={<CloudDownloadOutlined />}>
            <Link to="/downloads">Downloads</Link>
        </Menu.Item>
    </SubMenu>
);

const downloadRoute = (
    <SubMenu key="reports" icon={<IconFont type="iconic_reports" />} title="Reports">
        <Menu.Item key="downloads" icon={<CloudDownloadOutlined />}>
            <Link to="/downloads">Downloads</Link>
        </Menu.Item>
    </SubMenu>
);

const adminUsersRoute = (
    <Menu.Item key="admin/users" icon={<UserOutlined />}>
        <Link to="/admin/users">Users</Link>
    </Menu.Item>
);

const adminVehiclesRoute = (
    <Menu.Item key="admin/vehicles" icon={<CarOutlined />}>
        <Link to="/admin/vehicles">Vehicles</Link>
    </Menu.Item>
);

const adminVehicleDevicesRoute = (
    <Menu.Item key="admin/vehicle-devices" icon={<IconFont type="iconic_devices" />}>
        <Link to="/admin/vehicle-devices">Assign</Link>
    </Menu.Item>
);

const adminMaintenanceRoute = (
    <Menu.Item key="admin/maintenance" icon={<BranchesOutlined />}>
        <Link to="/admin/maintenance">Maintenance</Link>
    </Menu.Item>
);

const adminSettings = (
    <SubMenu key="admin/settings" icon={<SettingOutlined />} title="Settings">
        <Menu.Item key="admin/vehicle-groups" icon={<BuildOutlined />}>
            <Link to="/admin/vehicle-groups">Vehicle Groups</Link>
        </Menu.Item>
        <Menu.Item key="admin/events" icon={<FlagOutlined />}>
            <Link to="/admin/events">Vehicle Events</Link>
        </Menu.Item>
        <Menu.Item key="admin/org-contacts" icon={<CommentOutlined />}>
            <Link to="/admin/org-contacts">SMS Contacts</Link>
        </Menu.Item>
    </SubMenu>
);

const superOrganisationsRoute = (
    <Menu.Item key="management/organisations" icon={<BankOutlined />}>
        <Link to="/management/organisations">Organisations</Link>
    </Menu.Item>
);

const superDealersRoute = (
    <Menu.Item key="management/dealers" icon={<ShopOutlined />}>
        <Link to="/management/dealers">Dealers</Link>
    </Menu.Item>
);

const superDevicesRoute = (
    <Menu.Item key="management/devices" icon={<IconFont type="iconic_devices" />}>
        <Link to="/management/devices">Devices</Link>
    </Menu.Item>
);

const superBillingsRoute = (
    <Menu.Item key="management/billings" icon={<DollarOutlined />}>
        <Link to="/management/billings">Billings</Link>
    </Menu.Item>
);

const superFirmwaresRoute = (
    <Menu.Item key="management/firmwares" icon={<DeploymentUnitOutlined />}>
        <Link to="/management/firmwares">Firmwares</Link>
    </Menu.Item>
);

export default (pathname, userRole, isSuperAdmin, isDealer) => {
    if (pathname.match(/^admin\//g)) {
        if (isSuperAdmin) {
            return [adminVehiclesRoute, adminUsersRoute, adminSettings];
        } else if (isDealer) {
            return [
                adminMaintenanceRoute,
                adminVehiclesRoute,
                adminVehicleDevicesRoute,
                adminUsersRoute,
                adminSettings,
            ];
        }

        return userRole === ORG_ROLES.ADMIN.name
            ? [adminVehiclesRoute, adminUsersRoute, adminSettings]
            : [];
    } else if (pathname.match(/^management\//g)) {
        let menuItems = [];
        if (isDealer) {
            return [superOrganisationsRoute, superBillingsRoute];
        } else if (isSuperAdmin) {
            return [
                superOrganisationsRoute,
                superDealersRoute,
                superDevicesRoute,
                superFirmwaresRoute,
            ];
        }
        return menuItems;
    }

    switch (userRole) {
        case ORG_ROLES.ADMIN.name: {
            return [dashboardRoute, nowRoute, historyRoute, deviceRoute, reportRoute];
        }
        case ORG_ROLES.MANAGER.name: {
            return [dashboardRoute, nowRoute, historyRoute, deviceRoute, reportRoute];
        }
        case ORG_ROLES.OPERATOR.name: {
            return [nowRoute, deviceRoute];
        }
        case ORG_ROLES.COUNCIL.name: {
            return [nowRoute, historyRoute, downloadRoute];
        }
        case ORG_ROLES.VIEWER.name: {
            return [nowRoute, historyRoute];
        }
        case ORG_ROLES.ARCHIVIST.name:
            return [historyRoute];
        case ORG_ROLES.GUEST.name: {
            return [nowRoute];
        }
        default:
            break;
    }

    return [];
};
