/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import API from '@aws-amplify/api';
import moment from 'moment';
import { find, get } from 'lodash';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';

// Import Ant Design component
import { Table, Button, Card, PageHeader, Modal, Descriptions, Input, Space, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';

// Import stylesheet
import styles from './styles';

const SuperFirmwares = () => {
    // Initialisation
    const [loadingFirmwares, setLoadingFirmwares] = useState(false);
    const [firmwares, setFirmwares] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [saving, setSaving] = useState(false);
    const [dataFetchedAt, setDataFetchedAt] = useState(new Date().getTime());

    const onEditCancel = () => {
        setEditMode(false);
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            description: '',
            filePath: '',
        },
        onSubmit: async (values) => {
            const { id, name, description, filePath } = values;
            const body = { name, description, filePath };
            setSaving(true);

            if (id) {
                try {
                    await API.put('superadmin', `/firmwares/${id}`, { body });
                    message.success('Firmware updated');
                } catch (error) {
                    message.error('Unable to update firmware');
                }
            } else {
                try {
                    await API.post('superadmin', '/firmwares', { body });
                    message.success('Firmware created');
                } catch (error) {
                    message.error('Unable to add firmware');
                }
            }

            setSaving(false);
            onEditCancel();
            setDataFetchedAt(new Date().getTime());
        },
    });

    useEffect(() => {
        const fetchFirmwares = async () => {
            setLoadingFirmwares(true);
            try {
                const data = await API.get('superadmin', '/firmwares');
                setFirmwares(data);
            } catch (error) {
                message.error('Unable to fetch firmwares');
            }
            setLoadingFirmwares(false);
        };

        fetchFirmwares();
    }, [dataFetchedAt]);

    // Configure table columns
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => (a.name > b.name ? 1 : -1),
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'File Path',
            dataIndex: 'filePath',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (value) => (value ? moment(value).format('MMMM Do YYYY') : 'N/A'),
            sorter: (a, b) => (moment(a.createdAt).isAfter(moment(b.createdAt)) ? 1 : -1),
            defaultSortOrder: 'descend',
        },
        {
            dataIndex: 'id',
            render: (id) => (
                <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                        setEditMode(true);
                        const matchedFirmware = find(firmwares, { id });
                        if (matchedFirmware) {
                            const { name, description, filePath } = matchedFirmware;
                            formik.setFieldValue('id', id);
                            formik.setFieldValue('name', name);
                            formik.setFieldValue('description', description);
                            formik.setFieldValue('filePath', filePath);
                        }
                    }}
                />
            ),
        },
    ];

    return (
        <div>
            <PageHeader
                title="All Firmwares"
                subTitle={`${firmwares.length} firmwares found`}
                ghost={false}
            />

            <div css={styles.contentWrapper}>
                <Card>
                    <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            type="primary"
                            onClick={() => setEditMode(true)}
                            css={{ marginBottom: '20px' }}
                        >
                            Add Firmware
                        </Button>
                    </div>

                    <Table
                        columns={columns}
                        loading={loadingFirmwares}
                        rowKey="id"
                        dataSource={firmwares}
                    />
                </Card>
            </div>

            {/* Firmware edit modal */}

            <Modal
                title={formik.values.id ? 'Edit Firmware' : 'Add Firmware'}
                open={editMode}
                onCancel={onEditCancel}
                footer={null}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Descriptions column={1} bordered>
                        <Descriptions.Item label="Name">
                            <Input
                                id="name"
                                name="name"
                                placeholder="Enter firmware name"
                                onChange={formik.handleChange}
                                value={get(formik.values, 'name')}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="Description">
                            <Input
                                id="description"
                                name="description"
                                placeholder="Enter firmware description"
                                onChange={formik.handleChange}
                                value={get(formik.values, 'description')}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="File Path">
                            <Input
                                id="filePath"
                                name="filePath"
                                placeholder="Enter firmware file path"
                                onChange={formik.handleChange}
                                value={get(formik.values, 'filePath')}
                            />
                        </Descriptions.Item>
                    </Descriptions>

                    <Space css={{ marginTop: '30px' }}>
                        <Button htmlType="submit" type="primary" loading={saving}>
                            Save
                        </Button>
                        <Button onClick={onEditCancel}>Cancel</Button>
                    </Space>
                </form>
            </Modal>
        </div>
    );
};

export default SuperFirmwares;
