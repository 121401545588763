const routes = [
    {
        path: '/reports/weekly',
        breadcrumbName: 'Weekly Reports',
    },
    {
        breadcrumbName: 'Report Details',
    },
];

export default routes;