/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useContext, useState } from 'react';

// Import Ant Design components
import {
    Card,
    Table,
    Button,
    Alert,
    Input,
    Select,
    Modal,
    Popconfirm,
    Badge,
    PageHeader,
    Tooltip,
} from 'antd';
import { PlusOutlined, BuildOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';

// Import stores
import { OrgsContext } from '../../store/OrgsContext';
import { UserContext } from '../../store/UserContext';

// Import utilities
// import { breadcrumbRender } from '../../utilities/breadcrumb';
import { useAmplifyOrgGetAPI } from '../../utilities/hooks';

// Import actions
import {
    deleteVehicles,
    createVehicle,
    updateVehicle,
    assignVehicles,
    updateVehicleAdminLock,
} from './actions';

// Import forms
import AssignVehicle from './forms/AssignVehicle';
import EditVehicle from './forms/EditVehicle';

// Import breadcrumb routes
// import routes from './routes';

// Import stylesheet
import styles from './styles';

// Import Option component
const { Option } = Select;

const AdminVehicles = () => {
    const [dataFetchedAt, setDataFetchedAt] = useState(new Date().getTime());
    const [searchRego, setSearchRego] = useState('');
    const [searchGroup, setSearchGroup] = useState(undefined);
    const [selectedVehicle, setSelectedVehicle] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);
    const [editLoading, setEditLoading] = useState(false);
    const [assignLoading, setAssignLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [assignModalVisible, setAssignModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [vehicleLockInEdit, setVehiclelockInEdit] = useState();

    // Retrieve currently selected organisation and check if current user is a dealer
    const orgContext = useContext(OrgsContext);
    const userContext = useContext(UserContext);
    const { selectedOrg, orgs } = orgContext;
    const { isDealer } = userContext;

    const { data: vehicles, loading: loadingVehicles } = useAmplifyOrgGetAPI(
        selectedOrg,
        '/vehicles',
        [],
        { t: dataFetchedAt }
    );

    const { data: vehicleGroups, loading: loadingVehicleGroups } = useAmplifyOrgGetAPI(
        selectedOrg,
        '/vehicle-groups',
        []
    );

    const rowSelection = {
        onChange: (_, selectedRows) => setSelectedItems(selectedRows),
        selectedRowKeys: selectedItems.map((item) => item.id),
    };

    // Handle vehicle assignment
    const onVehicleAssign = async (selectedVehicleGroupId, selectedOrgId) => {
        // Assign vehicles to a new vehicle group or org
        setAssignLoading(true);
        await assignVehicles(selectedOrg, selectedItems, selectedVehicleGroupId, selectedOrgId);

        // Refetch vehicle data
        setDataFetchedAt(new Date().getTime());

        // Clean up status
        setSelectedItems([]);
        setAssignLoading(false);
        setAssignModalVisible(false);
    };

    // Handle vehicle assignment cancellation
    const onVehicleAssignCancel = () => {
        setAssignModalVisible(false);
        setSelectedItems([]);
    };

    // Handle vehicle edit
    const onVehicleEdit = async (values) => {
        // Create or update vehicle
        setEditLoading(true);
        if (selectedVehicle.id) {
            const updates = {};
            if (selectedVehicle.rego !== values.rego) updates.rego = values.rego;
            if (selectedVehicle.make !== values.make) updates.make = values.make;
            if (selectedVehicle.model !== values.model) updates.model = values.model;
            if (selectedVehicle.plate !== values.plate) updates.plate = values.plate;
            await updateVehicle(selectedOrg, updates, selectedVehicle.id);
        } else {
            await createVehicle(selectedOrg, values);
        }

        // Refetch vehicle data
        setDataFetchedAt(new Date().getTime());

        // Clean up status
        setSelectedVehicle({});
        setEditLoading(false);
        setEditModalVisible(false);
    };

    // Handle vehicle edit cancellation
    const onVehicleEditCancel = () => {
        setEditModalVisible(false);
        setSelectedVehicle({});
    };

    // Handle vehicle deletion
    const onVehicleDelete = async () => {
        // Delete selected vehicle(s)
        setDeleteLoading(true);
        await deleteVehicles(selectedOrg, selectedItems);

        // Refetch vehicle data
        setDataFetchedAt(new Date().getTime());

        // Clean up status
        setDeleteLoading(false);
        setSelectedItems([]);
    };

    // Handle search criteria clearance
    const onSearchClear = () => {
        setSearchRego('');
        setSearchGroup(undefined);
    };

    // Handle vehicle update lock updated
    const onVehicleAdminLockUpdated = async (vehicleId, locked) => {
        setVehiclelockInEdit(vehicleId);
        await updateVehicleAdminLock(selectedOrg, vehicleId, locked);
        setVehiclelockInEdit(null);

        // Refetch vehicle data
        setDataFetchedAt(new Date().getTime());
    };

    // Configure table columns
    const columns = [
        {
            title: 'Fleet ID',
            dataIndex: 'rego',
            render: (value, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        setSelectedVehicle(record);
                        setEditModalVisible(true);
                    }}
                    css={{ padding: '0px' }}
                >
                    {value}
                </Button>
            ),
            sorter: (a, b) => (a.rego > b.rego ? 1 : -1),
        },
        {
            title: 'Make',
            dataIndex: 'make',
            sorter: (a, b) => (a.make > b.make ? 1 : -1),
        },
        {
            title: 'Body',
            dataIndex: 'model',
            sorter: (a, b) => (a.model > b.model ? 1 : -1),
        },
        {
            title: 'Plate',
            dataIndex: 'plate',
            sorter: (a, b) => (a.plate > b.plate ? 1 : -1),
        },
        {
            title: 'Vehicle Group',
            dataIndex: 'groupName',
            render: (value, record) => (
                <Badge text={value || 'Unassigned'} color={record.groupColor || 'gray'} />
            ),
            sorter: (a, b) => (a.groupName > b.groupName ? 1 : -1),
        },
        {
            title: 'DVR Status',
            dataIndex: 'online',
            render: (value, record) => {
                if (record.deviceId) {
                    let dvrBadge;
                    switch (value) {
                        case 'online':
                            dvrBadge = <Badge status="success" text="Online" />;
                            break;
                        case 'timeout':
                            dvrBadge = <Badge status="default" text="Timeout" />;
                            break;
                        case 'offline':
                            dvrBadge = <Badge status="default" text="Offline" />;
                            break;
                        default:
                            dvrBadge = <Badge status="default" text="Unknown" />;
                    }

                    return (
                        <Link
                            to={`${isDealer ? '/admin/maintenance' : '/devices'}?rego=${
                                record.rego
                            }`}
                        >
                            {dvrBadge}
                        </Link>
                    );
                }
                return <Badge status="default" text="Unassigned" />;
            },
        },
        {
            title: 'Commence on',
            dataIndex: 'billingStartedAt',
            render: (value) => (value ? moment(value).format('MMMM Do YYYY') : 'N/A'),
            sorter: (a, b) =>
                moment(a.billingStartedAt).isAfter(moment(b.billingStartedAt)) ? 1 : -1,
        },
        {
            dataIndex: 'adminLocked',
            render: (adminLocked, vehicle) => (
                <Popconfirm
                    title={`Are you sure ${adminLocked ? 'unlock' : 'lock'} this vehicle?`}
                    onConfirm={() => onVehicleAdminLockUpdated(vehicle.id, !adminLocked)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type={adminLocked ? 'danger' : 'primary'}
                        disabled={vehicleLockInEdit === vehicle.id}
                        icon={adminLocked ? <UnlockOutlined /> : <LockOutlined />}
                    >
                        {adminLocked ? 'Unlock' : 'Lock'}
                    </Button>
                </Popconfirm>
            ),
        },
    ];

    return (
        <div>
            <PageHeader
                title="Vehicle List"
                subTitle={`${vehicles.length} results found`}
                // breadcrumb={{ routes, itemRender: breadcrumbRender }}
                ghost={false}
            />

            <div css={styles.contentWrapper}>
                <Card>
                    <div css={styles.controlPanel}>
                        {/* Action panel */}
                        <div>
                            {isDealer && (
                                <Button
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    onClick={() => setEditModalVisible(true)}
                                >
                                    Add vehicle
                                </Button>
                            )}

                            <Button
                                type="primary"
                                onClick={() => setAssignModalVisible(true)}
                                disabled={selectedItems.length === 0}
                                css={{ marginLeft: '10px' }}
                            >
                                Assign Vehicle
                            </Button>

                            <Link to="/admin/vehicle-groups">
                                <Button icon={<BuildOutlined />} css={{ marginLeft: '10px' }}>
                                    Vehicle Groups
                                </Button>
                            </Link>
                        </div>

                        {/** Query panel */}
                        <div css={styles.queryPanel}>
                            <span css={styles.queryLabel}>Search Rego:</span>
                            <Input
                                placeholder="Enter vehicle rego"
                                value={searchRego}
                                onChange={(e) => setSearchRego(e.target.value)}
                                css={{ ...styles.queryInput, width: '150px' }}
                            />
                            <span css={styles.queryLabel}>Search Group:</span>
                            <Select
                                placeholder="Select a vehicle group"
                                value={searchGroup}
                                onChange={(value) => setSearchGroup(value)}
                                loading={loadingVehicleGroups}
                                css={styles.queryInput}
                            >
                                {vehicleGroups.map((group) => (
                                    <Option key={group.id} value={group.id}>
                                        {group.name}
                                    </Option>
                                ))}
                                <Option key="unassigned" value="">
                                    Unassigned
                                </Option>
                            </Select>
                            <Button onClick={onSearchClear}>Clear</Button>
                        </div>
                    </div>

                    {/** Action panel */}
                    {isDealer && selectedItems.length > 0 ? (
                        <div css={styles.actionPanel}>
                            <Popconfirm
                                title="Are you sure delete this vehicle?"
                                onConfirm={onVehicleDelete}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Tooltip
                                    title={
                                        selectedItems.filter((item) => item.deviceId !== null)
                                            .length > 0
                                            ? 'Please unassign device first'
                                            : ''
                                    }
                                >
                                    <Button
                                        type="danger"
                                        css={{ marginLeft: '15px' }}
                                        loading={deleteLoading}
                                        disabled={
                                            selectedItems.filter((item) => item.deviceId !== null)
                                                .length > 0
                                        }
                                    >
                                        Delete
                                    </Button>
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    ) : null}

                    {/** Data display panel */}
                    {selectedItems.length > 0 ? (
                        <Alert
                            message={
                                <div css={{ display: 'flex' }}>
                                    <div>
                                        Selected <b>{selectedItems.length}</b> items.
                                    </div>
                                    <div
                                        id="clear"
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => setSelectedItems([])}
                                        onKeyPress={() => {}}
                                        css={styles.clearButton}
                                    >
                                        Clear
                                    </div>
                                </div>
                            }
                            type="info"
                            showIcon
                            css={{ marginTop: '30px' }}
                        />
                    ) : null}

                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        loading={loadingVehicles}
                        rowKey="id"
                        dataSource={vehicles.filter(
                            (vehicle) =>
                                vehicle.rego.toLowerCase().includes(searchRego.toLowerCase()) &&
                                (searchGroup === undefined || searchGroup === vehicle.groupId)
                        )}
                        css={{
                            '.ant-table-column-title': {
                                fontWeight: 'bold',
                            },
                            marginTop: '20px',
                        }}
                    />
                </Card>
            </div>

            {/** Vehicle Assignment Modal */}
            <Modal
                title={'Assign Vehicle'}
                open={assignModalVisible}
                destroyOnClose
                footer={[]}
                onCancel={() => setAssignModalVisible(false)}
            >
                <AssignVehicle
                    assignLoading={assignLoading}
                    vehicleGroups={vehicleGroups}
                    orgs={orgs}
                    onAssign={onVehicleAssign}
                    onCancel={onVehicleAssignCancel}
                    selectedVehicles={selectedItems}
                />
            </Modal>

            {/** Vehicle Edit Modal */}
            <Modal
                title={`${selectedVehicle.id ? 'Update' : 'Create New'} Vehicle`}
                open={editModalVisible}
                destroyOnClose
                footer={[]}
                onCancel={() => setEditModalVisible(false)}
            >
                <EditVehicle
                    editLoading={editLoading}
                    selectedVehicle={selectedVehicle}
                    onSubmit={onVehicleEdit}
                    onCancel={onVehicleEditCancel}
                    isDealer={isDealer}
                />
            </Modal>
        </div>
    );
};

export default AdminVehicles;
