// Import libraries
import { css } from '@emotion/core';

// Import presets
import { content } from '../../styles/presets';

// Prepare stylesheet
const styles = {
    contentWrapper: css({ ...content }),
};

export default styles;
