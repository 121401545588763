// Import libraries
import { css } from '@emotion/core';

// Import presets
import { content } from '../../styles/presets';

// Prepare stylesheet
const styles = {
    contentWrapper: css({ ...content }),
    healthy: css({ '.ant-statistic-content-value': { color: '#389e0d' } }),
    error: css({ '.ant-statistic-content-value': { color: '#f5222d' } }),
    warning: css({ '.ant-statistic-content-value': { color: '#faad14' } }),
};

export default styles;
