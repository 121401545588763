// Import libraries
import React from 'react';
import Amplify from 'aws-amplify';
import { Global } from '@emotion/core';
import { Router, Route, Switch } from 'react-router-dom';
import { Authenticator, SignUp, Greetings } from 'aws-amplify-react';

// Import views
import AdminUsers from './views/AdminUsers';
import AdminVehicleGroups from './views/AdminVehicleGroups';
import AdminVehicles from './views/AdminVehicles';
import AdminVehicleDevices from './views/AdminVehicleDevices';
import AdminMaintenance from './views/AdminMaintenance';
import AdminMaintenanceDevice from './views/AdminMaintenanceDevice';
import AdminEvents from './views/AdminEvents';
import AdminOrgContacts from './views/AdminOrgContacts';
import Dashboard from './views/Dashboard';
// import Vehicles from './views/Vehicles';
import Devices from './views/Devices';
import RealTimeMap from './views/FleetMap/RealTimeMap';
import HistoryMap from './views/FleetMap/HistoryMap';
import IssueReports from './views/IssueReports';
import WeeklyReports from './views/WeeklyReports';
import WeeklyReport from './views/WeeklyReport';
import EventReports from './views/EventReports';
import Account from './views/Account';
import SuperOrgs from './views/SuperOrgs';
import SuperOrg from './views/SuperOrg';
import SuperOrgEdit from './views/SuperOrg/EditOrg';
import SuperDealers from './views/SuperDealers';
import SuperDealer from './views/SuperDealer';
import SuperDealerEdit from './views/SuperDealer/EditDealer';
import SuperBillings from './views/SuperBillings';
import SuperDevices from './views/SuperDevices';
import SuperFirmwares from './views/SuperFirmwares';
import Downloads from './views/Downloads';

// Import containers
import OperatorContainer from './containers/OperatorContainer';
import CouncilContainer from './containers/CouncilContainer';
import AdminContainer from './containers/AdminContainer';
import ManagerContainer from './containers/ManagerContainer';
import PrivateContainer from './containers/PrivateContainer';
import DealerContainer from './containers/DealerContainer';
import SuperAdminContainer from './containers/SuperAdminContainer';

// Import components
import ReactParticles from './components/ReactParticles';

// Import stylesheets
import globals from './styles/globals';
import theme from './styles/authTheme';
import 'mapbox-gl/dist/mapbox-gl.css';

// Import utilities
import history from './store/history';

// Import and apply authentication configurations
import amplifyConfig from './configs/amplifyConfig';
Amplify.configure(amplifyConfig);

// Configure app routes
const App = () => (
    <Router history={history}>
        <Global styles={globals} />
        <Authenticator
            theme={theme}
            hide={[SignUp, Greetings]}
            errorMessage={(message) => {
                if (message === 'User does not exist.') {
                    return 'User does not exist. Please make sure your email address is in lower case.';
                }
                return message;
            }}
        >
            {/** Sign-in background */}
            <ReactParticles />

            {/** Private container */}
            <PrivateContainer>
                <Switch>
                    {/* Guest-level operations */}
                    <Route exact path="/fleetmap/real-time" component={RealTimeMap} />

                    <OperatorContainer>
                        <Switch>
                            {/* Operator-level operations */}
                            <Route exact path="/devices" component={Devices} />

                            <CouncilContainer>
                                <Switch>
                                    {/* Council-level operations */}
                                    <Route exact path="/fleetmap/history" component={HistoryMap} />

                                    <ManagerContainer>
                                        <Switch>
                                            {/* Manager-level operations */}
                                            <Route exact path="/dashboard" component={Dashboard} />

                                            {/* <Route exact path="/vehicles/list" component={Vehicles} /> */}
                                            {/* <Route exact path="/devices/:deviceId" component={Device} /> */}
                                            <Route
                                                exact
                                                path="/reports/issues"
                                                component={IssueReports}
                                            />
                                            <Route
                                                exact
                                                path="/reports/weekly"
                                                component={WeeklyReports}
                                            />
                                            <Route
                                                exact
                                                path="/reports/weekly/:reportId"
                                                component={WeeklyReport}
                                            />
                                            <Route
                                                exact
                                                path="/reports/events"
                                                component={EventReports}
                                            />
                                            <Route exact path="/account" component={Account} />
                                            <Route exact path="/downloads" component={Downloads} />

                                            <AdminContainer>
                                                <Switch>
                                                    {/* Admin-level operations */}
                                                    <Route
                                                        exact
                                                        path="/admin/users"
                                                        component={AdminUsers}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/admin/vehicle-groups"
                                                        component={AdminVehicleGroups}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/admin/vehicle-devices"
                                                        component={AdminVehicleDevices}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/admin/vehicles"
                                                        component={AdminVehicles}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/admin/events"
                                                        component={AdminEvents}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/admin/org-contacts"
                                                        component={AdminOrgContacts}
                                                    />

                                                    <DealerContainer>
                                                        <Switch>
                                                            {/* Dealer-level operations */}
                                                            <Route
                                                                exact
                                                                path="/management/organisations"
                                                                component={SuperOrgs}
                                                            />
                                                            <Route
                                                                path="/management/organisations/create"
                                                                component={SuperOrgEdit}
                                                            />
                                                            <Route
                                                                exact
                                                                path="/management/organisations/:orgId"
                                                                component={SuperOrg}
                                                            />
                                                            <Route
                                                                path="/management/organisations/:orgId/edit"
                                                                component={SuperOrgEdit}
                                                            />
                                                            <Route
                                                                exact
                                                                path="/management/billings"
                                                                component={SuperBillings}
                                                            />
                                                            <Route
                                                                exact
                                                                path="/admin/maintenance"
                                                                component={AdminMaintenance}
                                                            />
                                                            <Route
                                                                path="/admin/maintenance/:deviceId"
                                                                component={AdminMaintenanceDevice}
                                                            />

                                                            <SuperAdminContainer>
                                                                <Switch>
                                                                    {/* SuperAdmin-level operations */}
                                                                    <Route
                                                                        exact
                                                                        path="/management/dealers"
                                                                        component={SuperDealers}
                                                                    />
                                                                    <Route
                                                                        path="/management/dealers/create"
                                                                        component={SuperDealerEdit}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path="/management/dealers/:dealerId"
                                                                        component={SuperDealer}
                                                                    />
                                                                    <Route
                                                                        path="/management/dealers/:dealerId/edit"
                                                                        component={SuperDealerEdit}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path="/management/devices"
                                                                        component={SuperDevices}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path="/management/firmwares"
                                                                        component={SuperFirmwares}
                                                                    />
                                                                </Switch>
                                                            </SuperAdminContainer>
                                                        </Switch>
                                                    </DealerContainer>
                                                </Switch>
                                            </AdminContainer>
                                        </Switch>
                                    </ManagerContainer>
                                </Switch>
                            </CouncilContainer>
                        </Switch>
                    </OperatorContainer>
                </Switch>
            </PrivateContainer>
        </Authenticator>
    </Router>
);

export default App;
