/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { useFormik } from 'formik';
import { CirclePicker } from 'react-color';

// Import Ant Design components
import { Button, Descriptions, Input } from 'antd';

const EditVehicleGroup = ({
    editLoading, selectedVehicleGroup, onSubmit, onCancel,
}) => {
    const formik = useFormik({
        initialValues: {
            name: selectedVehicleGroup.name,
            color: selectedVehicleGroup.color,
        },
        onSubmit: onSubmit,
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Descriptions column={1} css={{ marginBottom: '20px' }}>
                <Descriptions.Item label="Name">
                    <Input
                        id="name"
                        name="name"
                        placeholder="Enter group name"
                        onChange={formik.handleChange}
                        value={formik && formik.values ? formik.values.name : ''}
                        css={{
                            marginLeft: '10px',
                            marginBottom: '10px',
                            width: '60%'
                        }}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Color">
                    <CirclePicker
                        onChange={color => formik.setFieldValue('color', color.hex)}
                        color={formik && formik.values ? formik.values.color : ''}
                        css={{ marginLeft: '10px' }}
                    />
                </Descriptions.Item>
            </Descriptions>
            <Button
                htmlType="submit"
                type="primary"
                loading={editLoading}
                css={{ width: '100px' }}
            >
                Save
            </Button>
            <Button
                onClick={onCancel}
                css={{ marginLeft: '10px' }}
            >
                Cancel
            </Button>
        </form>
    );
};

export default EditVehicleGroup;
