/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import Auth from '@aws-amplify/auth';

// Import Ant Design components
import { Menu, Dropdown, Avatar } from 'antd';
import { ProfileOutlined, LogoutOutlined, AuditOutlined } from '@ant-design/icons';

// Import store
import history from '../../../../store/history';

// Import stylesheets
import { colors } from '../../../../styles/presets';
import styles from './styles';

const AvatarDropdown = ({ isSuperAdmin, isDealer }) => {
    // Handle user menu click event
    const handleUserMenuClick = key => {
        switch (key) {
            case 'management': {
                history.push('/management/organisations');
                break;
            }
            case 'account': {
                history.push('/account');
                break;
            }
            case 'logout': {
                Auth.signOut();
                break;
            }
            default: {
                console.log(`Unknown key ${ key } detected.`);
            }
        }
    };

    return (
        <Dropdown
            css={{ cursor: 'pointer' }}
            overlay={(
                <Menu
                    onClick={({ key }) => handleUserMenuClick(key)}
                    css={styles.userDropdown}
                >
                    {isSuperAdmin && (
                        <Menu.ItemGroup title="Super Admin">
                            <Menu.Item key="management">
                                <AuditOutlined />
                                Management
                            </Menu.Item>
                        </Menu.ItemGroup>
                    )}
                    <Menu.ItemGroup title="Account">
                        <Menu.Item key="account">
                            <ProfileOutlined />
                            My Account
                        </Menu.Item>
                    </Menu.ItemGroup>

                    {/** Sign out */}
                    <Menu.Divider />
                    <Menu.Item key="logout" css={{ color: colors.dustRed }}>
                        <LogoutOutlined />
                        Sign Out
                    </Menu.Item>
                </Menu>
            )}
        >
            <Avatar src="/imgs/avatar.png" />
        </Dropdown>
    );
};

export default AvatarDropdown;
