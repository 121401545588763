/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { useState } from 'react';
import { Auth } from 'aws-amplify';

// Import Ant Design components
import { List, Button, Modal, Input, Descriptions, message } from 'antd';

const Security = () => {
    const [changePswModalVisible, setChangePswModalVisible] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const data = [
        {
            title: 'Account Password',
            description: 'Current password strength: Okay',
            completed: true,
            onClick: () => setChangePswModalVisible(true),
        },
        {
            title: 'Mobile Binding',
            description: 'Coming soon ...',
            completed: false,
        },
        {
            title: 'Backup Email',
            description: 'Coming soon ...',
            completed: false,
        },
        {
            title: 'Multifactor Authentication (MFA)',
            description: 'Coming soon ...',
            completed: false,
        },
    ];

    const cleanUp = () => {
        setOldPassword('');
        setNewPassword('');
        setChangePswModalVisible(false);
    };

    const handlePswChange = async () => {
        const currentUser = await Auth.currentAuthenticatedUser();
        if (oldPassword && newPassword) {
            await Auth.changePassword(currentUser, oldPassword, newPassword);
            cleanUp();
            message.success('Password changed, please sign in again');
            await Auth.signOut({ global: true });
        } else {
            message.error('Missing values');
        }
    };

    return (
        <div>
            <div className="ant-descriptions-title">Security Settings</div>
            <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item) => (
                    <List.Item
                        actions={[
                            item.completed ? (
                                <Button type="link" onClick={item.onClick}>
                                    Modify
                                </Button>
                            ) : (
                                <Button type="link" disabled>
                                    Set Up
                                </Button>
                            ),
                        ]}
                    >
                        <List.Item.Meta title={item.title} description={item.description} />
                    </List.Item>
                )}
            />

            {/* Reset password */}
            <Modal
                title="Change Password"
                open={changePswModalVisible}
                destroyOnClose
                footer={[]}
                onCancel={() => setChangePswModalVisible(false)}
            >
                <Descriptions layout="vertical" column={1}>
                    <Descriptions.Item label="Old Password">
                        <Input.Password
                            id="oldPassword"
                            name="oldPassword"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            css={{ width: '280px' }}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="New Password">
                        <Input.Password
                            id="newPassword"
                            name="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            css={{ width: '280px' }}
                        />
                    </Descriptions.Item>
                </Descriptions>
                <div css={{ display: 'flex', marginTop: '20px' }}>
                    <Button type="primary" onClick={handlePswChange}>
                        Update
                    </Button>
                    <Button css={{ marginLeft: '15px' }} onClick={() => cleanUp()}>
                        Cancel
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default Security;
