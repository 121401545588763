// Import presets
import { content } from '../../styles/presets';

// Prepare stylesheet
const styles = {
    contentWrapper: { ...content },
    filterSection: { marginBottom: '30px' },
    datePicker: { width: '200px' },
    vehicleSelection: { width: '200px' },
    eventSelections: { minWidth: '200px', maxWidth: '400px' },
};

export default styles;
