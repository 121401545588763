/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { useState } from 'react';

// Import Ant Design components
import { Select, Button, Tabs } from 'antd';

// Import Option and TabPane components
const { Option } = Select;
const { TabPane } = Tabs;

const AssignVehicle = ({
    assignLoading, vehicleGroups, orgs, onAssign, onCancel, selectedVehicles,
}) => {
    const [assignType, setAssignType] = useState('group');
    const [selectedVehicleGroupId, setSelectedVehicleGroupId] = useState(undefined);
    const [selectedOrgId, setSelectedOrgId] = useState(undefined);

    return (
        <div>
            <p>Please select a new vehicle group or organisation to assign the vehicle:</p>
            <Tabs defaultActiveKey={assignType} onChange={key => setAssignType(key)} css={{ 'marginBottom': '30px' }}>
                <TabPane tab="Vehicle Group" key="group">
                    <Select
                        css={{ width: '200px' }}
                        value={selectedVehicleGroupId}
                        placeholder="Select a vehicle group"
                        onChange={value => setSelectedVehicleGroupId(value)}
                    >
                        {vehicleGroups.map(group => (
                            <Option key={group.id} value={group.id}>
                                {group.name}
                            </Option>
                        ))}
                        <Option key="unassigned" value="">
                            Unassigned
                        </Option>
                    </Select>
                </TabPane>
                <TabPane tab="Organisation" key="org">
                    <Select
                        css={{ width: '200px' }}
                        value={selectedOrgId}
                        placeholder="Select an organisation"
                        onChange={value => setSelectedOrgId(value)}
                    >
                        {orgs.map(org => (
                            <Option key={org.id} value={org.id} >
                                {org.name}
                            </Option>
                        ))}
                    </Select>
                </TabPane>
            </Tabs>

            <div>
                <Button
                    type="primary"
                    loading={assignLoading}
                    onClick={() => onAssign(selectedVehicleGroupId, selectedOrgId)}
                >
                    Assign
                </Button>
                <Button
                    onClick={onCancel}
                    css={{ marginLeft: '10px' }}
                >
                    Cancel
                </Button>
            </div>
        </div>
    );
};

export default AssignVehicle;
