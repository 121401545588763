/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { StaticMap } from 'react-map-gl';
import { get } from 'lodash';
import { useState, useContext, useEffect } from 'react';
import DeckGL, { IconLayer, MapView } from 'deck.gl';

// Import SocketIO Client
import { io } from 'socket.io-client';

// Import Ant Design component
import { Button, Card, Spin, message } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';

// Import stores
import { UserContext } from '../../../store/UserContext';
import { OrgsContext } from '../../../store/OrgsContext';

// Import MapBox configurations
import { INITIAL_VIEWPORT, MAPBOX_STYLES } from '../configs';

// Import utilities
import { useAmplifyOrgGetAPI } from '../../../utilities/hooks';

// Import components
import ControlPanel from './components/ControlPanel';
import VehicleDetailsPanel from './components/VehicleDetailsPanel';

const RealTimeMap = () => {
    // Drawer visibility
    const [drawerVisible, setDrawerVisible] = useState(true);

    // Data layer
    const [vehicles, setVehicles] = useState({});
    const [focusVehicleId, setFocusVehicleId] = useState('');
    const [focusVehicleLng, setFocusVehicleLng] = useState(null);
    const [focusVehicleLat, setFocusVehicleLat] = useState(null);
    const [offlineFocusVehicleId, setOfflineFocusVehicleId] = useState('');
    const [animatedVehicles, setAnimatedVehicles] = useState([]);

    // Map state data
    const [viewState, setViewState] = useState(INITIAL_VIEWPORT);

    // Status filter
    const [statusFilter, setStatusFilter] = useState('online');

    // Hovered object and positions
    const [hoveredObject, setHoveredObject] = useState({});
    const [pointerX, setPointerX] = useState(0);
    const [pointerY, setPointerY] = useState(0);

    const [socket, setSocket] = useState(null);

    // Retrieve currently selected organisation
    const orgContext = useContext(OrgsContext);
    const { selectedOrg, userRole, rtVehicleErrors } = orgContext;

    // Retrieve user timezone
    const userContext = useContext(UserContext);
    const { timezone } = userContext;

    // Fetch organisation settings
    const { data: settings, loading: loadingSettings } = useAmplifyOrgGetAPI(
        selectedOrg,
        '/settings',
        {}
    );

    useEffect(() => {
        if (settings && settings.geolocation) {
            setViewState((v) => ({
                ...v,
                latitude: settings.geolocation.latitude,
                longitude: settings.geolocation.longitude,
            }));
        }
    }, [settings]);

    useEffect(() => {
        const stopStreaming = () => {
            if (socket && socket.connected) socket.disconnect();
        };

        const startStreaming = () => {
            stopStreaming();

            // Subscribe to a new topic
            const host =
                process.env.REACT_APP_LOCAL_ENV === 'prod'
                    ? 'https://socket.fleetway.com.au:3000'
                    : 'https://socket-staging.fleetway.com.au:3000';
            const socketIO = io(`${host}?orgId=${selectedOrg}`, { secure: true });

            setSocket(socketIO);
        };

        if (selectedOrg) startStreaming();

        return () => stopStreaming();
    }, [selectedOrg, rtVehicleErrors]); // eslint-disable-line

    useEffect(() => {
        if (socket) {
            // Handle data callback
            socket.on(selectedOrg, (vehicleData) => {
                const allVehicles = {};
                const newAnimatedVehicles = [];

                Object.entries(vehicleData || {}).forEach(([vehicleId, values]) => {
                    const item = JSON.parse(values);
                    allVehicles[vehicleId] = item;

                    // Add or update real-time status
                    if (rtVehicleErrors[vehicleId]) {
                        const { hddFailure, lowBattery, gpsFailure } = rtVehicleErrors[vehicleId];
                        if (hddFailure !== undefined)
                            allVehicles[vehicleId].hddFailure = hddFailure;
                        if (lowBattery !== undefined)
                            allVehicles[vehicleId].lowBattery = lowBattery;
                        if (gpsFailure !== undefined)
                            allVehicles[vehicleId].gpsFailure = gpsFailure;
                    }

                    if (
                        item &&
                        item.position &&
                        item.position.last[0] &&
                        item.position.last[1] &&
                        item.position.target[0] &&
                        item.position.target[1]
                    ) {
                        newAnimatedVehicles.push({
                            id: vehicleId,
                            rego: item.vehicle.rego,
                            longitude: item.position.last[0],
                            latitude: item.position.last[1],
                            color: item.group.color,
                            lng: item.position.target[0],
                            lat: item.position.target[1],
                            online: item.vehicle.net,
                        });
                    }
                });

                if (allVehicles) setVehicles(allVehicles);
                setAnimatedVehicles(newAnimatedVehicles);
            });
        }
    }, [socket, rtVehicleErrors, selectedOrg]);

    useEffect(() => {
        setVehicles({});
        setAnimatedVehicles([]);
        setFocusVehicleId('');
    }, [selectedOrg]);

    const handleVehicleFocus = (vehicle) => {
        setFocusVehicleId('');

        if (
            vehicle.position.last[0] &&
            vehicle.position.last[1] &&
            vehicle.position.target[0] &&
            vehicle.position.target[1]
        ) {
            // Update center
            setViewState({
                ...viewState,
                latitude: vehicle.position.last[1],
                longitude: vehicle.position.last[0],
                zoom: statusFilter === 'offline' ? 18 : 14,
            });
        } else {
            message.warning('Unknown vehicle location');
        }

        const inSleepMode = vehicle.vehicle.hdd?.split('|')[3] === 'SL1';
        if (vehicle.vehicle.net === 'online' && inSleepMode) {
            message.info('Vehicle is in sleep mode');
        } else if (userRole !== 'operator') {
            // Set focus vehicle rego
            if (statusFilter !== 'offline') setFocusVehicleId(vehicle.id);
            else setOfflineFocusVehicleId(vehicle.id);
        } else {
            message.warning('Insufficient permissions to view vehicle details');
        }
    };

    const hexToRgb = (hex) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
            : null;
    };

    const renderTooltip = () => (
        <Card
            size="small"
            bordered={false}
            css={{
                position: 'absolute',
                zIndex: 1,
                pointerEvents: 'none',
                backgroundColor: 'black',
                color: 'white',
                left: pointerX,
                top: pointerY,
            }}
        >
            {hoveredObject.object.rego}
        </Card>
    );

    const layers = [
        // Vehicle Icon Layer
        new IconLayer({
            id: 'trucks',
            data: animatedVehicles.filter((item) => {
                if (
                    (statusFilter === 'online' && item.online === 'online') ||
                    (statusFilter === 'offline' && item.online !== 'online')
                )
                    return true;
                return false;
            }),
            pickable: true,
            iconAtlas: '/imgs/red-circle-transparent.png',
            iconMapping: {
                truck: {
                    x: 0,
                    y: 0,
                    width: 980,
                    height: 980,
                },
                truckWithMask: {
                    x: 0,
                    y: 0,
                    width: 980,
                    height: 980,
                    mask: true,
                },
            },
            sizeScale: 20,
            getPosition: (d) => {
                if (d.id === focusVehicleId && focusVehicleLat && focusVehicleLng) {
                    return [focusVehicleLng, focusVehicleLat];
                }
                return [d.longitude, d.latitude];
            },
            getIcon: (d) => (d.color ? 'truckWithMask' : 'truck'),
            getColor: (d) => {
                const rgb = d.color ? hexToRgb(d.color) : [0, 0, 0];
                if (
                    (focusVehicleId !== '' && d.id !== focusVehicleId) ||
                    (offlineFocusVehicleId !== '' && d.id !== offlineFocusVehicleId)
                ) {
                    return [...rgb, 50];
                }
                return [...rgb, 255];
            },
            onHover: (info) => {
                setHoveredObject(info);
                setPointerX(info.x);
                setPointerY(info.y);
            },
            onClick: (info) => {
                handleVehicleFocus({
                    ...vehicles[info.object.id],
                    id: info.object.id,
                });
            },
        }),
    ];

    return loadingSettings ? (
        <Spin
            tip="Fetching organisation settings ..."
            css={{ marginTop: '100px', width: '100vw' }}
        />
    ) : (
        <div css={{ width: '100vw' }}>
            <DeckGL
                viewState={viewState}
                onViewStateChange={({ viewState }) => setViewState(viewState)}
                controller={false}
                layers={layers}
                width={`calc(100vw - ${drawerVisible ? '350px' : '0px'})`}
                height="calc(100vh - 64px)"
            >
                <MapView id="map" controller>
                    <StaticMap
                        key="map"
                        mapboxApiAccessToken={process.env.MAPBOX_ACCESS_TOKEN}
                        mapStyle={MAPBOX_STYLES.newCustom}
                    />
                </MapView>

                {!drawerVisible && (
                    <Button
                        type="primary"
                        icon={<FileSearchOutlined />}
                        onClick={() => setDrawerVisible(true)}
                        css={{
                            position: 'absolute',
                            top: '25px',
                            right: '25px',
                        }}
                    >
                        List View
                    </Button>
                )}

                {/* Tooltips shown on mouse hover events */}
                {hoveredObject && hoveredObject.picked && renderTooltip()}
            </DeckGL>

            {focusVehicleId ? (
                <VehicleDetailsPanel
                    did={get(vehicles, `${focusVehicleId}.vehicle.did`)}
                    rid={get(vehicles, `${focusVehicleId}.vehicle.rid`)}
                    vloss={get(vehicles, `${focusVehicleId}.vehicle.vloss`)}
                    rec={get(vehicles, `${focusVehicleId}.vehicle.rec`)}
                    rego={get(vehicles, `${focusVehicleId}.vehicle.rego`)}
                    groupName={get(vehicles, `${focusVehicleId}.group.name`)}
                    groupColor={get(vehicles, `${focusVehicleId}.group.color`)}
                    net={get(vehicles, `${focusVehicleId}.vehicle.net`)}
                    gpsFix={get(vehicles, `${focusVehicleId}.status.GpsFix`)}
                    hdd={get(vehicles, `${focusVehicleId}.vehicle.hdd`)}
                    service={get(vehicles, `${focusVehicleId}.vehicle.Service`)}
                    drawerVisible={drawerVisible}
                    handleDrawerClose={() => setDrawerVisible(false)}
                    handleVehicleUnfocus={() => setFocusVehicleId('')}
                    handleVehiclePosChange={(longitude, latitude) => {
                        setFocusVehicleLng(longitude);
                        setFocusVehicleLat(latitude);
                        setViewState((currentViewState) => ({
                            ...currentViewState,
                            latitude,
                            longitude,
                        }));
                    }}
                />
            ) : (
                <ControlPanel
                    vehicles={vehicles}
                    drawerVisible={drawerVisible}
                    statusFilter={statusFilter}
                    handleDrawerClose={() => setDrawerVisible(false)}
                    handleVehicleFocus={handleVehicleFocus}
                    handleStatusFilterChange={(e) => {
                        setStatusFilter(e.target.value);
                        setOfflineFocusVehicleId('');
                    }}
                    timezone={timezone}
                />
            )}
        </div>
    );
};

export default RealTimeMap;
