/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { find } from 'lodash';
import moment from 'moment';
import { io } from 'socket.io-client';
import { IntercomProvider } from 'react-use-intercom';
import { useState, useMemo, useEffect, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';

// Import Ant Design components
import { Empty, Button, Typography, Spin, notification } from 'antd';

// Import store
import history from '../../store/history';
import { UserContext } from '../../store/UserContext';
import { OrgsContext } from '../../store/OrgsContext';

// Import layout
import AppLayout from '../../layouts/AppLayout';

// Import constants
import { ORG_ROLES } from '../../constants/orgRoles';

// Import actions
import { useUserFetch, useOrgsFetch, setDefaultOrg } from './actions';

// Import Title and Text components
const { Title, Text } = Typography;

// Extract values from environment variables
const { REACT_APP_INTERCOM_APP_ID } = process.env;

const PrivateContainer = (props) => {
    // Initialisation
    const [socketIO, setSocketIO] = useState(null);
    const [rtVehicleErrors, setRTVehicleErrors] = useState({}); // real-time vehicle errors

    // Fetch user data
    const {
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        timezone,
        setTimezone,
        isSuperAdmin,
        isDealer,
    } = useUserFetch(props.authState);

    // Fetch organisation data
    const {
        orgs,
        setOrgs,
        userRole,
        setUserRole,
        selectedOrg,
        setSelectedOrg,
        loading: loadingOrgs,
    } = useOrgsFetch(props.authState);

    useEffect(() => {
        if (selectedOrg) {
            // Initialisation
            setRTVehicleErrors({});
            if (socketIO && socketIO.connected) socketIO.disconnect();

            let host = 'http://localhost:3100';
            switch (process.env.REACT_APP_LOCAL_ENV) {
                case 'dev':
                    host = 'https://mc-staging.fleetway.com.au:3100';
                    break;
                case 'prod':
                    host = 'https://mc.fleetway.com.au:3100';
                    break;
                default:
                    break;
            }
            const socket = io(host, {
                secure: true,
                rejectUnauthorized: false,
                path: '/mc',
                query: { orgId: selectedOrg },
            });

            // Actions on data received
            socket.on(selectedOrg, (data) => {
                if (['dev', 'local'].indexOf(process.env.REACT_APP_LOCAL_ENV) >= 0)
                    console.log(data);
                // console.log(data);
                let message = {};
                try {
                    message = JSON.parse(data);
                } catch (error) {
                    console.error('Invalid message received from message center');
                }

                if (message.type === 'warning' || message.type === 'error') {
                    notification[message.type]({
                        message: message.title,
                        description: (
                            <div>
                                <div>Vehicle: {message.vehicleRego || 'Unknown'}</div>
                                <div>
                                    TIME:{' '}
                                    {moment
                                        .utc(message.utcTime * 1000)
                                        .local()
                                        .format('DD/MM/YYYY HH:mm:ss')}
                                </div>
                            </div>
                        ),
                        placement: 'bottomRight',
                        duration: message.duration,
                    });
                } else if (message.type === 'flag') {
                    const { vehicleId, updates } = message;
                    setRTVehicleErrors((errors) => {
                        const vehicleErrors = errors[vehicleId] || {};
                        return {
                            ...errors,
                            [vehicleId]: {
                                ...vehicleErrors,
                                ...updates,
                            },
                        };
                    });
                }
            });

            socket.on('connect', () => {
                if (process.env.REACT_APP_LOCAL_ENV === 'dev') {
                    console.log(`Subscribed to ${host}/mc?orgId=${selectedOrg}`);
                }
            });

            socket.on('connect_error', (error) => {
                console.error('Unable to connect to message center');
            });

            socket.on('connect_timeout', (timeout) => {
                console.error('Connection to message center timed out');
            });

            setSocketIO(socket);
        }
    }, [selectedOrg]); // eslint-disable-line

    useEffect(() => {
        if (props.authState === 'signIn' && socketIO && socketIO.connected) {
            socketIO.disconnect();
            console.log('Disconnected from message center');
            setSocketIO(null);
        }
    }, [props.authState, socketIO]);

    // Update user role
    useEffect(() => {
        if (orgs && orgs.length > 0) {
            const { role } = find(orgs, { id: selectedOrg }) || {};
            if (role) setUserRole(role);
        }
    }, [orgs, selectedOrg, setUserRole]);

    const userContextValue = useMemo(
        () => ({
            firstName,
            setFirstName,
            lastName,
            setLastName,
            email,
            setEmail,
            timezone,
            setTimezone,
            isSuperAdmin,
            isDealer,
        }),
        [
            firstName,
            setFirstName,
            lastName,
            setLastName,
            email,
            setEmail,
            timezone,
            setTimezone,
            isSuperAdmin,
            isDealer,
        ]
    );
    const orgsContextValue = useMemo(
        () => ({
            orgs,
            setOrgs,
            userRole,
            setUserRole,
            selectedOrg,
            setSelectedOrg,
            setDefaultOrg,
            rtVehicleErrors,
            setRTVehicleErrors,
        }),
        [orgs, setOrgs, userRole, setUserRole, selectedOrg, setSelectedOrg, rtVehicleErrors]
    );

    // Hide private content if not signed in
    if (props.authState !== 'signedIn') return null;

    // Redirect to /dashboard page if no path name presents
    const pathname =
        props && props.location && props.location.pathname
            ? props.location.pathname.replace('/', '')
            : '';
    if (
        [
            ORG_ROLES.GUEST.name,
            ORG_ROLES.OPERATOR.name,
            ORG_ROLES.COUNCIL.name,
            ORG_ROLES.VIEWER.name,
        ].indexOf(userRole) >= 0 &&
        pathname === 'dashboard'
    ) {
        history.push('/fleetmap/real-time');
    } else if (
        userRole === ORG_ROLES.ARCHIVIST.name &&
        ['dashboard', 'fleetmap/real-time'].includes(pathname)
    ) {
        history.push('/fleetmap/history');
    } else if (
        isDealer &&
        (/^fleetmap(.*)?/.test(pathname) ||
            /^dashboard$/.test(pathname) ||
            /^devices(.*)?/.test(pathname) ||
            /^reports(.*)?/.test(pathname))
    ) {
        history.push('/admin/users');
    } else if (!pathname) {
        if (isDealer) history.push('/admin/users');
        history.push('/dashboard');
    }

    if (!email) return null;

    return (
        <IntercomProvider
            appId={REACT_APP_INTERCOM_APP_ID}
            apiBase="https://api-iam.intercom.io"
            autoBoot
            autoBootProps={{
                name: `${firstName} ${lastName}`.trim(),
                email,
            }}
        >
            <UserContext.Provider value={userContextValue}>
                <OrgsContext.Provider value={orgsContextValue}>
                    <AppLayout pathname={pathname}>
                        {orgs.length > 0 ||
                        pathname.match(/^management\//g) ||
                        pathname.match(/^account$/g) ? (
                            props.children
                        ) : (
                            <div
                                css={{
                                    width: '45%',
                                    margin: '100px auto 0px auto',
                                    textAlign: 'center',
                                }}
                            >
                                {loadingOrgs ? (
                                    <Spin tip="Loading organisations ..." />
                                ) : (
                                    <Fragment>
                                        <div>
                                            <Empty description={null} />
                                            <Title level={3}>
                                                Please create a new organisation
                                            </Title>
                                            <Text>
                                                Or add yourself to one of your existing
                                                organisations.
                                            </Text>
                                        </div>
                                        <Link to="/management/organisations">
                                            <Button type="primary" css={{ marginTop: '30px' }}>
                                                Open Management Panel
                                            </Button>
                                        </Link>
                                    </Fragment>
                                )}
                            </div>
                        )}
                    </AppLayout>
                </OrgsContext.Provider>
            </UserContext.Provider>
        </IntercomProvider>
    );
};

export default withRouter(PrivateContainer);
