/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import shortid from 'shortid';
import moment from 'moment-timezone';
import { ceil, orderBy } from 'lodash';
import { useState } from 'react';

// Import Ant Design components
import { Drawer, Button, Input, List, Radio, Badge, Space, message } from 'antd';
import {
    MenuUnfoldOutlined,
    HddOutlined,
    ThunderboltOutlined,
    CompassOutlined,
} from '@ant-design/icons';

// Import Search component
const { Search } = Input;

const ControlPanel = (props) => {
    const {
        vehicles,
        drawerVisible,
        statusFilter,
        handleDrawerClose,
        handleVehicleFocus,
        handleStatusFilterChange,
        timezone,
    } = props;

    const [searchRego, setSearchRego] = useState('');

    return (
        <Drawer
            title={
                <div
                    css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <h4 css={{ margin: 0 }}>Real Time Map</h4>
                    <Button
                        size="small"
                        icon={<MenuUnfoldOutlined />}
                        onClick={handleDrawerClose}
                    />
                </div>
            }
            placement="right"
            width="350px"
            mask={false}
            getContainer={false}
            closable={false}
            open={drawerVisible}
        >
            <h3>Search by Rego</h3>
            <Search
                placeholder="Enter vehicle rego"
                onChange={(e) => setSearchRego(e.target.value)}
                css={{ width: '90%', marginBottom: '30px' }}
                allowClear
            />

            {/* Vehicle List */}
            <div css={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>All Vehicles</h3>
                <Radio.Group
                    value={statusFilter}
                    buttonStyle="solid"
                    size="small"
                    onChange={handleStatusFilterChange}
                >
                    <Radio.Button value="online">
                        Online (
                        {
                            Object.values(vehicles || {})
                                .filter(
                                    (item) =>
                                        item.vehicle &&
                                        item.vehicle.rego
                                            .toLowerCase()
                                            .includes(searchRego.toLowerCase())
                                )
                                .filter((item) => item.vehicle && item.vehicle.net === 'online')
                                .length
                        }
                        )
                    </Radio.Button>
                    <Radio.Button value="offline">
                        Offline (
                        {
                            Object.values(vehicles || {})
                                .filter(
                                    (item) =>
                                        item.vehicle &&
                                        item.vehicle.rego
                                            .toLowerCase()
                                            .includes(searchRego.toLowerCase())
                                )
                                .filter(
                                    (item) =>
                                        item.vehicle &&
                                        (item.vehicle.net === 'offline' ||
                                            item.vehicle.net === 'timeout')
                                ).length
                        }
                        )
                    </Radio.Button>
                </Radio.Group>
            </div>
            <List
                itemLayout="horizontal"
                dataSource={orderBy(
                    Object.entries(vehicles || {})
                        .map(([id, value]) => ({ ...value, id }))
                        .filter((item) =>
                            item.vehicle.rego.toLowerCase().includes(searchRego.toLowerCase())
                        )
                        .filter((item) => {
                            if (
                                (statusFilter === 'online' && item.vehicle.net === 'online') ||
                                (statusFilter === 'offline' && item.vehicle.net !== 'online')
                            )
                                return true;
                            return false;
                        }),
                    [statusFilter === 'offline' ? 'vehicle.osTime' : 'status.speed'],
                    ['desc']
                )}
                pagination={{ size: 'small', pageSize: 12, showSizeChanger: false }}
                renderItem={(item) => {
                    const { hdd } = item?.vehicle || {};
                    const inSleepMode = hdd?.split('|')[3] === 'SL1';
                    return (
                        <List.Item
                            key={
                                item.vehicle && item.vehicle.rid
                                    ? item.vehicle.rid
                                    : shortid.generate()
                            }
                            onClick={() => handleVehicleFocus(item)}
                            css={{ display: 'flex', height: '45px' }}
                        >
                            <List.Item.Meta
                                title={
                                    <Badge
                                        // text={item.vehicle ? item.vehicle.rego : 'N/A'}
                                        color={
                                            item.group && item.group.color
                                                ? item.group.color
                                                : 'gray'
                                        }
                                    />
                                }
                                css={{ '.ant-list-item-meta-title': { marginBottom: 0 } }}
                            />
                            <div
                                css={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    backgroundColor: hdd !== 'H0|S0' ? '#fff' : '#fff1f0',
                                    width: '93%',
                                    height: '43px',
                                    alignItems: 'center',
                                    paddingLeft: '10px',
                                }}
                            >
                                {item.vehicle ? item.vehicle.rego : 'N/A'}
                                {!item.vehicle || item.vehicle.net !== 'online' ? (
                                    <span css={{ paddingRight: '10px' }}>
                                        {moment
                                            .utc(item.vehicle.osTime)
                                            .tz(timezone)
                                            .format('DD/MM HH:mm:ss')}
                                    </span>
                                ) : (
                                    <span>
                                        <Space>
                                            {item.hddFailure && (
                                                <HddOutlined style={{ color: 'red' }} />
                                            )}
                                            {item.lowBattery && (
                                                <ThunderboltOutlined style={{ color: 'red' }} />
                                            )}
                                            {item.gpsFailure && (
                                                <CompassOutlined style={{ color: 'red' }} />
                                            )}
                                        </Space>

                                        {inSleepMode ? (
                                            <span
                                                style={{
                                                    color: '#b68f8f',
                                                    paddingRight: '10px',
                                                }}
                                            >
                                                Sleep Mode
                                            </span>
                                        ) : (
                                            <span
                                                css={{ marginLeft: '25px', paddingRight: '10px' }}
                                            >
                                                <b>{`${
                                                    item.status ? ceil(item.status.speed) : 0
                                                } `}</b>
                                                km/h
                                            </span>
                                        )}

                                        {/* <span
                                            css={{
                                                color: item.vehicle && item.vehicle.hdd
                                                    && item.vehicle.hdd !== '0|S0' ? 'blue' : 'red',
                                                fontSize: '20px',
                                            }}>
                                            &#9679;
                                        </span> */}
                                    </span>
                                )}
                            </div>
                        </List.Item>
                    );
                }}
            />
        </Drawer>
    );
};

export default ControlPanel;
