// Import library
import { css } from '@emotion/core';

const styles = {
    header: css({
        width: '90%',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
    }),
    headerLogo: css({
        img: { height: '32px' },
    }),
    leftSideHeader: css({
        display: 'flex',
        ul: {
            backgroundColor: 'transparent',
            color: 'white',
            li: {
                span: {
                    verticalAlign: 'middle',
                    svg: { fontSize: '25px' },
                },
            },
            '.ant-menu-item': { a: { color: 'inherit' } },
        },
    }),
    rightSideHeader: css({}),
};

export default styles;
