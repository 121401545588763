const globals = {
    'html, body, #root, #root>div': {
        width: '100vw',
        height: '100vh',
        margin: 0,
    },
    '#root': {
        backgroundColor: '#FAFAFA',
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif !important',
    },
    '.ant-badge-status-dot': {
        width: '9px',
        height: '9px',
    },
    '.ant-popover-inner-content': { width: '100%' },
};

export default globals;
