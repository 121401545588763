// Import libraries
import { css } from '@emotion/core';

// Import presets
import { content, colors } from '../../styles/presets';

// Prepare stylesheet
const styles = {
    contentWrapper: css({ ...content }),
    adminTagColor: colors.primary,
    queryLabel: css({ marginRight: '10px' }),
    queryInput: css({
        width: '200px',
        marginRight: '30px',
    }),
};

export default styles;
