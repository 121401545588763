// Import libraries
import request from 'superagent';

// Import local store
import localStore from './localStore';

// Load environment variables
const {
    REACT_APP_API_URL: API_URL,
    REACT_APP_ACCESS_TOKEN_NAME: ACCESS_TOKEN_NAME,
} = process.env;

/**
 * Get user authentication token from the local storage.
 * @returns User authentication token or null (if not exist).
 */
const getUserToken = () => {
    const userToken = localStore.getItem(ACCESS_TOKEN_NAME);
    /** TODO: Add additional checks with the user token (if needed) */
    return userToken;
};

/**
 * Remove all prefix / in the given url and return the formatted path.
 * @param path Path to be checked and formatted
 * @returns A formatted url
 */
const checkURLPath = path => {
    let sliceLength = 0;
    for (let idx = 0; idx < path.length; idx += 1) {
        if (path[idx] === '/') sliceLength += 1;
        else break;
    }
    return path.slice(sliceLength);
};

/**
 * Send a GET request to a backend service or an external service if url is provided.
 * By default, authentication token from the local storage will be sent along with the request.
 * If a different authentication token needs to be sent, use the authToken argument.
 * @param path Backend resource path
 * @param paramters Request parameters
 * @param host Host address of the external service, default to the backend api host
 * @param authToken A different authentication token to be sent
 * @returns A request promise to be resolved
 */
export const getApi = async (path, paramters = {}, host = API_URL, authToken = null) => {
    const token = authToken || getUserToken();
    const getRequest = request.get(`${ host }/${ checkURLPath(path) }`)
        .query(paramters)
        .withCredentials();
    if (token) getRequest.set('Authorization', token);
    return getRequest;
};

/**
 * Send a POST request to a backend service or an external service if url is provided.
 * By default, authentication token from the local storage will be sent along with the request.
 * If a different authentication token needs to be sent, use the authToken argument.
 * @param path Backend resource path
 * @param data Request body
 * @param host Host address of the external service, default to the backend api host
 * @param authToken A different authentication token to be sent
 * @returns A request promise to be resolved
 */
export const postApi = async (path, data = {}, host = API_URL, authToken = null) => {
    const token = authToken || getUserToken();
    const postRequest = request.post(`${ host }/${ checkURLPath(path) }`)
        .send(data)
        .withCredentials();
    if (token) postRequest.set('Authorization', `${ token }`);
    return postRequest;
};

/**
 * Send a PUT request to a backend service or an external service if url is provided.
 * By default, authentication token from the local storage will be sent along with the request.
 * If a different authentication token needs to be sent, use the authToken argument.
 * @param path Backend resource path
 * @param data Request body
 * @param host Host address of the external service, default to the backend api host
 * @param authToken A different authentication token to be sent
 * @returns A request promise to be resolved
 */
export const putApi = async (path, data = {}, host = API_URL, authToken = null) => {
    const token = authToken || getUserToken();
    const putRequest = request.put(`${ host }/${ checkURLPath(path) }`)
        .send(data)
        .withCredentials();
    if (token) putRequest.set('Authorization', `${ token }`);
    return putRequest;
};

/**
 * Send a DELETE request to a backend service or an external service if url is provided.
 * By default, authentication token from the local storage will be sent along with the request.
 * If a different authentication token needs to be sent, use the authToken argument.
 * @param path Backend resource path
 * @param data Request body
 * @param host Host address of the external service, default to the backend api host
 * @param authToken A different authentication token to be sent
 * @returns A request promise to be resolved
 */
export const deleteApi = async (path, data = {}, host = API_URL, authToken = null) => {
    const token = authToken || getUserToken();
    const deleteRequest = request.delete(`${ host }/${ checkURLPath(path) }`)
        .send(data)
        .withCredentials();
    if (token) deleteRequest.set('Authorization', `${ token }`);
    return deleteRequest;
};

/**
 * Optionally retrieve nested error message from response
 * @param {Error} err api error
 * @param {string} [fallback=undefined] optional custom fallback error to be returned
 * @returns {string} returns error message or generic error 'Internal Server Error
 */
export const fetchApiErrorMessage = (err, fallback = undefined) => (
    err.response
    && err.response.data
    && err.response.data.message
) || (
    fallback || 'Internal Server Error'
);
