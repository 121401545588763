/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import API from '@aws-amplify/api';
import { orderBy } from 'lodash';
import { useEffect, useState, useContext } from 'react';

// Import Ant Design component
import { Card, PageHeader, Timeline, message } from 'antd';

// Import store
import { OrgsContext } from '../../store/OrgsContext';

// Import utilities
import { breadcrumbRender } from '../../utilities/breadcrumb';

// Import breadcrumb routes
import routes from './routes';

// Import stylesheet
import styles from './styles';

const AdminMaintenanceDevice = props => {
    // Retrieve device id from path parameter
    const { deviceId } = props.match.params;

    // Retrieve currently selected organisation
    const orgsContext = useContext(OrgsContext);
    const { selectedOrg } = orgsContext;

    // Initialization
    const [loadingLogs, setLoadingLogs] = useState(false);
    const [historyLogs, setHistoryLogs] = useState([]);

    useEffect(() => {
        const fetchHistoryLogs = async deviceId => {
            setLoadingLogs(true);
            try {
                const data = await API.get('organisations', `/${ selectedOrg }/devices/${ deviceId }`);
                setHistoryLogs(data);
            } catch (error) {
                message.error('Unable to fetch device status history');
            }
            setLoadingLogs(false);
        };

        if (deviceId && selectedOrg) fetchHistoryLogs(deviceId);
    }, [deviceId, selectedOrg]);

    return (
        <div>
            <PageHeader
                title="Device Status History"
                subTitle={deviceId}
                breadcrumb={{ routes, itemRender: breadcrumbRender }}
                ghost={false}
            />

            <div css={styles.contentWrapper}>
                <Card loading={loadingLogs}>
                    {historyLogs.length === 0
                        ? 'No logs found'
                        : (
                            <Timeline mode="left">
                                {orderBy(historyLogs, ['createdAt'], ['desc']).map(log => (
                                    <Timeline.Item
                                        key={log.id}
                                        color={log.resolved ? 'green' : 'red'}
                                        label={log.createdAt}
                                    >
                                        Issue {log.type} is detected on device.
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        )
                    }
                </Card>
            </div>
        </div>
    );
};

export default AdminMaintenanceDevice;
