// Import utilities
import { breadcrumbRender } from '../../utilities/breadcrumb';

const getBreadcrumb = (orgId, currentAction) => {
    const routes = [
        {
            path: '/management/organisations',
            breadcrumbName: 'Organisations',
        },
    ];

    if (orgId) {
        routes.push({
            path: `/management/organisations/${ orgId }`,
            breadcrumbName: 'Organisation Details',
        });
    }

    if (currentAction) {
        routes.push({ breadcrumbName: currentAction });
    }

    return { routes, itemRender: breadcrumbRender };
};

export default getBreadcrumb;
