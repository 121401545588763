/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { useContext } from 'react';
import { Link } from 'react-router-dom';

// Import Ant Design components
import { Layout, Menu, Select, Divider, Button, Row, Col, message } from 'antd';
import { StarOutlined } from '@ant-design/icons';

// Import stores
import { UserContext } from '../../../../store/UserContext';
import { OrgsContext } from '../../../../store/OrgsContext';

// Import components
import AvatarDropdown from '../AvatarDropdown';

// Import stylesheets
import colors from '../../../../styles/colors';
import { message as messagePresets } from '../../../../styles/presets';
import styles from './styles';

// Import routes
import getRoutes from './routes';

// Import Header, Option and OptGroup components
const { Header } = Layout;
const { Option, OptGroup } = Select;

// Configure Antd message component (applied globally)
message.config({ ...messagePresets });

/**
 * Return true if on an admin page.
 * @param {*} pathname Page path name
 * @returns true if currenetly on an admin page, otherwise false
 */
const onAdminPage = (pathname) => pathname.match(/^admin\//g);

/**
 * Return true if on a management page.
 * @param {*} pathname Page path name
 * @returns true if currenetly on an management page, otherwise false
 */
const onManagementPage = (pathname) => pathname.match(/^management\//g);

const rightContentRender = (pathname, userContext, orgsContext) => {
    const { firstName, lastName, isSuperAdmin, isDealer } = userContext;
    const { orgs, setOrgs, selectedOrg, setSelectedOrg, setDefaultOrg, userRole } = orgsContext;

    // Prepare organisation group data
    const orgGroups = {};
    orgs.forEach((org) => {
        const orgToInsert = {
            ...org,
            isParentOrg: !org.parentOrgId,
            parentOrgId: org.parentOrgId || org.id,
        };
        if (orgToInsert.parentOrgId in orgGroups) {
            if (orgToInsert.isParentOrg) {
                orgGroups[orgToInsert.parentOrgId].unshift(orgToInsert);
            } else {
                orgGroups[orgToInsert.parentOrgId].push(orgToInsert);
            }
        } else {
            orgGroups[orgToInsert.parentOrgId] = [orgToInsert];
        }
    });

    return (
        <div css={{ minWidth: '400px' }}>
            {/* Enter admin panel */}
            {(userRole === 'admin' || userRole === 'dealer') &&
                !onAdminPage(pathname) &&
                !onManagementPage(pathname) && (
                    <Link to={'/admin/users'}>
                        <Button
                            css={{
                                marginRight: '30px',
                                color: '#fff',
                                backgroundColor: colors.adminColor,
                                border: 0,
                            }}
                        >
                            Admin Panel
                        </Button>
                    </Link>
                )}

            {/* Exit admin or management panel */}
            {((onAdminPage(pathname) && !isDealer && selectedOrg !== '') ||
                onManagementPage(pathname)) && (
                <Link to={isDealer ? '/admin/users' : '/dashboard'}>
                    <Button
                        type="danger"
                        css={{
                            marginRight: '30px',
                            color: '#fff',
                            backgroundColor: onAdminPage(pathname)
                                ? colors.adminColor
                                : colors.superColor,
                            border: 0,
                        }}
                    >
                        Exit {onAdminPage(pathname) ? 'Admin' : 'Management'} Panel
                    </Button>
                </Link>
            )}

            {/** Organisation dropdown menu */}
            {!onManagementPage(pathname) && Object.keys(orgGroups).length > 0 && (
                <Select
                    value={selectedOrg}
                    css={{ minWidth: '160px', marginRight: '20px' }}
                    onChange={(key) => setSelectedOrg(key)}
                    showSearch
                    placeholder="Select an Organisation"
                    optionFilterProp="name"
                    optionLabelProp="name"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ minWidth: '240px' }}
                    dropdownRender={(menu) => (
                        <div>
                            {menu}
                            <Divider css={{ margin: '4px 0' }} />
                            <div
                                css={{ padding: '8px', cursor: 'pointer' }}
                                role="presentation"
                                onMouseDown={(e) => e.preventDefault()}
                            >
                                <Button
                                    icon={<StarOutlined />}
                                    block
                                    onClick={() => {
                                        setDefaultOrg(selectedOrg);
                                        setOrgs(
                                            orgs.map((org) => ({
                                                ...org,
                                                isDefault: org.id === selectedOrg,
                                            }))
                                        );
                                    }}
                                >
                                    Set current as default
                                </Button>
                            </div>
                        </div>
                    )}
                >
                    {Object.values(orgGroups).map((orgs, idx) => (
                        <OptGroup
                            key={`org-group-${idx + 1}`}
                            label={`Organisation Group ${idx + 1}`}
                        >
                            {orgs.map((org) => (
                                <Option value={org.id} key={org.id} name={org.name}>
                                    <Row align="middle" justify="space-between" type="flex">
                                        <Col style={{ flexGrow: 1 }}>
                                            {`${!org.isParentOrg ? '- ' : ''}${org.name}`}
                                        </Col>
                                        <Col>
                                            {org.isDefault ? <StarOutlined theme="filled" /> : null}
                                        </Col>
                                    </Row>
                                </Option>
                            ))}
                        </OptGroup>
                    ))}
                </Select>
            )}

            {isDealer && !onManagementPage(pathname) && (
                <Link to={'/management/organisations'}>
                    <Button
                        css={{
                            marginRight: '20px',
                            color: '#fff',
                            backgroundColor: colors.superColor,
                            border: 0,
                        }}
                    >
                        Management Panel
                    </Button>
                </Link>
            )}

            {/** User dropdown menu */}
            <AvatarDropdown
                firstName={firstName}
                lastName={lastName}
                isSuperAdmin={isSuperAdmin}
                isDealer={isDealer}
                userRole={userRole}
            />
        </div>
    );
};

const AppHeader = ({ pathname }) => {
    // Retrieve organisation and user context
    const orgsContext = useContext(OrgsContext);
    const userContext = useContext(UserContext);
    const { userRole } = orgsContext;
    const { isSuperAdmin, isDealer } = userContext;

    const extraHeaderStyles = {};
    if (onAdminPage(pathname)) {
        extraHeaderStyles.backgroundColor = '#5C7098';
    } else if (onManagementPage(pathname)) {
        extraHeaderStyles.backgroundColor = '#2e62a2';
    }

    return (
        <Header style={extraHeaderStyles}>
            <div css={styles.header}>
                <div css={styles.leftSideHeader}>
                    {/* Logo */}
                    <Link to={isDealer ? '/admin/users' : '/dashboard'}>
                        <div css={styles.headerLogo}>
                            <img src="/imgs/logo_new.png" alt="Fleetway logo" />
                        </div>
                    </Link>

                    <Menu
                        theme="light"
                        mode="horizontal"
                        selectedKeys={[pathname]}
                        style={{ minWidth: '700px' }}
                    >
                        {getRoutes(pathname, userRole, isSuperAdmin, isDealer)}
                    </Menu>
                </div>

                <div css={styles.rightSideHeader}>
                    {rightContentRender(pathname, userContext, orgsContext)}
                </div>
            </div>
        </Header>
    );
};

export default AppHeader;
