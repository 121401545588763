/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { find } from 'lodash';
import { useState, useContext, Fragment } from 'react';

// Import Ant Design component
import {
    Card,
    Button,
    Table,
    Empty,
    Input,
    Divider,
    Modal,
    Popconfirm,
    Descriptions,
    Radio,
    PageHeader,
    Select,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

// Import store
import { UserContext } from '../../store/UserContext';
import { OrgsContext } from '../../store/OrgsContext';

// Import utilities
import { useAmplifyOrgGetAPI } from '../../utilities/hooks';

// Import constants
import { ORG_ROLES } from '../../constants/orgRoles';
import AU_TIMEZONES from '../../constants/timezones';

// Import actions
import { addUser, updateUser, deleteUser } from './actions';

// Load stylesheet
import styles from './styles';

// Import additional Ant Design components
const { Option } = Select;

const AdminUsers = () => {
    const [dataFetchedAt, setDataFetchedAt] = useState(new Date().getTime());
    const [searchEmail, setSearchEmail] = useState('');
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [userInEdit, setUserInEdit] = useState({});
    const [editLoading, setEditLoading] = useState(false);

    // Check if user is a dealer user
    const userContext = useContext(UserContext);
    const { isDealer } = userContext;

    // Retrieve currently selected organisation
    const orgsContext = useContext(OrgsContext);
    const { orgs, selectedOrg } = orgsContext;
    const orgName = orgs && selectedOrg ? find(orgs, { id: selectedOrg }).name : '';

    // Fetch user data
    const { data: users, loading: loadingUsers } = useAmplifyOrgGetAPI(selectedOrg, '/users', [], {
        t: dataFetchedAt,
    });

    // Configure table columns
    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            render: (role) => {
                const { displayName = role } = find(Object.values(ORG_ROLES), { name: role }) || {};
                return displayName;
            },
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
        },
        {
            render: (value, user) => (
                <div>
                    {!isDealer && (
                        <Fragment>
                            <Button
                                type="link"
                                onClick={() => {
                                    setUserInEdit(user);
                                    setEditModalVisible(true);
                                }}
                            >
                                Edit
                            </Button>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="Are you sure delete this user?"
                                onConfirm={async () => {
                                    await deleteUser(selectedOrg, user.id);
                                    setDataFetchedAt(new Date().getTime());
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="link">Remove</Button>
                            </Popconfirm>
                            <Divider type="vertical" />
                        </Fragment>
                    )}
                </div>
            ),
        },
    ];
    if (!isDealer) {
        columns.push();
    }

    return (
        <div>
            <PageHeader
                title="Organisation Users"
                subTitle={`${users.length} users found`}
                ghost={false}
            />

            <div css={styles.contentWrapper}>
                <Card>
                    {!isDealer && (
                        <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => setAddModalVisible(true)}
                            >
                                Add User
                            </Button>
                        </div>
                    )}
                    <div css={{ margin: '20px 0px' }}>
                        <span css={styles.queryLabel}>Filter:</span>
                        <Input
                            value={searchEmail}
                            css={styles.queryInput}
                            placeholder="Enter email address"
                            onChange={(e) => setSearchEmail(e.target.value)}
                        />
                        <Button onClick={() => setSearchEmail('')}>Clear</Button>
                    </div>
                    <Table
                        columns={columns}
                        loading={loadingUsers}
                        rowKey="id"
                        dataSource={users.filter((user) =>
                            user.email.toLowerCase().includes(searchEmail.toLowerCase())
                        )}
                        locale={{
                            emptyText: (
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="No users found"
                                />
                            ),
                        }}
                        css={{
                            '.ant-table-column-title': {
                                fontWeight: 'bold',
                            },
                        }}
                    />
                </Card>
            </div>

            <Modal
                title={addModalVisible ? 'Add a new user' : 'Edit user'}
                open={addModalVisible || editModalVisible}
                onCancel={() => {
                    if (addModalVisible) setAddModalVisible(false);
                    else setEditModalVisible(false);
                    setUserInEdit({});
                }}
                width={800}
                footer={[
                    <Button
                        key="edit"
                        type="primary"
                        onClick={async () => {
                            setEditLoading(true);
                            if (addModalVisible) {
                                await addUser(selectedOrg, userInEdit);
                                setAddModalVisible(false);
                            } else {
                                await updateUser(selectedOrg, userInEdit);
                                setEditModalVisible(false);
                            }
                            setEditLoading(false);
                            setUserInEdit({});
                            setDataFetchedAt(new Date().getTime());
                        }}
                        loading={editLoading}
                    >
                        {addModalVisible ? 'Add' : 'Update'}
                    </Button>,
                    <Button
                        key="cancel"
                        onClick={() => {
                            if (addModalVisible) setAddModalVisible(false);
                            else setEditModalVisible(false);
                            setUserInEdit({});
                        }}
                    >
                        Cancel
                    </Button>,
                ]}
            >
                <Descriptions column={1}>
                    <Descriptions.Item label="Organisation">
                        <b>{orgName}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Email">
                        <Input
                            value={userInEdit.email}
                            placeholder="Enter user email address"
                            disabled={editModalVisible}
                            css={{ width: '240px' }}
                            onChange={(e) =>
                                setUserInEdit({
                                    ...userInEdit,
                                    email: e.target.value,
                                })
                            }
                        />
                    </Descriptions.Item>
                    {addModalVisible && (
                        <Descriptions.Item label="First Name">
                            <Input
                                placeholder="Enter user first name"
                                css={{ width: '240px' }}
                                onChange={(e) =>
                                    setUserInEdit({
                                        ...userInEdit,
                                        firstName: e.target.value,
                                    })
                                }
                            />
                        </Descriptions.Item>
                    )}
                    {addModalVisible && (
                        <Descriptions.Item label="Last Name">
                            <Input
                                placeholder="Enter user last name"
                                css={{ width: '240px' }}
                                onChange={(e) =>
                                    setUserInEdit({
                                        ...userInEdit,
                                        lastName: e.target.value,
                                    })
                                }
                            />
                        </Descriptions.Item>
                    )}
                    <Descriptions.Item label="Timezone">
                        <Select
                            placeholder="Select a timezone"
                            showSearch
                            css={{ width: '240px' }}
                            value={userInEdit.timezone}
                            onChange={(value) => setUserInEdit({ ...userInEdit, timezone: value })}
                        >
                            {AU_TIMEZONES.map((timezone) => (
                                <Option key={timezone} value={timezone}>
                                    {timezone}
                                </Option>
                            ))}
                        </Select>
                    </Descriptions.Item>
                    <Descriptions.Item label="Role">
                        <Radio.Group
                            buttonStyle="solid"
                            value={userInEdit.role}
                            onChange={(e) =>
                                setUserInEdit({
                                    ...userInEdit,
                                    role: e.target.value,
                                })
                            }
                        >
                            {Object.values(ORG_ROLES).map((role) => (
                                <Radio.Button value={role.name}>{role.displayName}</Radio.Button>
                            ))}
                        </Radio.Group>
                    </Descriptions.Item>
                </Descriptions>

                <Divider style={{ margin: '16px 0' }} />

                <img src="/imgs/user-roles.png" alt="User roles" width="75%" />
            </Modal>
        </div>
    );
};

export default AdminUsers;
