/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import API from '@aws-amplify/api';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

// Import Ant Design component
import { Table, Button, Card, PageHeader, message } from 'antd';

// Import stylesheet
import styles from './styles';

const SuperDealers = () => {
    // Initialization
    const [loadingDealers, setLoadingDealers] = useState(false);
    const [dealers, setDealers] = useState([]);

    useEffect(() => {
        // Retrieve all dealers
        const fetchDealersData = async () => {
            setLoadingDealers(true);
            try {
                const data = await API.get('superadmin', '/dealers');
                setDealers(data);
            } catch (error) {
                message.error('Unable to fetch all dealers');
            }
            setLoadingDealers(false);
        };

        fetchDealersData();
    }, []);

    // Configure table columns
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (name, dealer) => (
                <Link to={`/management/dealers/${ dealer.id }`}>
                    {name}
                </Link>
            )
        },
        {
            title: 'Contact Name',
            dataIndex: 'contactName',
            render: value => value || 'N/A',
        },
        {
            title: 'Contact Number',
            dataIndex: 'contactNumber',
            render: value => value || 'N/A',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: value => value ? moment(value).format('MMMM Do YYYY') : 'N/A',
        },
    ];

    return (
        <div>
            <PageHeader
                title="All Dealers"
                subTitle={`${ dealers.length } dealers found`}
                ghost={false}
            />

            <div css={styles.contentWrapper}>
                <Card>
                    <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Link to="/management/dealers/create">
                            <Button type="primary" css={{ marginBottom: '20px' }}>
                                Add Dealer
                            </Button>
                        </Link>
                    </div>

                    <Table
                        columns={columns}
                        loading={loadingDealers}
                        rowKey="id"
                        dataSource={dealers}
                    />
                </Card>
            </div>
        </div>
    );
};

export default SuperDealers;
