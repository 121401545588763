// Configure color settings
const colors = {
    primary: '#2EA234',
    darkGray: '#595959',
    lightGray: '#f5f5f5',
    dustRed: '#ff4d4f',
    sunriseYellow: '#ffec3d',
    adminColor: '#785C98',
    superColor: '#4682b4',
};

export default colors;
