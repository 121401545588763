// Import libraries
import { message } from 'antd';
import API from '@aws-amplify/api';

// Import utilities
import { fetchApiErrorMessage } from '../../utilities/api';

export const deleteVehicles = async (selectedOrg, vehicles) => {
    try {
        const deletePromises = vehicles.map((vehicle) =>
            API.del('organisations', `/${selectedOrg}/vehicles/${vehicle.id}`)
        );
        await Promise.all(deletePromises);

        message.success('Vehicle(s) deleted');
    } catch (error) {
        message.error(fetchApiErrorMessage(error));
    }
};

export const createVehicle = async (selectedOrg, values) => {
    try {
        await API.post('organisations', `/${selectedOrg}/vehicles`, {
            body: {
                rego: values.rego,
                make: values.make,
                model: values.model,
                plate: values.plate,
            },
        });
        message.success('Vehicle created');
    } catch (error) {
        message.error(fetchApiErrorMessage(error));
    }
};

export const updateVehicle = async (selectedOrg, values, vehicleId) => {
    try {
        await API.put('organisations', `/${selectedOrg}/vehicles/${vehicleId}`, { body: values });
        message.success('Vehicle updated');
    } catch (error) {
        message.error(fetchApiErrorMessage(error));
    }
};

export const assignVehicles = async (selectedOrg, vehicles, groupId, orgId) => {
    try {
        await API.put('organisations', `/${selectedOrg}/vehicles`, {
            body: {
                vehicleIds: vehicles.map((vehicle) => vehicle.id),
                groupId: groupId,
                newOrgId: orgId,
            },
        });
        message.success('Vehicle(s) assigned');
    } catch (error) {
        message.error(fetchApiErrorMessage(error));
    }
};

export const updateVehicleAdminLock = async (selectedOrg, vehicleId, locked) => {
    try {
        await API.put('organisations', `/${selectedOrg}/vehicles/${vehicleId}/locked`, {
            body: { locked },
        });
    } catch (error) {
        message.error(fetchApiErrorMessage(error));
    }
};
