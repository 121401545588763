/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import queryString from 'query-string';
import { useState, useContext } from 'react';

// Import Ant Design component
import { Card, Select, Table, Empty, PageHeader } from 'antd';
import {
    CloseCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined
} from '@ant-design/icons';

// Import store
import { OrgsContext } from '../../store/OrgsContext';

// Import utilities
import { useAmplifyOrgGetAPI } from '../../utilities/hooks';

// Import stylesheet
import styles from './styles';

// Import Option component
const { Option } = Select;

// Render column item
const NO_ERROR_COLOR = '#018001';
const ERROR_COLOR = '#CC6666';
const renderContent = hasError => {
    const theme = 'filled';
    const css = {
        fontSize: '16px',
        color: hasError ? ERROR_COLOR : NO_ERROR_COLOR,
    };
    return hasError
        ? (<CloseCircleOutlined theme={theme} css={css} />)
        : (<CheckCircleOutlined theme={theme} css={css} />);
};

// Configure table columns
const columns = [
    {
        title: 'Vehicle Rego',
        dataIndex: 'rego',
        sorter: (a, b) => a.rego > b.rego ? 1 : -1,
    },
    {
        title: 'Storage',
        dataIndex: 'notRec',
        render: renderContent,
        sorter: a => a.notRec ? 1 : -1,
    },
    {
        title: 'GPS',
        dataIndex: 'gpsStatus',
        render: gpsStatus => {
            if (gpsStatus === 0 || gpsStatus === 1) {
                return (
                    <ExclamationCircleOutlined
                        theme="filled"
                        css={{ fontSize: '16px', color: ERROR_COLOR }}
                    />
                );
            }
            return renderContent(gpsStatus < 2);
        },
        sorter: (a, b) => a.gpsStatus > b.gpsStatus ? 1 : -1,
    },
    {
        title: 'Video Loss',
        dataIndex: 'vlossErr',
        render: renderContent,
        sorter: a => a.vlossErr ? 1 : -1,
    },
];

const IssueReports = props => {
    const { filter = '' } = queryString.parse(props.location.search) || {};
    const [filterType, setFilterType] = useState(filter ? filter.split(',') : ['notRec', 'gpsErr', 'vlossErr']);

    // Retrieve currently selected organisation
    const orgsContext = useContext(OrgsContext);
    const { selectedOrg } = orgsContext;

    // Fetch current issue data
    const {
        data: issues,
        loading: loadingIssues,
    } = useAmplifyOrgGetAPI(selectedOrg, '/reports/issues', []);

    return (
        <div>
            <PageHeader
                title="Issue Reports"
                breadcrumb={null}
                ghost={false}
            />

            <div css={styles.contentWrapper}>
                <Card>
                    <div css={{ marginBottom: '30px' }}>
                        <span css={styles.queryLabel}>Filter:</span>
                        <Select
                            mode="multiple"
                            value={filterType}
                            css={styles.queryInput}
                            onChange={val => setFilterType(val)}
                        >
                            <Option value="notRec">Storage Failure</Option>
                            <Option value="gpsErr">GPS Errors</Option>
                            <Option value="vlossErr">Video Loss</Option>
                        </Select>
                    </div>
                    <Table
                        dataSource={issues.filter(issue => {
                            if (issue.gpsStatus < 2 && filterType.indexOf('gpsErr') >= 0) return true;
                            else if (issue.notRec && filterType.indexOf('notRec') >= 0) return true;
                            else if (issue.vlossErr && filterType.indexOf('vlossErr') >= 0) return true;
                            return false;
                        })}
                        columns={columns}
                        rowKey="rego"
                        loading={loadingIssues}
                        locale={{
                            emptyText: (
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="No Issues found"
                                />
                            )
                        }}
                    />
                </Card>
            </div>
        </div>
    );
};

export default IssueReports;
