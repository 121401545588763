/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import moment from 'moment';
import { get } from 'lodash';
// import API from '@aws-amplify/api';
import { useState, useContext, useEffect } from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

// Import Ant Design components
import { Spin, Typography, List, Avatar, Descriptions, Drawer, message } from 'antd';

// Import store
import { OrgsContext } from '../../store/OrgsContext';
import { UserContext } from '../../store/UserContext';

// Import utilities
// import { fetchApiErrorMessage } from '../../utilities/api';

// Import MapBox configurations
import { INITIAL_VIEWPORT } from '../FleetMap/configs';

// Import constants
import {
    // DEFAULT_EVENT_BUTTON_TRIGGER_MAPPINGS,
    DEFAULT_EVENT_G_SENSOR_MAPPINGS,
} from '../../constants/events';

// Import components
import EventMedia from './EventMedia';

// Import stylesheet
import styles from './styles';

// Import additional Ant Design components
const { Title } = Typography;

const EventReport = ({ event, onClose }) => {
    // Retrieve currently selected organisation
    // const orgsContext = useContext(OrgsContext);
    // const { selectedOrg } = orgsContext;

    // Retrieve user's timezone configuration
    const userContext = useContext(UserContext);
    const { timezone } = userContext;

    // Initialisation
    // const [longitude, setLongitude] = useState(INITIAL_VIEWPORT.longitude);
    // const [latitude, setLatitude] = useState(INITIAL_VIEWPORT.latitude);
    // const [event, setEvent] = useState({});
    const [loadingEvent, setLoadingEvent] = useState(false);
    // const [presignedUrls, setPresignedUrls] = useState([]);

    // useEffect(() => {
    //     // Retrieve details of the event
    //     const fetchEventDetails = async () => {
    //         setLoadingEvent(true);
    //         try {
    //             const data = await API.get('organisations', `/${selectedOrg}/events/${eventId}`);
    //             setEvent(data);

    //             // Prepare layers and the initial view state
    //             const { latitude, longitude } = data || {};
    //             if (latitude && longitude) {
    //                 setLongitude(longitude);
    //                 setLatitude(latitude);
    //             }
    //         } catch (error) {
    //             message.error(fetchApiErrorMessage(error));
    //         }
    //         setLoadingEvent(false);
    //     };

    //     if (eventId) fetchEventDetails();
    // }, [selectedOrg, eventId]);

    // useEffect(() => {
    //     const obtainPresignedUrls = async (data) => {
    //         try {
    //             const { presignedUrls = [] } = await API.post('history', '/images/download', {
    //                 body: { imageUrls: data },
    //             });
    //             setPresignedUrls(presignedUrls);
    //         } catch (error) {
    //             message.error('Unable to retrieve event images.');
    //         }
    //     };

    //     const data = get(event, 'data.file', []).sort();
    //     if (data && Array.isArray(data) && data.length > 0) obtainPresignedUrls(data);
    // }, [event]);

    // Prepare event time
    const eventTime =
        event && event.eventTime
            ? moment(event.eventTime).tz(timezone).format('MMM Do YYYY, h:mm:ss a')
            : '';

    // Prepare map container styles
    const mapContainerStyle = {
        width: '100%',
        minHeight: '600px',
    };

    const { lat, lon } = event?.eventLocation || {};
    const latitude = lat ? lat : INITIAL_VIEWPORT.latitude;
    const longitude = lon ? lon : INITIAL_VIEWPORT.longitude;

    return (
        <Drawer
            title={event?.eventName || 'Unknown'}
            placement="right"
            closable
            onClose={onClose}
            open
            width="30%"
            key="event-report-drawer"
        >
            <Spin tip="Loading event report details ..." spinning={loadingEvent}>
                {/* Event Details */}
                <Descriptions column={3} labelStyle={{ fontWeight: 'bold' }}>
                    <Descriptions.Item label="Fleet ID">{event?.rego}</Descriptions.Item>

                    <Descriptions.Item label="Time" span={2}>
                        {eventTime}
                    </Descriptions.Item>

                    {Object.keys(DEFAULT_EVENT_G_SENSOR_MAPPINGS).includes(event?.eventId) && (
                        <div>
                            <Descriptions.Item label="Speed">
                                {event.speed ?? 'N/A'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Intensity">
                                {event?.intensity ?? 'N/A'}
                            </Descriptions.Item>
                        </div>
                    )}
                </Descriptions>

                {/* Event Media */}
                {/* {Object.keys(DEFAULT_EVENT_BUTTON_TRIGGER_MAPPINGS).includes(event?.eventId) && (
                    <EventMedia data={presignedUrls} />
                )} */}

                {/* Event Addresses */}
                <div style={styles.eventAddressesSection}>
                    <Title level={5}>Geocoded Address</Title>
                    {/* <List
                        itemLayout="horizontal"
                        dataSource={get(event, 'addresses', []).slice(0, 1)}
                        renderItem={(address, idx) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Avatar src="/imgs/address-icon.png" />}
                                    // title={`Address ${ idx + 1 }`}
                                    description={address}
                                />
                            </List.Item>
                        )}
                    /> */}
                    <div style={styles.mapView}>
                        <LoadScript
                            googleMapsApiKey={
                                process.env.REACT_APP_LOCAL_ENV === 'prod'
                                    ? 'AIzaSyAlbhVIe3IZjRw_h3FQLBPYUGwxPAkqG4I'
                                    : 'AIzaSyBMSSRkeFefYCmaMVmXjf-2DKl_yQwNjPQ'
                            }
                        >
                            <GoogleMap
                                id="fleetway-map-view"
                                mapContainerStyle={mapContainerStyle}
                                zoom={18}
                                center={{ lat: latitude, lng: longitude }}
                            >
                                <Marker position={{ lat: latitude, lng: longitude }} />
                            </GoogleMap>
                        </LoadScript>
                    </div>
                </div>
            </Spin>
        </Drawer>
    );
};

export default EventReport;
