/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { useContext, useEffect } from 'react';

// Import Ant Design components
import {
    Card, Table, Empty, PageHeader
} from 'antd';

// Import store
import history from '../../store/history';
import { OrgsContext } from '../../store/OrgsContext';

// Import utilities
import { breadcrumbRender } from '../../utilities/breadcrumb';
import { useAmplifyOrgGetAPI } from '../../utilities/hooks';

// Import breadcrumb routes
import routes from './routes';

// Import stylesheet
import styles from './styles';

// Configure table columns
const columns = [
    {
        title: 'Rego',
        dataIndex: 'rego',
        sorter: (a, b) => a.rego > b.rego ? 1 : -1,
    },
    {
        title: 'HDD',
        dataIndex: 'hddErr',
        render: value => value || '-',
        sorter: (a, b) => a.hddErr > b.hddErr ? 1 : -1,
    },
    {
        title: 'SD',
        dataIndex: 'sdErr',
        render: value => value || '-',
        sorter: (a, b) => a.sdErr > b.sdErr ? 1 : -1,
    },
    {
        title: 'GPS',
        dataIndex: 'fixErr',
        render: value => value || '-',
        sorter: (a, b) => a.fixErr > b.fixErr ? 1 : -1,
    },
    {
        title: 'CH1',
        dataIndex: 'ch1Err',
        render: value => value || '-',
        sorter: (a, b) => a.ch1Err > b.ch1Err ? 1 : -1,
    },
    {
        title: 'CH2',
        dataIndex: 'ch2Err',
        render: value => value || '-',
        sorter: (a, b) => a.ch2Err > b.ch2Err ? 1 : -1,
    },
    {
        title: 'CH3',
        dataIndex: 'ch3Err',
        render: value => value || '-',
        sorter: (a, b) => a.ch3Err > b.ch3Err ? 1 : -1,
    },
    {
        title: 'CH4',
        dataIndex: 'ch4Err',
        render: value => value || '-',
        sorter: (a, b) => a.ch4Err > b.ch4Err ? 1 : -1,
    },
    {
        title: 'CH5',
        dataIndex: 'ch5Err',
        render: value => value || '-',
        sorter: (a, b) => a.ch5Err > b.ch5Err ? 1 : -1,
    },
    {
        title: 'CH6',
        dataIndex: 'ch6Err',
        render: value => value || '-',
        sorter: (a, b) => a.ch6Err > b.ch6Err ? 1 : -1,
    },
    {
        title: 'CH7',
        dataIndex: 'ch7Err',
        render: value => value || '-',
        sorter: (a, b) => a.ch7Err > b.ch7Err ? 1 : -1,
    },
    {
        title: 'CH8',
        dataIndex: 'ch8Err',
        render: value => value || '-',
        sorter: (a, b) => a.ch8Err > b.ch8Err ? 1 : -1,
    },
];

const WeeklyReport = props => {
    // Retrieve report id
    const { reportId } = props.match.params;

    // Retrieve currently selected organisation
    const orgsContext = useContext(OrgsContext);
    const { selectedOrg } = orgsContext;

    // Fetch weekly report data
    const {
        data: report,
        loading: loadingReport,
        error,
    } = useAmplifyOrgGetAPI(selectedOrg, `/reports/weekly/${ reportId }`, [], {}, false);

    useEffect(() => {
        if (error) history.push('/reports/weekly');
    }, [error]);

    return (
        <div>
            <PageHeader
                title="Weekly Report"
                breadcrumb={{ routes, itemRender: breadcrumbRender }}
                ghost={false}
            />

            <div css={styles.contentWrapper}>
                <Card>
                    <Table
                        dataSource={report}
                        columns={columns}
                        loading={loadingReport}
                        rowKey="rego"
                        locale={{
                            emptyText: (
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="No issues found"
                                />
                            )
                        }}
                    />
                </Card>
            </div>
        </div>
    );
};

export default WeeklyReport;
