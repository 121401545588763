/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import moment from 'moment';
import API from '@aws-amplify/api';
import { Link } from 'react-router-dom';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { useEffect, useState, useContext, Fragment } from 'react';

// Import Ant Design components
import {
    Card,
    Button,
    Descriptions,
    Table,
    Row,
    Col,
    Modal,
    Alert,
    Input,
    Switch,
    Select,
    Radio,
    Divider,
    message,
} from 'antd';
import { LoginOutlined, LogoutOutlined, EditOutlined } from '@ant-design/icons';

// Import store
import { UserContext } from '../../store/UserContext';

// Import actions
import { addUser } from '../AdminUsers/actions';

// Import constants
import { ORG_ROLES } from '../../constants/orgRoles';
import AU_TIMEZONES from '../../constants/timezones';

// Import components
import OrgPlan from '../../components/OrgPlan';

// Import stylesheet
import styles from './styles';

// Import additional Ant Design components
const { Option } = Select;

const SuperOrg = (props) => {
    // Retrieve organisation id from path parameter
    const { orgId } = props.match.params;

    // Check if current user is an superadmin or dealer
    const userContext = useContext(UserContext);
    const { isSuperAdmin, email } = userContext;

    // Initialization
    const [loadingOrg, setLoadingOrg] = useState(false);
    const [loadingSuborgs, setLoadingSuborgs] = useState([]);
    const [loadingOrgUsers, setLoadingOrgUsers] = useState([]);
    const [updatingPlanStatus, setUpdatingPlanStatus] = useState(false);
    const [resetVisible, setResetVisible] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const [resetPassword, setResetPassword] = useState('');

    const [org, setOrg] = useState({});
    const [suborgs, setSuborgs] = useState([]);
    const [orgUsers, setOrgUsers] = useState([]);
    const [orgDataFetchedAt, setOrgDataFetchedAt] = useState(new Date().getTime());

    const [addModalVisible, setAddModalVisible] = useState(false);
    const [userInEdit, setUserInEdit] = useState({});
    const [editLoading, setEditLoading] = useState(false);
    const [userDataFetchedAt, setUserDataFetchedAt] = useState(new Date().getTime());

    useEffect(() => {
        // Fetch organisation data
        const fetchOrgData = async () => {
            setLoadingOrg(true);
            try {
                const organisation = await API.get('organisations', `/${orgId}`);
                setOrg(organisation);
            } catch (error) {
                message.error('Unable to fetch organisation details');
            }
            setLoadingOrg(false);
        };

        if (orgId) fetchOrgData();
    }, [orgId, orgDataFetchedAt]);

    useEffect(() => {
        // Fetch all suborganisations
        const fetchSuborgData = async () => {
            setLoadingSuborgs(true);
            try {
                const suborganisations = await API.get(
                    'organisations',
                    `/${orgId}/suborganisations`
                );
                setSuborgs(suborganisations);
            } catch (error) {
                message.error('Unable to fetch all suborganisations');
            }
            setLoadingSuborgs(false);
        };

        if (orgId) fetchSuborgData();
    }, [orgId]);

    useEffect(() => {
        // Fetch all users within organisation
        const fetchOrgUsers = async () => {
            setLoadingOrgUsers(true);
            try {
                const users = await API.get('organisations', `/${orgId}/users`);
                setOrgUsers(users);
            } catch (error) {
                message.error('Unable to fetch all organisation users');
            }
            setLoadingOrgUsers(false);
        };

        if (orgId) fetchOrgUsers();
    }, [orgId, userDataFetchedAt]);

    const activatePlan = async () => {
        if (orgId) {
            // Activate plan for organisation and its suborganisations
            setUpdatingPlanStatus(true);
            try {
                await API.put('organisations', `/${orgId}/activate-plan`);
                message.success('Organisation plan activated');

                // Refetch organisation data
                setOrgDataFetchedAt(new Date().getTime());
            } catch (error) {
                message.error('Unable to activate organisation plan');
            }
            setUpdatingPlanStatus(false);
        }
    };

    const deactivatePlan = async () => {
        if (orgId) {
            // Deactivate plan for organisation and its suborganisations
            setUpdatingPlanStatus(true);
            try {
                await API.put('organisations', `/${orgId}/deactivate-plan`);
                message.success('Organisation plan deactivated');

                // Refetch organisation data
                setOrgDataFetchedAt(new Date().getTime());
            } catch (error) {
                message.error('Unable to deactivate organisation plan');
            }
            setUpdatingPlanStatus(false);
        }
    };

    const onNotifEnabledChange = async (checked) => {
        if (orgId) {
            try {
                await API.put('organisations', `/${orgId}/sms-notifications`, {
                    body: {
                        notificationsEnabled: checked,
                    },
                });
                message.success(
                    checked ? 'SMS notifications enabled' : 'SMS notifications disabled'
                );
            } catch (error) {
                message.error('Unable to update organisation notifications configurations');
            }
        }
    };

    const clearResetPassword = () => {
        setResetVisible(false);
        setResetEmail('');
        setResetPassword('');
    };

    const resetUserPassword = async () => {
        if (!resetEmail || !resetPassword) {
            message.error('Missing user email or password');
        } else if (resetPassword.length < 6) {
            message.error('Password must have length greater than or equal to 6');
        } else {
            try {
                const body = { email: resetEmail, password: resetPassword };
                await API.post('superadmin', '/users/reset-password', { body });
                message.success('User password reset');
                clearResetPassword();
            } catch (error) {
                message.error('Unable to reset user password');
            }
        }
    };

    // Configure table columns
    const suborgColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (name, org) => <Link to={`/management/organisations/${org.id}`}>{name}</Link>,
        },
        {
            title: 'Contact Name',
            dataIndex: 'contactName',
            render: (value) => value || 'N/A',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (value) => (value ? moment(value).format('MMM Do YYYY') : 'N/A'),
        },
    ];
    const userColumns = [
        {
            title: 'Email',
            dataIndex: 'email',
            render: (value) => (
                <Button type="link" css={{ padding: 0 }}>
                    {value || 'N/A'}
                </Button>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            render: (_, user) => `${user.firstName} ${user.lastName}`,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            render: (value) => value || 'N/A',
        },
        {
            dataIndex: 'id',
            render: (_, user) => (
                <Button
                    type="link"
                    disabled={user.email === email}
                    css={{ padding: 0 }}
                    onClick={() => {
                        setResetVisible(true);
                        setResetEmail(user.email);
                    }}
                >
                    Reset password
                </Button>
            ),
        },
    ];

    return (
        <Fragment>
            <PageHeaderWrapper
                title="Organisation Details"
                subTitle={orgId}
                // breadcrumb={getBreadcrumb(orgId)}
                extra={
                    org &&
                    org.suborganisations &&
                    org.suborganisations.length > 0 && (
                        <div>{org.suborganisations.length} suborganisations found</div>
                    )
                }
            />

            <div css={styles.contentWrapper}>
                <Card title={org.name} loading={loadingOrg}>
                    <Descriptions column={2} bordered>
                        <Descriptions.Item label="Name">{org.name || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label="Dealer Name">
                            {org.dealerName || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Contact Name">
                            {org.contactName || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Contact Number">
                            {org.contactNumber || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Postal Address">
                            {org.contactAddress || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Latitude, Longitude">
                            {org.latitude || 'N/A'}, {org.longitude || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="ABN">
                            {org.abn || 'Not provided'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Created At">
                            {org.createdAt ? moment(org.createdAt).format('MMMM Do YYYY') : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Current Plan">
                            <OrgPlan plan={org.plan} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Plan Started At">
                            {org.planStartedAt
                                ? moment(org.planStartedAt).format('MMMM Do YYYY')
                                : 'Not started'}
                        </Descriptions.Item>
                        <Descriptions.Item label="SMS Notifications">
                            <Switch
                                defaultChecked={org.notificationsEnabled}
                                onChange={onNotifEnabledChange}
                            />
                        </Descriptions.Item>
                    </Descriptions>

                    <div css={{ display: 'flex', marginTop: '20px' }}>
                        <Link to={`/management/organisations/${orgId}/edit`}>
                            <Button
                                type="primary"
                                icon={<EditOutlined />}
                                css={{ marginRight: '15px' }}
                            >
                                Edit Organisation
                            </Button>
                        </Link>
                        {isSuperAdmin &&
                            (org.planStartedAt ? (
                                <Button
                                    type="primary"
                                    icon={<LoginOutlined />}
                                    onClick={deactivatePlan}
                                    loading={updatingPlanStatus}
                                >
                                    Deactivate Plan
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    icon={<LogoutOutlined />}
                                    onClick={activatePlan}
                                    loading={updatingPlanStatus}
                                >
                                    Activate Plan
                                </Button>
                            ))}
                    </div>
                </Card>

                <Row gutter={24} css={{ marginTop: '30px' }}>
                    <Col span={10}>
                        <Card
                            title="Suborganisations"
                            extra={
                                <Link
                                    to={`/management/organisations/create?parent_org_id=${orgId}&parent_org_name=${org.name}`}
                                >
                                    <Button type="link" disabled={org.parentOrgId !== null}>
                                        Create new
                                    </Button>
                                </Link>
                            }
                        >
                            {org.parentOrgId !== null ? (
                                <Alert
                                    message="Maximum level of two is supported in Fleetway organisation hierarchy. No suborganisations can be created under a suborganisation."
                                    type="info"
                                    showIcon
                                />
                            ) : (
                                <Table
                                    columns={suborgColumns}
                                    loading={loadingSuborgs}
                                    rowKey="id"
                                    dataSource={suborgs}
                                />
                            )}
                        </Card>
                    </Col>

                    <Col span={14}>
                        <Card
                            title="Users"
                            extra={
                                <Button type="link" onClick={() => setAddModalVisible(true)}>
                                    Add new
                                </Button>
                            }
                        >
                            <Table
                                columns={userColumns}
                                loading={loadingOrgUsers}
                                rowKey="id"
                                dataSource={orgUsers}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>

            <Modal
                title="Reset user password"
                open={resetVisible}
                onOk={resetUserPassword}
                onCancel={clearResetPassword}
            >
                <Descriptions column={1}>
                    <Descriptions.Item label="Email">
                        <Input
                            id="email"
                            name="email"
                            value={resetEmail}
                            disabled
                            style={{ width: '200px' }}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="Password">
                        <Input
                            id="password"
                            name="password"
                            value={resetPassword}
                            placeholder="Enter new password"
                            onChange={(e) => setResetPassword(e.target.value)}
                            style={{ width: '200px' }}
                        />
                    </Descriptions.Item>
                </Descriptions>
            </Modal>

            <Modal
                title="Add a new user"
                open={addModalVisible}
                onCancel={() => {
                    setAddModalVisible(false);
                    setUserInEdit({});
                }}
                width={800}
                footer={[
                    <Button
                        key="edit"
                        type="primary"
                        onClick={async () => {
                            setEditLoading(true);
                            await addUser(orgId, userInEdit);
                            setAddModalVisible(false);
                            setEditLoading(false);
                            setUserInEdit({});
                            setUserDataFetchedAt(new Date().getTime());
                        }}
                        loading={editLoading}
                    >
                        Add
                    </Button>,
                    <Button
                        key="cancel"
                        onClick={() => {
                            setAddModalVisible(false);
                            setUserInEdit({});
                        }}
                    >
                        Cancel
                    </Button>,
                ]}
                destroyOnClose
            >
                <Descriptions column={1}>
                    <Descriptions.Item label="Organisation">
                        <b>{org.name}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Email">
                        <Input
                            value={userInEdit.email}
                            placeholder="Enter user email address"
                            css={{ width: '240px' }}
                            onChange={(e) =>
                                setUserInEdit({
                                    ...userInEdit,
                                    email: e.target.value,
                                })
                            }
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="First Name">
                        <Input
                            placeholder="Enter user first name"
                            css={{ width: '240px' }}
                            onChange={(e) =>
                                setUserInEdit({
                                    ...userInEdit,
                                    firstName: e.target.value,
                                })
                            }
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="Last Name">
                        <Input
                            placeholder="Enter user last name"
                            css={{ width: '240px' }}
                            onChange={(e) =>
                                setUserInEdit({
                                    ...userInEdit,
                                    lastName: e.target.value,
                                })
                            }
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="Timezone">
                        <Select
                            placeholder="Select a timezone"
                            showSearch
                            css={{ width: '240px' }}
                            value={userInEdit.timezone}
                            onChange={(value) => setUserInEdit({ ...userInEdit, timezone: value })}
                        >
                            {AU_TIMEZONES.map((timezone) => (
                                <Option key={timezone} value={timezone}>
                                    {timezone}
                                </Option>
                            ))}
                        </Select>
                    </Descriptions.Item>
                    <Descriptions.Item label="Role">
                        <Radio.Group
                            buttonStyle="solid"
                            value={userInEdit.role}
                            onChange={(e) =>
                                setUserInEdit({
                                    ...userInEdit,
                                    role: e.target.value,
                                })
                            }
                        >
                            {Object.values(ORG_ROLES).map((role) => (
                                <Radio.Button value={role.name}>{role.displayName}</Radio.Button>
                            ))}
                        </Radio.Group>
                    </Descriptions.Item>
                </Descriptions>

                <Divider style={{ margin: '16px 0' }} />

                <img src="/imgs/user-roles.png" alt="User roles" width="75%" />
            </Modal>
        </Fragment>
    );
};

export default SuperOrg;
