/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import moment from 'moment';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

// Import Ant Design components
import { Card, Row, Col, Statistic, PageHeader } from 'antd';

// Import Bizcharts
import { Chart, Geom, Axis, Tooltip as BizTooltip } from 'bizcharts';

// Import store
import { OrgsContext } from '../../store/OrgsContext';
import { UserContext } from '../../store/UserContext';

// Import utilities
import { useAmplifyOrgGetAPI } from '../../utilities/hooks';

// Import stylesheet
import styles from './styles';

const Dashboard = () => {
    // Retrieve currently selected organisation
    const orgsContext = useContext(OrgsContext);
    const { selectedOrg } = orgsContext;

    // Retrieve user timezone
    const userContext = useContext(UserContext);
    const { timezone } = userContext;

    // Configure columns
    const columns = {
        hour: {
            alias: 'Hour',
            type: 'time',
            tickCount: 24,
            formatter: (label) => moment.tz(label, timezone).hours(),
        },
        numOfOnlineDevices: { alias: 'Number of Online Devices' },
    };

    // Loading organisation devices
    const { data: status, loading: loadingStatus } = useAmplifyOrgGetAPI(
        selectedOrg,
        '/status',
        {}
    );

    return (
        <div>
            <PageHeader title="G'day Mate" avatar={{ src: 'imgs/avatar.jpeg' }} ghost={false} />

            <div css={styles.contentWrapper}>
                <div css={{ display: 'flex' }}>
                    <Card css={{ width: '30%' }} loading={loadingStatus}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Statistic
                                    title="Online Status"
                                    value={status.onlineNum}
                                    suffix={`/ ${status.totalNum}`}
                                    css={styles.healthy}
                                />
                            </Col>
                            <Col span={12}>
                                <Statistic
                                    title="Health Status"
                                    value={status.healthyNum}
                                    suffix={`/ ${status.totalNum}`}
                                    css={styles.healthy}
                                />
                            </Col>
                        </Row>
                    </Card>

                    <Card css={{ width: '30%', marginLeft: '30px' }} loading={loadingStatus}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Link to="/reports/issues?filter=notRec,gpsErr">
                                    <Statistic
                                        title="Emergency"
                                        value={status.errorNum}
                                        suffix={`/ ${status.totalNum}`}
                                        css={styles.error}
                                    />
                                </Link>
                            </Col>
                            <Col span={12}>
                                <Link to="/reports/issues?filter=vlossErr,gpsErr">
                                    <Statistic
                                        title="Need to check"
                                        value={status.warningNum}
                                        suffix={`/ ${status.totalNum}`}
                                        css={styles.warning}
                                    />
                                </Link>
                            </Col>
                        </Row>
                    </Card>
                </div>

                <Card
                    title="Number of Online Devices (Last 24 Hours)"
                    css={{ width: '80%', marginTop: '30px' }}
                    bodyStyle={!loadingStatus ? { padding: '30px 30px 20px' } : {}}
                    loading={loadingStatus}
                >
                    <Chart
                        width={900}
                        height={300}
                        data={
                            status && status.onlineLogs
                                ? status.onlineLogs.map((log) => {
                                      return {
                                          ...log,
                                          hour: moment(log.time).tz(timezone).toDate(),
                                      };
                                  })
                                : []
                        }
                        scale={columns}
                    >
                        <Axis name="hour" title />
                        <Axis name="numOfOnlineDevices" title />
                        <BizTooltip showTitle={false} />
                        <Geom type="interval" position="hour*numOfOnlineDevices" />
                    </Chart>
                </Card>
            </div>
        </div>
    );
};

export default Dashboard;
