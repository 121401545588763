/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import moment from 'moment';
import { saveAs } from 'file-saver';
import API from '@aws-amplify/api';
import uuid4 from 'uuid/v4';
import { useContext, useState, useEffect } from 'react';

// Import Ant Design components
import {
    Card,
    Table,
    Button,
    Input,
    Badge,
    Tooltip,
    PageHeader,
    DatePicker,
    Dropdown,
    Modal,
    Space,
    Typography,
    Row,
    Col,
    Progress,
    message,
} from 'antd';
import {
    ReloadOutlined,
    LockOutlined,
    UnlockOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';

// Import store
import { OrgsContext } from '../../store/OrgsContext';
import { UserContext } from '../../store/UserContext';

// Import utilities
import { connectRequestHub } from '../../utilities/requestHub';

// Import constants
import { ORG_ROLES } from '../../constants/orgRoles';
import {
    VISIBILITY,
    REQUEST_STATUS,
    DOWNLOAD_STATUS,
    TRANSFER_STATUS,
} from '../../constants/downloads';
import { SocketIOChannels } from '../../constants/requestHub';

// Import stylesheet
import styles from './styles';

// Import additional Ant Design resources
const { confirm } = Modal;
const { Text } = Typography;

// Interval to obtain transfer status
export const STREAM_REQUEST_INTERVAL = 2000;

const Downloads = () => {
    // Initialization
    const [requests, setRequests] = useState([]);
    const [loadingDownloads, setLoadingDownloads] = useState(false);
    const [searchRego, setSearchRego] = useState('');
    const [searchDate, setSearchDate] = useState(null);
    const [dataFetchedAt, setDataFetchedAt] = useState(new Date().getTime());
    const [updatingVisibility, setUpdatingVisibility] = useState(false);

    const [reqHubSocket, setReqHubSocket] = useState(null);
    const [transferStatus, setTransferStatus] = useState({});

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const [_, setRefreshIntervalId] = useState(null);

    // Retrieve currently selected organisation
    const orgContext = useContext(OrgsContext);
    const { selectedOrg, userRole } = orgContext;

    // Retrieve user timezone
    const userContext = useContext(UserContext);
    const { timezone } = userContext;

    useEffect(() => {
        // Establish a websocket connection to request hub
        const connect = async () => {
            const socketIO = await connectRequestHub(selectedOrg);
            setReqHubSocket(socketIO);
        };

        // Connect to request hub if not connected
        if (!reqHubSocket) connect();

        return () => {
            // Disconnect from request hub if connected
            if (reqHubSocket) {
                reqHubSocket.disconnect();
                setReqHubSocket(null);
            }
        };
    }, [reqHubSocket, selectedOrg]);

    useEffect(() => {
        // Request to obtain organisation transfer status
        const getTransferStatus = () => {
            reqHubSocket.emit(SocketIOChannels.TRANSFER_STATUS, { requestId: uuid4() }, (res) => {
                setTransferStatus(res?.orgTransferStatus ?? {});
            });
        };

        if (reqHubSocket) {
            getTransferStatus();
            const interval = setInterval(() => getTransferStatus(), STREAM_REQUEST_INTERVAL);
            setRefreshIntervalId(interval);
        }

        return () => {
            setRefreshIntervalId((current) => {
                clearInterval(current);
                return null;
            });
        };
    }, [reqHubSocket]);

    useEffect(() => {
        const getDownloads = async () => {
            setLoadingDownloads(true);
            try {
                const res = await API.get('history', `/${selectedOrg}/video/download`);
                setRequests(res?.requests ?? []);
                setExpandedRowKeys(
                    res?.requests?.[0]?.requestId ? [res?.requests?.[0]?.requestId] : []
                );
            } catch (error) {
                message.error('Unable to load download requests, please try again later');
            }
            setLoadingDownloads(false);
        };

        if (selectedOrg) getDownloads();
    }, [selectedOrg, dataFetchedAt]);

    const setRequestVisibility = (requestId, visibility) => {
        // Prepare title
        let title;
        switch (visibility) {
            case VISIBILITY.PUBLIC:
                title = 'Do you want to set public access to this request?';
                break;
            case VISIBILITY.ADMIN_ONLY:
                title = 'Do you want to restrict access to administrator only?';
                break;
            case VISIBILITY.DELETED:
                title = 'Do you want to delete this request?';
                break;
            default:
                title = null;
        }

        if (requestId && title) {
            confirm({
                title,
                icon: <ExclamationCircleOutlined />,
                onOk: async () => {
                    setUpdatingVisibility(true);

                    try {
                        // Send a request to update record in the database
                        await API.put('history', `/${selectedOrg}/video/download/${requestId}`, {
                            body: { visibility },
                        });

                        // Update the local data
                        setRequests((records) => {
                            if (visibility === VISIBILITY.DELETED)
                                return records.filter((r) => r.requestId !== requestId);

                            return records.map((r) => {
                                if (r.requestId === requestId) {
                                    return { ...r, requestVisibility: visibility };
                                }
                                return r;
                            });
                        });
                    } catch (error) {
                        message.error('Something went wrong, please try again later');
                    } finally {
                        setUpdatingVisibility(false);
                    }
                },
                confirmLoading: updatingVisibility,
            });
        }
    };

    // Configure table columns
    const columns = [
        {
            title: 'Rego',
            dataIndex: ['requestQuery', 'vehicleRego'],
        },
        {
            title: 'Channel',
            dataIndex: ['requestQuery', 'requestChannels'],
            render: (channels) => channels.sort().join(', '),
        },
        {
            title: 'Start Date',
            dataIndex: ['requestQuery', 'startTime'],
            render: (value) => moment(value).tz(timezone).format('DD/MM/YYYY'),
        },
        {
            title: 'Start Time',
            dataIndex: ['requestQuery', 'startTime'],
            render: (value) => moment(value).tz(timezone).format('h:mm:ss a'),
        },
        {
            title: 'End Time',
            dataIndex: ['requestQuery', 'endTime'],
            render: (value) => moment(value).tz(timezone).format('h:mm:ss a'),
        },
        {
            title: 'Duration',
            dataIndex: 'requestQuery',
            render: (requestQuery) => {
                const { startTime, endTime } = requestQuery || {};
                const diff = moment.duration(moment(endTime).diff(moment(startTime)));
                if (diff.asSeconds() >= 60)
                    return diff.asSeconds() % 60 === 0
                        ? `${diff.asMinutes()}m`
                        : `${diff.asMinutes().toFixed(1)}m`;
                return `${diff.asSeconds()}s`;
            },
        },
        {
            title: 'Requested By',
            dataIndex: 'requestedBy',
            render: (requestedBy) => `${requestedBy.firstName} ${requestedBy.lastName}`,
        },
        {
            title: 'Requested At',
            dataIndex: 'requestedAt',
            render: (value) => moment(value).tz(timezone).format('DD/MM/YYYY, h:mm:ss a'),
        },
        {
            title: 'Status',
            dataIndex: 'requestStatus',
            render: (status, value) => {
                // Check for new status
                // If all downloads are completed, set status to completed
                // If one of downloads failed, set status to failed
                const { downloads } = value || {};
                let newStatus;
                if (
                    downloads.every(
                        ({ downloadId, downloadStatus }) =>
                            downloadStatus === DOWNLOAD_STATUS.COMPLETED ||
                            transferStatus?.[downloadId]?.transferStatus ===
                                TRANSFER_STATUS.COMPLETED
                    )
                ) {
                    newStatus = REQUEST_STATUS.COMPLETED;
                } else if (
                    downloads.some(
                        ({ downloadId, downloadStatus }) =>
                            downloadStatus === DOWNLOAD_STATUS.FAILED ||
                            transferStatus?.[downloadId]?.transferStatus === TRANSFER_STATUS.FAILED
                    )
                ) {
                    newStatus = REQUEST_STATUS.FAILED;
                }

                switch (newStatus ?? status) {
                    case REQUEST_STATUS.INCOMPLETE:
                        return <Badge status="processing" text="Processing" />;
                    case REQUEST_STATUS.COMPLETED:
                        return <Badge status="success" text="Completed" />;
                    case REQUEST_STATUS.FAILED:
                        return <Badge status="error" text="Failed" />;
                    default:
                        return <Badge status="default" text="Unknown" />;
                }
            },
        },
        {
            // title: 'Status',
            dataIndex: 'requestStatus',
            render: (status, value) => {
                // Prepare visibility menu
                const visibilityMenuItems = [
                    {
                        label: 'Delete resource',
                        key: VISIBILITY.DELETED,
                        icon: <DeleteOutlined />,
                    },
                ];
                if (value.requestVisibility === VISIBILITY.ADMIN_ONLY) {
                    visibilityMenuItems.unshift({
                        label: 'Allow Public access',
                        key: VISIBILITY.PUBLIC,
                        icon: <UnlockOutlined />,
                    });
                }
                if (value.requestVisibility === VISIBILITY.PUBLIC) {
                    visibilityMenuItems.unshift({
                        label: 'Restricted to Admin only',
                        key: VISIBILITY.ADMIN_ONLY,
                        icon: <LockOutlined />,
                    });
                }

                return userRole !== ORG_ROLES.ADMIN.name ? (
                    <Button
                        onClick={() => {
                            setExpandedRowKeys((keys) => {
                                if (keys.includes(value.requestId)) {
                                    return keys.filter((key) => key !== value.requestId);
                                } else {
                                    return [...keys, value.requestId];
                                }
                            });
                        }}
                        type={expandedRowKeys.includes(value.requestId) ? 'default' : 'primary'}
                    >
                        {expandedRowKeys.includes(value.requestId) ? 'Hide' : 'Show'}
                    </Button>
                ) : (
                    <Dropdown.Button
                        menu={{
                            items: visibilityMenuItems,
                            onClick: (e) => setRequestVisibility(value.requestId, e.key),
                        }}
                        onClick={() => {
                            setExpandedRowKeys((keys) => {
                                if (keys.includes(value.requestId)) {
                                    return keys.filter((key) => key !== value.requestId);
                                } else {
                                    return [...keys, value.requestId];
                                }
                            });
                        }}
                        type={expandedRowKeys.includes(value.requestId) ? 'default' : 'primary'}
                        placement="bottomLeft"
                        icon={
                            value.requestVisibility === VISIBILITY.ADMIN_ONLY ? (
                                <Tooltip title="Visible to administrator only">
                                    <LockOutlined />
                                </Tooltip>
                            ) : undefined
                        }
                    >
                        {expandedRowKeys.includes(value.requestId) ? 'Hide' : 'Show'}
                    </Dropdown.Button>
                );
            },
        },
    ];

    const getDownloadStatus = (download, filename) => {
        let { downloadId, downloadStatus, downloadStatusCode } = download || {};

        // Extract values from transfer status
        const {
            transferStatus: ts,
            transferProgress = 0,
            transferStatusCode,
        } = transferStatus?.[downloadId] || {};

        // Show a progress bar if device is uploading
        if (ts === TRANSFER_STATUS.UPLOADING) downloadStatus = DOWNLOAD_STATUS.IN_PROGRESS;
        // Update the download completion status
        else if (ts === TRANSFER_STATUS.COMPLETED) downloadStatus = DOWNLOAD_STATUS.COMPLETED;
        // Update the failure status
        else if (ts === TRANSFER_STATUS.RETRYING || ts === TRANSFER_STATUS.FAILED) {
            downloadStatus = ts;
            downloadStatusCode = transferStatusCode ?? downloadStatusCode;
        }

        switch (downloadStatus) {
            case DOWNLOAD_STATUS.PENDING:
                return <Badge status="default" text="Pending" />;
            case DOWNLOAD_STATUS.IN_PROGRESS:
                return <Progress percent={transferProgress} style={{ width: '150px' }} />;
            case DOWNLOAD_STATUS.COMPLETED:
                return (
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => downloadVideo(downloadId, filename)}
                    >
                        Download
                    </Button>
                );
            case DOWNLOAD_STATUS.RETRYING:
                return <Badge status="warning" text="Retrying" />;
            case DOWNLOAD_STATUS.FAILED:
                let errorMessage = 'Failed';
                switch (parseInt(downloadStatusCode, 10)) {
                    case 5400:
                        errorMessage = 'Transfer error';
                        break;
                    case 5404:
                        errorMessage = 'No footage found';
                        break;
                    case 5410:
                        errorMessage = 'Request expired';
                        break;
                    default:
                        break;
                }
                return <Badge status="error" text={errorMessage} />;
            default:
                return <Badge status="default" text="Unknown" />;
        }
    };

    const downloadVideo = (downloadId, filename) => {
        message.info('Preparing ...');
        reqHubSocket.emit(
            SocketIOChannels.DOWNLOAD_URL,
            { downloadId },
            async ({ downloadUrl, errorMessage }) => {
                if (downloadUrl) {
                    message.success('Downloading ...');
                    const matches = downloadUrl.match(/\/([^\/]+\.avi)\?/);
                    const filename = matches?.[1] ?? `${filename}.avi`;
                    await saveAs(downloadUrl, decodeURIComponent(filename));
                } else if (errorMessage) {
                    message.error(errorMessage);
                }
            }
        );
    };

    return (
        <div>
            <PageHeader
                title="Download Requests"
                subTitle={`${requests.length} results found`}
                breadcrumb={null}
                ghost={false}
                extra={[]}
            />

            <div css={styles.contentWrapper}>
                <Card>
                    <div css={styles.controlPanel}>
                        {/** Query panel */}
                        <div css={styles.queryPanel}>
                            <span css={styles.queryLabel}>Search Rego:</span>
                            <Input
                                placeholder="Enter vehicle rego"
                                value={searchRego}
                                onChange={(e) => setSearchRego(e.target.value)}
                                css={styles.queryInput}
                            />
                            <span css={styles.queryLabel}>Search Date:</span>
                            <DatePicker
                                value={searchDate}
                                onChange={(date) => setSearchDate(date)}
                                css={styles.queryInput}
                            />
                            <Button
                                onClick={() => {
                                    setSearchRego('');
                                    setSearchDate(null);
                                }}
                            >
                                Clear
                            </Button>
                        </div>

                        <Tooltip title="Refresh" key="refresh" placement="bottom">
                            <Button
                                key="refresh"
                                shape="circle"
                                icon={<ReloadOutlined />}
                                onClick={() => setDataFetchedAt(new Date().getTime())}
                                type="primary"
                            />
                        </Tooltip>
                    </div>

                    <Table
                        columns={columns}
                        loading={loadingDownloads}
                        rowKey="requestId"
                        dataSource={requests.filter(
                            (record) =>
                                record?.requestQuery?.vehicleRego
                                    .toLowerCase()
                                    .includes(searchRego.toLowerCase()) &&
                                (searchDate === null ||
                                    searchDate.startOf('day').valueOf() ===
                                        moment(record?.requestQuery?.startTime)
                                            .startOf('day')
                                            .valueOf())
                        )}
                        expandable={{
                            expandedRowKeys: expandedRowKeys,
                            showExpandColumn: false,
                            expandedRowRender: ({ downloads, requestQuery }) => (
                                <Row gutter={[16, 16]} style={{ padding: '0 50px' }}>
                                    {downloads
                                        .sort((a, b) => a.channel - b.channel)
                                        .map((download) => {
                                            // Extract values from download
                                            const { downloadId, channel } = download;

                                            return (
                                                <Col span={6} key={downloadId}>
                                                    <Space key={downloadId} size="large">
                                                        <Text>CH {channel}</Text>
                                                        {getDownloadStatus(
                                                            download,
                                                            moment(requestQuery.startTime).valueOf()
                                                        )}
                                                    </Space>
                                                </Col>
                                            );
                                        })}
                                </Row>
                            ),
                        }}
                    />
                </Card>
            </div>
        </div>
    );
};

export default Downloads;
