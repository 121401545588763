/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import API from '@aws-amplify/api';
import moment from 'moment-timezone';
import { find } from 'lodash';
import { useState, useContext, useRef, useEffect, useMemo } from 'react';

// Import Ant Design component
import {
    Card,
    Space,
    Table,
    DatePicker,
    PageHeader,
    Typography,
    Empty,
    Button,
    Select,
    message,
} from 'antd';

// Import contexts
import { OrgsContext } from '../../store/OrgsContext';
import { UserContext } from '../../store/UserContext';

// Import components
import EventReport from '../EventReport';

// Import utilities
import { fetchApiErrorMessage } from '../../utilities/api';
import { useAmplifyOrgGetAPI } from '../../utilities/hooks';
import getColumnSearchProps from '../../utilities/getColumnSearchProps';

// Import constants
import {
    DEFAULT_EVENT_BUTTON_TRIGGER_MAPPINGS,
    DEFAULT_EVENT_G_SENSOR_MAPPINGS,
    DEFAULT_EVENT_MAPPINGS,
} from '../../constants/events';

// Import stylesheet
import styles from './styles';

// Import additional Ant design components
const { Option, OptGroup } = Select;
const { Text } = Typography;

const EventReports = () => {
    // Retrieve currently selected organisation
    const orgsContext = useContext(OrgsContext);
    const { orgs, selectedOrg } = orgsContext;

    // Retrieve user's timezone configuration
    const userContext = useContext(UserContext);
    const { timezone } = userContext;

    // Find the organisation event settings
    const currentOrg = find(orgs, { id: selectedOrg });
    const { gsensorEventsEnabled, triggerEventsEnabled } = currentOrg || {};

    // Initialisation
    const [events, setEvents] = useState(null);
    const [loadingEvents, setLoadingEvents] = useState(false);
    const [filterDate, setFilterDate] = useState(moment());
    const [filterVehicle, setFilterVehicle] = useState();
    const [filterEvents, setFilterEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState();

    // Prepare rego filters
    const [regoFilter, setRegoFilter] = useState('');
    const searchInputRef = useRef();

    // List org vehicles
    const { data: vehicles, loading: loadingVehicles } = useAmplifyOrgGetAPI(
        selectedOrg,
        '/vehicles',
        []
    );

    // Load event aliases
    const { data: aliases = {}, loading: loadingAliases } = useAmplifyOrgGetAPI(
        selectedOrg,
        '/events/aliases',
        []
    );
    const enabledEventIds = useMemo(
        () =>
            Object.entries(aliases).reduce(
                (acc, alias) => (alias[1] ? [...acc, alias[0]] : acc),
                []
            ),
        [aliases]
    );

    useEffect(() => {
        setFilterVehicle(undefined);
        setEvents(null);
    }, [selectedOrg]);

    useEffect(() => {
        if (enabledEventIds.length > 0) setFilterEvents(enabledEventIds);
    }, [enabledEventIds]);

    const queryOrgEvents = async () => {
        setLoadingEvents(true);

        try {
            const reqEvents = filterEvents.map((key) => DEFAULT_EVENT_MAPPINGS[key]);
            const data = await API.post('organisations', `/${selectedOrg}/events`, {
                body: filterDate
                    ? {
                          from_date: Math.floor(filterDate.startOf('day').valueOf()),
                          to_date: Math.ceil(filterDate.endOf('day').valueOf()),
                          vehicleId: filterVehicle,
                          ...(reqEvents.length > 0 ? { events: reqEvents } : {}),
                      }
                    : {},
            });
            setEvents(data);
        } catch (error) {
            message.error(fetchApiErrorMessage(error));
        }

        setLoadingEvents(false);
    };

    // Prepare table columns
    const columns = [
        {
            title: 'Vehicle Rego',
            dataIndex: 'rego',
            ...getColumnSearchProps(
                'rego',
                searchInputRef,
                regoFilter,
                setRegoFilter,
                null,
                (event) => setSelectedEvent(event)
            ),
        },
        {
            title: 'Event Name',
            dataIndex: 'eventId',
            render: (value) => aliases[value] || value,
        },
        {
            title: 'Event Time',
            dataIndex: 'eventTime',
            render: (value) => moment(value).tz(timezone).format('MMM Do YYYY, h:mm:ss a'),
        },
        {
            title: 'Speed',
            dataIndex: 'speed',
        },
    ];

    // Prepare the empty text
    const emptyText = (
        <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
                events
                    ? `No events found on ${
                          filterDate ? filterDate.format('MMMM Do YYYY') : 'the current date'
                      }`
                    : 'Please update your filters above and click Query'
            }
        />
    );

    return (
        <div>
            <PageHeader
                title="Event Reports"
                ghost={false}
                extra={events ? `Total ${events.length} events found` : undefined}
                css={{ '.ant-page-header-heading': { alignItems: 'center' } }}
            />

            <div style={styles.contentWrapper}>
                <Card>
                    {/* Filter section */}
                    <Space size="large" style={styles.filterSection}>
                        {/* Date filter */}
                        <Space>
                            <Text>Select Date:</Text>
                            <DatePicker
                                value={filterDate}
                                onChange={(date) => setFilterDate(date)}
                                placeholder="Please select a date"
                                style={styles.datePicker}
                                disabled={loadingEvents}
                            />
                        </Space>

                        {/* Vehicle filter */}
                        <Space>
                            <Text>Select Vehicle:</Text>
                            <Select
                                allowClear
                                value={filterVehicle}
                                placeholder="Please select a vehicle"
                                onChange={(vehicle) => setFilterVehicle(vehicle)}
                                style={styles.vehicleSelection}
                                loading={loadingVehicles}
                            >
                                {vehicles.map((vehicle) => (
                                    <Option key={vehicle.id} value={vehicle.id}>
                                        {vehicle.rego}
                                    </Option>
                                ))}
                            </Select>
                        </Space>

                        {/* Event filter */}
                        <Space>
                            <Text>Select Events:</Text>
                            <Select
                                mode="multiple"
                                allowClear
                                value={filterEvents}
                                placeholder="Please select events"
                                onChange={(events) => setFilterEvents(events)}
                                style={styles.eventSelections}
                                loading={loadingAliases}
                            >
                                {triggerEventsEnabled ? (
                                    <OptGroup label="Button Triggers">
                                        {Object.keys(DEFAULT_EVENT_BUTTON_TRIGGER_MAPPINGS)
                                            .filter((id) => enabledEventIds.includes(id))
                                            .map((enabledEventId) => (
                                                <Option key={enabledEventId} value={enabledEventId}>
                                                    {aliases[enabledEventId]}
                                                </Option>
                                            ))}
                                    </OptGroup>
                                ) : null}

                                {gsensorEventsEnabled ? (
                                    <OptGroup label="G-Sensors">
                                        {Object.keys(DEFAULT_EVENT_G_SENSOR_MAPPINGS)
                                            .filter((id) => enabledEventIds.includes(id))
                                            .map((enabledEventId) => (
                                                <Option key={enabledEventId} value={enabledEventId}>
                                                    {aliases[enabledEventId]}
                                                </Option>
                                            ))}
                                    </OptGroup>
                                ) : null}
                            </Select>
                        </Space>

                        {/* Action button */}
                        <Button
                            type="primary"
                            onClick={queryOrgEvents}
                            disabled={!filterVehicle || filterEvents.length === 0}
                        >
                            Query
                        </Button>
                    </Space>

                    {/* Data section */}
                    <Table
                        dataSource={events}
                        columns={columns}
                        loading={{
                            tip: 'Loading all events, this process may take some time ...',
                            spinning: loadingEvents,
                        }}
                        rowKey="key"
                        locale={{ emptyText }}
                    />
                </Card>
            </div>

            {/* Event report drawer */}
            {selectedEvent && (
                <EventReport event={selectedEvent} onClose={() => setSelectedEvent(undefined)} />
            )}
        </div>
    );
};

export default EventReports;
