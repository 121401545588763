// Import library
import { css } from '@emotion/core';

// Import preset
import { colors } from '../../../../styles/presets';

const styles = {
    userDropdown: css({
        '.ant-dropdown-menu-item': {
            i: { marginRight: '10px' },
        },
        '.ant-dropdown-menu-item-group-title': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            color: 'rgba(0, 0, 0, 0.65)',
            fontWeight: 500,
        },
        cursor: 'pointer',
        minWidth: '200px',
        top: '25px',
    }),
    userInfo: css({
        backgroundColor: colors.primary,
    }),
};

export default styles;
