export const ORG_ROLES = {
    ADMIN: {
        name: 'admin',
        displayName: 'Admin',
    },
    MANAGER: {
        name: 'manager',
        displayName: 'Manager',
    },
    COUNCIL: {
        name: 'council',
        displayName: 'Analyst',
    },
    VIEWER: {
        name: 'viewer',
        displayName: 'Associate',
    },
    GUEST: {
        name: 'guest',
        displayName: 'Guest',
    },
    ARCHIVIST: {
        name: 'archivist',
        displayName: 'Archivist',
    },
    OPERATOR: {
        name: 'operator',
        displayName: 'Support',
    },
};
