// Import colors
import colors from './colors';

// Export presets
export { colors };
export const message = {
    top: 70,
    duration: 3,
    maxCount: 3,
};
export const content = {
    maxWidth: '1200px',
    marginTop: '35px',
    marginRight: 'auto',
    marginBottom: '50px',
    marginLeft: 'auto',
};
