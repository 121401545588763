/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import MapboxClient from 'mapbox';
import API from '@aws-amplify/api';
import { useFormik } from 'formik';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { useEffect, useState, Fragment } from 'react';

// Import Ant Design components
import {
    Card, Input, Button, Select, Descriptions,
    AutoComplete, message
} from 'antd';

// Import breadcrumb
import getBreadcrumb from '../breadcrumb';

// Import stylesheet
import styles from '../styles';

// Import Option component
const { Option } = Select;

// Define constants
const SEARCH_DELAY_INTERVAL = 500;

const SuperOrgEdit = props => {
    // Retrieve organisation id from path parameter
    const { orgId } = props.match.params;

    // Retrieve parent organisation id and name from query parameters
    const {
        parent_org_id: parentOrgId, parent_org_name: parentOrgName,
    } = queryString.parse(props.location.search) || {};

    // Initialization
    const [loadingOrg, setLoadingOrg] = useState(false);
    const [updatingOrg, setUpdatingOrg] = useState(false);
    const [org, setOrg] = useState(undefined);
    const [timer, setTimer] = useState(null);
    const [addresses, setAddresses] = useState([]);

    const history = useHistory();

    // Initialize a new Mapbox client
    const mapboxClient = new MapboxClient(process.env.REACT_APP_MAPBOX_ACCESS_TOKEN);

    useEffect(() => {
        // Fetch organisation data
        const fetchOrgData = async () => {
            setLoadingOrg(true);
            try {
                const organisation = await API.get('organisations', `/${ orgId }`);
                setOrg(organisation);
            } catch (error) {
                message.error('Unable to fetch organisation details');
            }
            setLoadingOrg(false);
        };

        if(orgId) fetchOrgData();
    }, [orgId]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            // New organisation details
            name: org ? org.name : '',
            shortName: org ? org.shortName : '',
            contactName: org ? org.contactName : '',
            contactNumber: org ? org.contactNumber : '',
            contactAddress: org ? org.contactAddress : '',
            longitude: org ? org.longitude : '',
            latitude: org ? org.latitude : '',
            abn: org ? org.abn : '',
            plan: org ? org.plan : 'noPlan',
            parentOrgId,

            // Admin user information on organisation creation
            userEmail: '',
            userFirstName: '',
            userLastName: '',
        },
        onSubmit: async values => {
            setUpdatingOrg(true);
            const {
                name, shortName = '', contactName, contactNumber, contactAddress,
                longitude, latitude, abn, plan, parentOrgId,
                email, firstName, lastName,
            } = values || {};

            if (shortName.indexOf(' ') >= 0) {
                setUpdatingOrg(false);
                message.error('Please remove space(s) in the organisation short name');
            } else if (orgId) {
                try {
                    // Update organisation details
                    const body = {
                        name, shortName, contactName, contactNumber, contactAddress,
                        abn, longitude, latitude,
                    };
                    if (plan !== 'noPlan') body.plan = plan;
                    await API.put('organisations', `/${ orgId }`, { body });
                    message.success('Organisation updated successfully');
                    history.push(`/management/organisations/${ orgId }`);
                } catch (error) {
                    setUpdatingOrg(false);
                    message.error('Unable to update organisation');
                }
            } else {
                try {
                    // Create a new organisation
                    const body = {
                        name, shortName, contactName, contactNumber, contactAddress,
                        abn, longitude, latitude, parentOrgId,
                        adminUser: { email, firstName, lastName },
                    };
                    if (plan !== 'noPlan') body.plan = plan;
                    await API.post('organisations', '', { body });
                    message.success('Organisation created');
                    history.push('/management/organisations');
                } catch (error) {
                    setUpdatingOrg(false);
                    let errMsg = 'Unable to create a new organisation';
                    if (error.response.status === 400) {
                        ({ error: errMsg } = error.response.data || {});
                    }
                    message.error(errMsg);
                }
            }
        },
    });

    const handleAddressSearch = userInputData => {
        clearTimeout(timer);
        setTimer(setTimeout(() => {
            // Handle Mapbox Geocoding Search here
            if (userInputData.length > 3) {
                // Get geolocations
                mapboxClient.geocodeForward(userInputData, { country: 'AU' }, ((err, res) => {
                    if (res) {
                        const source = [];
                        res.features.forEach(feature => source.push({
                            coordinates: feature.geometry.coordinates,
                            value: feature.place_name.toString(),
                        }));
                        setAddresses(source);
                    }
                }));
            }
        }, SEARCH_DELAY_INTERVAL));
    };

    const onAddressSelect = (value, option) => {
        const { coordinates } = option || {};
        const [longitude, latitude] = coordinates;
        formik.setFieldValue('contactAddress', value);
        formik.setFieldValue('longitude', longitude);
        formik.setFieldValue('latitude', latitude);
    };

    return (
        <Fragment>
            <PageHeaderWrapper
                title={orgId ? 'Update Organisation' : 'Create Organisation'}
                subTitle={orgId}
                breadcrumb={getBreadcrumb(orgId, orgId ? 'Edit' : 'Create')}
            />

            <div css={styles.contentWrapper}>
                <Card loading={loadingOrg}>
                    <form onSubmit={formik.handleSubmit}>
                        <Descriptions title="Organisation" bordered column={2}>
                            <Descriptions.Item label="Name">
                                <Input
                                    id="name"
                                    name="name"
                                    placeholder="Enter organisation name"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    style={{ width: '200px' }}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Short Name">
                                <Input
                                    id="shortName"
                                    name="shortName"
                                    placeholder="Enter organisation short name"
                                    onChange={formik.handleChange}
                                    value={formik.values.shortName}
                                    style={{ width: '200px' }}
                                    disabled={orgId !== undefined}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Contact Name">
                                <Input
                                    id="contactName"
                                    name="contactName"
                                    placeholder="Enter organisation contact name"
                                    onChange={formik.handleChange}
                                    value={formik.values.contactName}
                                    style={{ width: '200px' }}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Contact Number">
                                <Input
                                    id="contactNumber"
                                    name="contactNumber"
                                    placeholder="Enter organisation contact number"
                                    onChange={formik.handleChange}
                                    value={formik.values.contactNumber}
                                    style={{ width: '200px' }}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="ABN">
                                <Input
                                    id="abn"
                                    name="abn"
                                    placeholder="Enter ABN"
                                    onChange={formik.handleChange}
                                    value={formik.values.abn}
                                    style={{ width: '200px' }}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Plan">
                                <Select
                                    value={formik.values.plan}
                                    onChange={value => formik.setFieldValue('plan', value)}
                                    style={{ width: '200px' }}
                                >
                                    <Option value="standard">Standard Plan</Option>
                                    <Option value="premium">Premium Plan</Option>
                                    <Option value="noPlan">No Plan</Option>
                                </Select>
                            </Descriptions.Item>
                            <Descriptions.Item label="Depot Center Address" span={2}>
                                <AutoComplete
                                    options={addresses}
                                    css={{ width: '500px' }}
                                    onSelect={onAddressSelect}
                                    onSearch={handleAddressSearch}
                                    placeholder="Enter depot center address"
                                    allowClear
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Depot Center Latitude">
                                {formik.values.latitude || 'N/A'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Depot Center Longitude">
                                {formik.values.longitude || 'N/A'}
                            </Descriptions.Item>
                            {parentOrgId && parentOrgName && (
                                <Descriptions.Item label="Parent Organisation">
                                    <Input
                                        disabled
                                        value={parentOrgName}
                                        style={{ width: '200px' }}
                                    />
                                </Descriptions.Item>
                            )}
                        </Descriptions>

                        {!orgId && (
                            <Descriptions title="Admin User" bordered column={2} css={{ marginTop: '30px' }}>
                                <Descriptions.Item label="Email">
                                    <Input
                                        id="email"
                                        name="email"
                                        placeholder="Enter admin user email"
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                        style={{ width: '200px' }}
                                    />
                                </Descriptions.Item>

                                <Descriptions.Item label="First Name">
                                    <Input
                                        id="firstName"
                                        name="firstName"
                                        placeholder="Enter admin user first name"
                                        onChange={formik.handleChange}
                                        value={formik.values.firstName}
                                        style={{ width: '200px' }}
                                    />
                                </Descriptions.Item>

                                <Descriptions.Item label="Last Name">
                                    <Input
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Enter admin user last name"
                                        onChange={formik.handleChange}
                                        value={formik.values.lastName}
                                        style={{ width: '200px' }}
                                    />
                                </Descriptions.Item>
                            </Descriptions>
                        )}

                        <div css={{ display: 'flex', marginTop: '30px' }}>
                            <Button type="primary" htmlType="submit" loading={updatingOrg}>
                                {orgId ? 'Update' : 'Create'}
                            </Button>
                            <Button
                                css={{ marginLeft: '15px' }}
                                onClick={() => {
                                    history.push(orgId
                                        ? `/management/organisations/${ orgId }`
                                        : '/management/organisations');
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </Card>
            </div>
        </Fragment>
    );
};

export default SuperOrgEdit;
