/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';

// Import Ant Design components
import { Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

// Prepare stylesheet
const styles = {
    nameFilterDropdown: {
        padding: '8px',
    },
    nameFilterButton: {
        width: '90px',
        height: '30px',
    },
    nameFilterInput: {
        width: '188px',
        marginBottom: '12px',
        display: 'block',
    },
};

const getColumnSearchProps = (
    dataIndex, searchInputRef, filter, setFilter, getLink, onClick
) => {
    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setFilter(selectedKeys[0]);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setFilter('');
    };

    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={styles.nameFilterDropdown}>
                <Input
                    ref={searchInputRef}
                    placeholder={`Search ${ dataIndex }`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={styles.nameFilterInput}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={styles.nameFilterButton}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={styles.nameFilterButton}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (filterValue, record) => record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(filterValue.toLowerCase()) : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) setTimeout(() => searchInputRef.current.select(), 100);
        },
        render: (value, item) => {
            if (!getLink) {
                return (
                    <Button type={onClick ? 'link' : 'text'} onClick={() => onClick(item)}>
                        {filter ? (
                            <Highlighter
                                highlightStyle={styles.highlighter}
                                searchWords={[filter]}
                                autoEscape
                                textToHighlight={value}
                            />
                        ) : value}
                    </Button>
                );
            }

            return (
                <Link to={getLink(item)}>
                    {filter
                        ? (
                            <Highlighter
                                highlightStyle={styles.highlighter}
                                searchWords={[filter]}
                                autoEscape
                                textToHighlight={value}
                            />
                        ) : value
                    }
                </Link>
            );
        },
    };
};

export default getColumnSearchProps;
