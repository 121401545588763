// Configure request hub host and protocol
let host = 'http://localhost:8081';
if (process.env.REACT_APP_LOCAL_ENV === 'prod') {
    host = 'wss://request-hub.fleetway.io';
} else if (process.env.REACT_APP_LOCAL_ENV === 'dev') {
    host = 'wss://request-hub-dev.fleetway.io';
}

// Request hub url
export const REQUEST_HUB_HOST = host;

// One-time-message action codes
export const OTMActionCodes = {
    FETCH_SETTINGS: 1000,
    STREAM_STATUS: 1001, // 2.2.2
    STREAM_IMAGES: 1002, // 2.2.3
    OBTAIN_HISTORY_IMAGES: 1003, // 2.2.4
    GET_DEVICE_CONFIGS: 1004, // 2.2.5
    SET_DEVICE_CONFIGS: 1005, // 2.2.6
    REQUEST_VIDEO: 1006, // 2.2.7
    UPGRADE_FIRMWARE: 1007, // 2.2.8
    SEND_COMMAND: 1008, // 2.2.9
    REQUEST_VIDEO_UPLOAD: 1009,
};

// Socket.io channels
export const SocketIOChannels = {
    REQUEST: 'request', // For sending and receiving OTM requests
    TRANSFER_STATUS: 'transfer-status', // For obtaining organisation file transfer status and device queue size
    DOWNLOAD_URL: 'download-url', // Request to obtain a signed GCS url
};

// Get topic for live streaming status or images
export const getLiveStreamingTopic = (deviceId, actionCode) => {
    // Prepare suffix for topic name
    let suffix;
    switch (actionCode) {
        case OTMActionCodes.STREAM_STATUS:
            suffix = 'status';
            break;
        case OTMActionCodes.STREAM_IMAGES:
            suffix = 'images';
            break;
        default:
            suffix = 'unknown';
            break;
    }

    return `fleetway-live-streaming:${deviceId}:${suffix}`;
};
