/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { useState } from 'react';
import { GoogleMap, Marker, StreetViewPanorama, LoadScript } from '@react-google-maps/api';

// Import Ant Design components
import { Tabs } from 'antd';

const GoogleMapView = (props) => {
    // Extract values from props
    const { lat, lng } = props;

    // Initialisation
    const [currentTab, setCurrentTab] = useState('mapView');

    // Prepare map container styles
    const mapContainerStyle = {
        height: '100%',
        width: '100%',
        minHeight: '620px',
    };

    return (
        <LoadScript
            googleMapsApiKey={
                process.env.REACT_APP_LOCAL_ENV === 'prod'
                    ? 'AIzaSyAlbhVIe3IZjRw_h3FQLBPYUGwxPAkqG4I'
                    : 'AIzaSyBMSSRkeFefYCmaMVmXjf-2DKl_yQwNjPQ'
            }
        >
            <Tabs
                activeKey={currentTab}
                onChange={(key) => setCurrentTab(key)}
                items={[
                    {
                        key: 'mapView',
                        label: 'Map View',
                        children: (
                            <GoogleMap
                                id="fleetway-map-view"
                                mapContainerStyle={mapContainerStyle}
                                zoom={18}
                                center={{ lat, lng }}
                            >
                                <Marker position={{ lat, lng }} />
                            </GoogleMap>
                        ),
                    },
                    {
                        key: 'streetView',
                        label: 'Street View',
                        children: (
                            <GoogleMap
                                id="fleetway-street-map"
                                mapContainerStyle={mapContainerStyle}
                                center={{ lat, lng }}
                                options={{
                                    disableDefaultUI: true,
                                    visible: false,
                                }}
                            >
                                <StreetViewPanorama
                                    position={{ lat, lng }}
                                    options={{
                                        disableDefaultUI: true,
                                        visible: true,
                                        linksControl: false,
                                        panControl: false,
                                        enableCloseButton: false,
                                    }}
                                />
                            </GoogleMap>
                        ),
                    },
                ]}
                css={{
                    '.ant-tabs-tab': { padding: '4.5px 0' },
                    '.ant-tabs-nav-wrap': { justifyContent: 'flex-end' },
                }}
            />
        </LoadScript>
    );
};

export default GoogleMapView;
