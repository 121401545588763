/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import moment from 'moment';
import API from '@aws-amplify/api';
import { Link } from 'react-router-dom';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { useEffect, useState, Fragment } from 'react';

// Import Ant Design components
import {
    Card, Button, Descriptions, Table,
    Row, Col, Tooltip, message
} from 'antd';

// Import breadcrumb
import getBreadcrumb from './breadcrumb';

// Import stylesheet
import styles from './styles';

const SuperDealer = props => {
    // Retrieve dealer id from path parameter
    const { dealerId } = props.match.params;

    // Initialization
    const [loadingDealer, setLoadingDealer] = useState(false);
    const [loadingDealerOrgs, setLoadingDealerOrgs] = useState([]);
    const [loadingDealerUsers, setLoadingDealerUsers] = useState([]);

    const [dealer, setDealer] = useState({});
    const [dealerOrgs, setDealerOrgs] = useState([]);
    const [dealerUsers, setDealerUsers] = useState([]);

    useEffect(() => {
        const fetchDealerData = async () => {
            setLoadingDealer(true);
            try {
                const data = await API.get('superadmin', `/dealers/${ dealerId }`);
                setDealer(data);
            } catch (error) {
                message.error('Unable to fetch dealer details');
            }
            setLoadingDealer(false);
        };

        const fetchDealerOrgs = async () => {
            setLoadingDealerOrgs(true);
            try {
                const data = await API.get('superadmin', `/dealers/${ dealerId }/organisations`);
                setDealerOrgs(data);
            } catch (error) {
                message.error('Unable to fetch dealer organisations');
            }
            setLoadingDealerOrgs(false);
        };

        const fetchDealerUser = async () => {
            setLoadingDealerUsers(true);
            try {
                const data = await API.get('superadmin', `/dealers/${ dealerId }/users`);
                setDealerUsers(data);
            } catch (error) {
                message.error('Unable to fetch dealer users');
            }
            setLoadingDealerUsers(false);
        };

        if (dealerId) {
            fetchDealerData();
            fetchDealerOrgs();
            fetchDealerUser();
        }
    }, [dealerId]);

    // Configure table columns
    const orgColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (name, org) => (
                <Link to={`/management/organisations/${ org.id }`}>
                    {name}
                </Link>
            )
        },
        {
            title: 'Contact Name',
            dataIndex: 'contactName',
            render: value => value || 'N/A',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: value => value ? moment(value).format('MMM Do YYYY') : 'N/A',
        },
    ];
    const userColumns = [
        {
            title: 'Email',
            dataIndex: 'email',
            render: value => value || 'N/A',
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            render: value => value || 'N/A',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            render: value => value || 'N/A',
        },
    ];

    return (
        <Fragment>
            <PageHeaderWrapper
                title="Dealer Details"
                subTitle={dealerId}
                breadcrumb={getBreadcrumb(dealerId)}
            />

            <div css={styles.contentWrapper}>
                <Card title={dealer.name} loading={loadingDealer}>
                    <Descriptions column={2} bordered>
                        <Descriptions.Item label="Name">
                            {dealer.name || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Contact Name">
                            {dealer.contactName || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Contact Number">
                            {dealer.contactNumber || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Contact Address">
                            {dealer.contactAddress || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="ABN">
                            {dealer.abn || 'Not provided'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Created At">
                            {dealer.createdAt ? moment(dealer.createdAt).format('MMMM Do YYYY') : 'N/A'}
                        </Descriptions.Item>
                    </Descriptions>

                    <div css={{ display: 'flex', marginTop: '20px' }}>
                        <Link to={`/management/dealers/${ dealerId }/edit`}>
                            <Button type="primary">Edit Dealer</Button>
                        </Link>
                    </div>
                </Card>

                <Row gutter={24} css={{ marginTop: '30px' }}>
                    <Col span={12}>
                        <Card title="Dealer Organisations">
                            <Table
                                columns={orgColumns}
                                loading={loadingDealerOrgs}
                                rowKey="id"
                                dataSource={dealerOrgs}
                            />
                        </Card>
                    </Col>

                    <Col span={12}>
                        <Card
                            title="Dealer Users"
                            extra={(
                                <Tooltip title="Maximum one dealer user is allowed at the moment">
                                    <Button type="link" disabled={dealerUsers.length >= 1}>
                                        Add new
                                    </Button>
                                </Tooltip>
                            )}
                        >
                            <Table
                                columns={userColumns}
                                loading={loadingDealerUsers}
                                rowKey="email"
                                dataSource={dealerUsers}
                            />
                        </Card>
                    </Col>
                </Row>

            </div>
        </Fragment>
    );
};

export default SuperDealer;
