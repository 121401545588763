/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { find } from 'lodash';
import { memo } from 'react';

// Import Ant Design components
import { Card } from 'antd';

// Import bizchart components
import { Chart, Line, Annotation } from 'bizcharts';

// Import styles
import colors from '../../../../../styles/colors';

const VelocityPanel = (props) => {
    // Extract values from props
    const { routePoints, width, searchType, setHighlightPt } = props;

    const processData = (data = [], idleThresholdInSec = 14 * 60, maxPointTimeGapInSec = 120) => {
        // Initialisation
        const allData = [];
        const idleData = [];
        let idleStart = null;

        if (data?.length) {
            const stepSize = searchType === 'location' ? 1 : 3;
            for (let idx = 0; idx < data.length; idx += stepSize) {
                // Extract values from data
                const { time, speed, unixTime } = data[idx];

                // Add route point to all data
                allData.push({
                    time: time,
                    speed: speed,
                });

                // Check for idle duration
                if (!idleStart && speed === 0) {
                    idleStart = { ...data[idx], idx };
                } else if (idleStart) {
                    const lastPoint = data[idx - stepSize];
                    const pointTimeLapsedInSec = Math.abs(unixTime - lastPoint.unixTime) / 1000;

                    if (speed >= 2 || pointTimeLapsedInSec > maxPointTimeGapInSec) {
                        const idleDurationInSec = Math.floor(
                            Math.abs(lastPoint.unixTime - idleStart.unixTime) / 1000
                        );
                        if (
                            (idleDurationInSec >= idleThresholdInSec || idx === data.length - 1) &&
                            idleStart.time !== lastPoint.time &&
                            idx - stepSize - idleStart.idx > stepSize
                        ) {
                            idleData.push({
                                start: [idleStart.time, 'median'],
                                end: [lastPoint.time, 'median'],
                                text: `${Math.floor(idleDurationInSec / 60)}\nmins`,
                            });
                        }
                        idleStart = null;
                    }
                }
            }
        }

        return { allData, idleData };
    };

    const highlightPos = (time) => {
        if (time === '') setHighlightPt(null);
        else {
            const point = find(routePoints, { time });
            if (point) {
                const lon = point.position[1];
                const lat = point.position[0];
                setHighlightPt((current) => {
                    if (!current || (current[0] !== lon && current[1] !== lat)) {
                        return [lon, lat];
                    }
                    return current;
                });
            }
        }
    };

    const { allData, idleData } = processData(routePoints);

    return (
        <Card
            key="velocity-panel"
            bodyStyle={{ padding: 20 }}
            css={{
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '180px',
                width,
            }}
        >
            {routePoints && (
                <Chart
                    height={120}
                    data={allData}
                    autoFit
                    onTooltipChange={(evt) => highlightPos(evt?.data?.items[0].title)}
                    onPlotMouseleave={() => highlightPos('')}
                    scale={{
                        time: { tickCount: 10 },
                        speed: { min: 0 },
                    }}
                >
                    <Line
                        position="time*speed"
                        tooltip={[
                            'speed',
                            (speed) => ({
                                name: 'Speed',
                                value: `<b>${Math.floor(speed)}</b> km/h`,
                            }),
                        ]}
                    />

                    {/* Annotation for vehicle idle duration */}
                    {idleData.map((idle) => (
                        <Annotation.DataRegion
                            key={idle.start}
                            start={idle.start}
                            end={idle.end}
                            text={{
                                content: idle.text,
                                style: { fontSize: 10, fill: colors.primary, fontWeight: 'bold' },
                            }}
                            region={{
                                style: { fill: colors.primary, fillOpacity: 0.1 },
                            }}
                            lineLength={50}
                        />
                    ))}
                </Chart>
            )}
        </Card>
    );
};

export default memo(VelocityPanel);
