// Import library
import { css } from '@emotion/core';

const styles = {
    layout: css({
        minHeight: '100%',
    }),
    contentPageHeader: css({
        position: 'relative',
        '.ant-page-header-heading': {
            maxWidth: '1200px',
            margin: '0 auto',
            padding: '0px 24px',
        },
        '.ant-breadcrumb': {
            maxWidth: '1200px',
            margin: '0 auto',
            padding: '0px 24px',
        }
    }),
};

export default styles;



