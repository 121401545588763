/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { useState, useContext } from 'react';
import { useFormik } from 'formik';
import moment from 'moment-timezone';

// Import Ant Design components
import {
    Descriptions, Input, Button, Select
} from 'antd';

// Import actions
import { updateBasic } from '../../actions';

// Import store
import { UserContext } from '../../../../store/UserContext';

// Import stylesheet
import styles from './styles';

// Import the Option component
const { Option } = Select;

const Basic = () => {
    const [loading, setLoading] = useState(false);
    const userContext = useContext(UserContext);

    const formik = useFormik({
        initialValues: {
            email: userContext.email || '',
            firstName: userContext.firstName || '',
            lastName: userContext.lastName || '',
            timezone: userContext.timezone || '',
        },
        enableReinitialize: true,
        onSubmit: async values => {
            setLoading(true);
            const res = await updateBasic(values);
            if (res) {
                userContext.setFirstName(values.firstName);
                userContext.setLastName(values.lastName);
                userContext.setTimezone(values.timezone);
            }
            setLoading(false);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Descriptions
                title="Basic Information"
                layout="vertical"
                column={1}
            >
                <Descriptions.Item label="Email">
                    <Input
                        id="email"
                        name="email"
                        disabled
                        value={formik.values.email}
                        css={styles.basicInput}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="First Name">
                    <Input
                        id="firstName"
                        name="firstName"
                        placeholder="Enter first name"
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        css={styles.basicInput}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Last Name">
                    <Input
                        id="lastName"
                        name="lastName"
                        placeholder="Enter last name"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        css={styles.basicInput}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Timezone">
                    <Select
                        showSearch
                        placeholder="Select a timezone"
                        onChange={value => formik.setFieldValue('timezone', value)}
                        value={formik.values.timezone}
                        css={styles.basicInput}
                    >
                        {/** List all timezones in Australia */}
                        {
                            moment.tz.names()
                                .filter(name => name.indexOf('Australia') >= 0)
                                .map(timezone => (
                                    <Option value={timezone} key={timezone}>
                                        {timezone}
                                    </Option>
                                ))
                        }
                    </Select>
                </Descriptions.Item>

            </Descriptions>
            <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                css={{ marginTop: '25px' }}
            >
                Update basic information
            </Button>
        </form>
    );
};

export default Basic;