// Retrieve local environment
const localEnv = process.env.REACT_APP_LOCAL_ENV;

// Configure API host
let apiHost = 'http://localhost:1688/dev';
if (localEnv === 'dev') {
    apiHost = 'https://api-staging.fleetway.com.au';
} else if (localEnv === 'prod') {
    apiHost = 'https://api.fleetway.com.au';
}

// Configure API endpoints
const endpoints = [
    {
        name: 'organisations',
        endpoint: `${apiHost}/organisations`,
        region: 'ap-southeast-2',
    },
    {
        name: 'users',
        endpoint: `${apiHost}/users`,
        region: 'ap-southeast-2',
    },
    {
        name: 'history',
        endpoint: `${apiHost}/history`,
        region: 'ap-southeast-2',
    },
    {
        name: 'settings',
        endpoint: `${apiHost}/settings`,
        region: 'ap-southeast-2',
    },
    {
        name: 'superadmin',
        endpoint: `${apiHost}/superadmin`,
        region: 'ap-southeast-2',
    },
    {
        name: 'dealers',
        endpoint: `${apiHost}/dealers`,
        region: 'ap-southeast-2',
    },
];

// Configure authentication
const authConfigs = {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId:
        localEnv === 'prod'
            ? 'ap-southeast-2:b4a59d78-c665-4f3a-ba4e-53bcf6f3752a'
            : 'ap-southeast-2:3ba58758-6e85-463b-8e67-3ec98899d69e',

    // REQUIRED - Amazon Cognito Region
    region: 'ap-southeast-2',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: 'ap-southeast-2',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: localEnv === 'prod' ? 'ap-southeast-2_XZrO6Z9Wa' : 'ap-southeast-2_KOxFyqB0D',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId:
        localEnv === 'prod' ? '5nc95co9ibfm38k3n5u6ajpa3g' : '3gs1qcb2b4qo3g8ql0b0guhuos',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',
};

const amplifyConfig = {
    Auth: authConfigs,
    API: { endpoints },
};

export default amplifyConfig;
