// Import libraries
import { css } from '@emotion/core';

// Import presets
import { content } from '../../styles/presets';

// Prepare stylesheet
const styles = {
    contentWrapper: css({ ...content }),
    queryLabel: css({ marginRight: '10px' }),
    queryInput: css({
        width: '260px',
        marginRight: '30px',
    }),
};

export default styles;
