/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import moment from 'moment-timezone';
import Lightbox from 'react-image-lightbox';
import { useState, useEffect, useContext } from 'react';
import uuid4 from 'uuid/v4';

// Import Ant Design components
import { Button, Drawer, Badge, Divider, Typography, Spin, message, notification } from 'antd';
import { WifiOutlined } from '@ant-design/icons';

// Import components
import IconFont from '../../../../../components/IconFont';
import RequestTimer from '../../../../../components/RequestTimer';

// Import store
import { UserContext } from '../../../../../store/UserContext';
import { OrgsContext } from '../../../../../store/OrgsContext';

// Import utilities
import { channelsRender } from '../../../../Devices/utils';
import { connectRequestHub } from '../../../../../utilities/requestHub';

// Import constants
import { colorMappings } from '../../../../Devices/utils';
import {
    OTMActionCodes,
    SocketIOChannels,
    getLiveStreamingTopic,
} from '../../../../../constants/requestHub';

// Import stylesheet
import 'react-image-lightbox/style.css';

// Import Title and Text components
const { Title, Text } = Typography;

// Interval to send OTM request to stream real-time device status and images
// To keep the stream alive, we need to send OTM request every 25 seconds
export const STREAM_REQUEST_INTERVAL = 25000;

// Prepare constants for long request notification keys
const STATUS_LONG_REQ_NOTIFICAITON_KEY = 'STATUS_LONG_REQ';
const IMAGES_LONG_REQ_NOTIFICAITON_KEY = 'IMAGES_LONG_REQ';

const VehicleDetailsPanel = (props) => {
    // Extract values from props
    const {
        did,
        vloss,
        rec,
        rego,
        groupName,
        groupColor,
        net,
        gpsFix,
        hdd,
        service = '',
        drawerVisible,
        handleDrawerClose,
        handleVehicleUnfocus,
        handleVehiclePosChange,
    } = props;

    // Initialisation
    const [isOpen, setIsOpen] = useState(false);
    const [reqHubSocket, setReqHubSocket] = useState(null);
    const [statusLoading, setStatusLoading] = useState(null);
    const [speed, setSpeed] = useState(0);
    const [osTime, setOSTime] = useState(null);
    const [openImgIdx, setOpenImgIdx] = useState(-1);
    const [imgLoading, setImgLoading] = useState(false);
    const [imgs, setImgs] = useState(Array(8).fill('/imgs/ic_loading.png'));
    const [_, setRefreshIntervalId] = useState(null);
    const [currentStatusMsgId, setCurrentStatusMsgId] = useState();
    const [currentImagesMsgId, setCurrentImagesMsgId] = useState();

    // Retrieve currently selected organisation
    const orgContext = useContext(OrgsContext);
    const { selectedOrg } = orgContext;

    // Retrieve user timezone
    const userContext = useContext(UserContext);
    const { timezone } = userContext;

    useEffect(() => {
        // Establish a websocket connection to request hub
        const connect = async () => {
            const socketIO = await connectRequestHub(selectedOrg);
            setReqHubSocket(socketIO);
            setCurrentStatusMsgId(uuid4());
            setCurrentImagesMsgId(uuid4());
        };

        // Connect to request hub if not connected
        if (!reqHubSocket) connect();

        return () => {
            // Disconnect from request hub if connected
            if (reqHubSocket) {
                reqHubSocket.disconnect();
                setReqHubSocket(null);
            }
        };
    }, [reqHubSocket]);

    useEffect(
        () => () => {
            notification.close(STATUS_LONG_REQ_NOTIFICAITON_KEY);
            notification.close(IMAGES_LONG_REQ_NOTIFICAITON_KEY);
        },
        []
    );

    useEffect(() => {
        // Public OTM to start and keep the stream alive
        const publishOTM = () => {
            // Request to stream device status
            reqHubSocket.emit(SocketIOChannels.REQUEST, {
                deviceId: did,
                actionCode: OTMActionCodes.STREAM_STATUS,
                requestId: uuid4(),
            });

            // Request to stream device images
            reqHubSocket.emit(SocketIOChannels.REQUEST, {
                deviceId: did,
                actionCode: OTMActionCodes.STREAM_IMAGES,
                requestId: uuid4(),
            });
        };

        if (reqHubSocket) {
            setStatusLoading(true);
            setImgLoading(true);

            publishOTM();
            const interval = setInterval(() => publishOTM(), STREAM_REQUEST_INTERVAL);
            setRefreshIntervalId(interval);
        }

        return () => {
            setRefreshIntervalId((current) => {
                clearInterval(current);
                return null;
            });
        };
    }, [reqHubSocket, did]);

    useEffect(() => {
        // Prepare a message handler on device status data received
        const onStatusDataReceived = (message) => {
            const { d: data } = message || {};
            const { p, s = 0, t = null } = data || {};

            setStatusLoading(false);
            setCurrentStatusMsgId(uuid4());
            notification.close(STATUS_LONG_REQ_NOTIFICAITON_KEY);

            if (p && Array.isArray(p) && p.length === 2) {
                handleVehiclePosChange(p[1], p[0]);
            }
            setSpeed(s);
            setOSTime(t && t > 0 ? t * 1000 : null);
        };

        // Prepare a message handler on device image data received
        const onImageDataReceived = (message) => {
            const { d: data, reqId } = message || {};

            setImgLoading(false);
            setCurrentImagesMsgId(uuid4());
            notification.close(IMAGES_LONG_REQ_NOTIFICAITON_KEY);

            const newImgs = [];

            // Parse video loss status
            const vlossStatus = vloss !== '0' ? vloss.split('|') : [];

            // Parse open channel status
            let operatingChs = null;
            service.split('|').forEach((serviceStatus) => {
                if (serviceStatus.startsWith('CH:')) {
                    operatingChs = serviceStatus.replace('CH:', '').split(',');
                }
            });

            for (let idx = 0; idx < 8; idx += 1) {
                let url = '/imgs/ic_channeloff.png';
                if (operatingChs && operatingChs.indexOf((idx + 1).toString()) !== -1) {
                    url = '/imgs/ic_nosignal.png';
                }
                const base64Data = data[`ch${idx + 1}`];
                if (base64Data) url = `data:image/png;base64, ${base64Data}`;
                if (vlossStatus.indexOf((idx + 1).toString()) !== -1) {
                    url = '/imgs/ic_videoloss.png';
                }
                newImgs[idx] = url;
            }
            setImgs(newImgs);
        };

        // Prepare topic to subscribe to real-time device status and images
        const streamStatusTopic = getLiveStreamingTopic(did, OTMActionCodes.STREAM_STATUS);
        const streamImagesTopic = getLiveStreamingTopic(did, OTMActionCodes.STREAM_IMAGES);

        // Register message handlers
        if (reqHubSocket) {
            reqHubSocket.on(streamStatusTopic, onStatusDataReceived);
            reqHubSocket.on(streamImagesTopic, onImageDataReceived);
        }

        return () => {
            // Unregister message handlers
            if (reqHubSocket) {
                reqHubSocket.off(streamStatusTopic, onStatusDataReceived);
                reqHubSocket.off(streamImagesTopic, onImageDataReceived);
            }
        };
    }, [reqHubSocket, did, vloss, rec, service]);

    const getHDDStatusColor = (status) => {
        const hddStatus = status.split('|');
        switch (hddStatus?.[0]) {
            case 'H1':
                return colorMappings.RECORDING;
            case 'H2':
                return colorMappings.STORAGE_FORMAT_REQUIRED;
            default:
                return 'red';
        }
    };

    const getSDStatusColor = (status) => {
        const hddStatus = status.split('|');
        switch (hddStatus?.[1]) {
            case 'S1':
                return colorMappings.RECORDING;
            case 'S2':
                return colorMappings.STORAGE_FORMAT_REQUIRED;
            default:
                return 'red';
        }
    };

    const getGPSStatusColor = (gpsFix) => {
        switch (gpsFix) {
            case 0:
            case 1:
            case 2:
                return '#d4b106';
            case 3:
                return 'green';
            default:
                return 'red';
        }
    };

    const buttonStyles = {
        border: 0,
        color: 'inherit',
        fontSize: '20px!important',
        lineHeight: 1.4,
    };

    return (
        <Drawer
            placement="right"
            width="350px"
            mask={false}
            getContainer={false}
            closable={false}
            open={drawerVisible}
            bodyStyle={{ backgroundColor: '#fafafa' }}
        >
            <div
                css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <div>
                    <Title level={3} style={{ margin: 0 }}>
                        {rego || 'N/A'}
                    </Title>
                    {groupName ? <Badge color={groupColor || 'gray'} text={groupName} /> : null}
                </div>
                <div
                    css={{
                        borderRadius: '15px 7px',
                        border: '1px solid #f0f0f0',
                        backgroundColor: 'white',
                        width: '80px',
                        height: '35px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '1px 3px 4px 0px rgba(0, 0, 0, .2)',
                    }}
                >
                    <Button
                        size="small"
                        icon={<IconFont type="iconic_close" css={{ fontSize: '20px!important' }} />}
                        onClick={handleVehicleUnfocus}
                        css={buttonStyles}
                        type="link"
                    />
                    <Divider type="vertical" />
                    <Button
                        size="small"
                        icon={<IconFont type="iconic_list" css={{ fontSize: '20px!important' }} />}
                        onClick={handleDrawerClose}
                        css={buttonStyles}
                        type="link"
                    />
                </div>
            </div>

            <Spin spinning={statusLoading}>
                <div
                    css={{
                        borderRadius: '35px 7px',
                        border: '1px solid #f0f0f0',
                        backgroundColor: 'white',
                        width: '285px',
                        height: '180px',
                        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .2)',
                        margin: '20px 0',
                        padding: '18px 25px',
                    }}
                >
                    <div
                        css={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '20px',
                        }}
                    >
                        <div>
                            <Title strong style={{ margin: 0 }}>
                                {parseInt(speed || '0', 10)}
                            </Title>
                            <div css={{ fontSize: '11px' }}>Km/h</div>
                        </div>
                        <div css={{ fontSize: '12px', textAlign: 'center' }}>
                            <div
                                css={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    '.anticon': { fontSize: '25px' },
                                }}
                            >
                                <div>
                                    {hdd.split('|')?.[2] === 'WF1' && net === 'online' ? (
                                        <WifiOutlined style={{ color: 'green' }} />
                                    ) : (
                                        <IconFont
                                            type="iconic_network"
                                            style={{ color: net === 'online' ? 'green' : 'red' }}
                                        />
                                    )}

                                    <br />
                                    <Text>NET</Text>
                                </div>
                                <div>
                                    <IconFont
                                        type="iconic_location"
                                        style={{ color: getGPSStatusColor(gpsFix) }}
                                    />
                                    <br />
                                    <Text>GPS</Text>
                                </div>
                                <div>
                                    <IconFont
                                        type="iconic_disk"
                                        style={{ color: getHDDStatusColor(hdd || '') }}
                                    />
                                    <br />
                                    <Text>HDD</Text>
                                </div>
                                <div>
                                    <IconFont
                                        type="iconic_sd"
                                        style={{ color: getSDStatusColor(hdd || '') }}
                                    />
                                    <br />
                                    <Text>SD</Text>
                                </div>
                            </div>
                            <div css={{ marginTop: '10px', fontSize: '10.5px' }}>
                                Updated:{' '}
                                <Text strong>
                                    {moment.utc(osTime).tz(timezone).format('DD/MM HH:mm:ss (Z)')}
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div>{channelsRender(rec, vloss, service, hdd)}</div>
                </div>
            </Spin>

            <div
                css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                }}
            >
                {imgs.map((imgSrc, idx) => (
                    <div
                        css={{ display: 'flex', flexDirection: 'column', margin: '5px' }}
                        key={`channel-${idx}`}
                    >
                        <Text css={{ textAlign: 'center', marginBottom: '8px' }} strong>
                            {`Channel ${idx + 1}`}
                        </Text>
                        {/* eslint-disable-next-line */}

                        <Spin spinning={imgLoading}>
                            {/* eslint-disable-next-line */}
                            <img
                                src={imgSrc}
                                alt="No data"
                                css={{
                                    width: '130px',
                                    height: '130px',
                                    border: '0',
                                    backgroundColor: '#F8F7F7',
                                }}
                                onClick={() => {
                                    if (!imgLoading && imgSrc !== '/imgs/ic_nosignal.png') {
                                        setIsOpen(true);
                                        setOpenImgIdx(idx);
                                    } else {
                                        message.info('No channel image found');
                                    }
                                }}
                                onError={(ev) => {
                                    ev.target.src = '/imgs/ic_nosignal.png';
                                }}
                            />
                        </Spin>
                    </div>
                ))}

                <div style={{ textAlign: 'center', width: '100%', marginTop: '30px' }}>
                    <Text type="secondary">Note: Images are fetched every 3 seconds.</Text>
                </div>
            </div>
            {isOpen && openImgIdx >= 0 && (
                <div>
                    <Lightbox
                        mainSrc={imgs[openImgIdx]}
                        nextSrc={imgs[(openImgIdx + 1) % imgs.length]}
                        prevSrc={imgs[(openImgIdx + imgs.length - 1) % imgs.length]}
                        onCloseRequest={() => {
                            setIsOpen(false);
                            setOpenImgIdx(-1);
                        }}
                        onImageLoadError={() => {
                            setIsOpen(false);
                            setOpenImgIdx(-1);
                        }}
                        enableZoom={false}
                        onMovePrevRequest={() => {
                            setOpenImgIdx((openImgIdx + imgs.length - 1) % imgs.length);
                        }}
                        onMoveNextRequest={() => {
                            setOpenImgIdx((openImgIdx + 1) % imgs.length);
                        }}
                    />
                </div>
            )}

            {/* Add a request timer for status fetching */}
            <RequestTimer
                requestId={currentStatusMsgId}
                notifications={{
                    10: () =>
                        notification.info({
                            key: STATUS_LONG_REQ_NOTIFICAITON_KEY,
                            placement: 'topLeft',
                            duration: 0,
                            message: 'Fetching device status',
                            description:
                                'Your request is still in progress. Please note, network instability may cause occasional delays.',
                        }),
                }}
            />

            {/* Add a request timer for images fetching */}
            <RequestTimer
                requestId={currentImagesMsgId}
                notifications={{
                    10: () =>
                        notification.info({
                            key: IMAGES_LONG_REQ_NOTIFICAITON_KEY,
                            placement: 'topLeft',
                            duration: 0,
                            message: 'Fetching device images',
                            description:
                                'Your request is still in progress. Please note, network instability may cause occasional delays.',
                        }),
                }}
            />
        </Drawer>
    );
};

export default VehicleDetailsPanel;
