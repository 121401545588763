// Import libraries
import { css } from '@emotion/core';

// Import presets
import { content, colors } from '../../styles/presets';

// Prepare stylesheet
const styles = {
    contentWrapper: css({ ...content }),
    controlPanel: css({
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '20px',
    }),
};

export default styles;
